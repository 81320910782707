import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';

type Size = 'large' | 'medium' | 'small' | 'mini';

@Component({
    selector: 'ls-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    standalone: true,
    imports: [MatLegacyProgressSpinnerModule],
})
export class SpinnerComponent implements OnChanges {
  @Input()
  size: Size = 'medium';

  diameter = 50;

  constructor(private changeDetector: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.size) {
      this.diameter = this.getDiameter(this.size);
      this.changeDetector.markForCheck();
    }
  }

  private getDiameter(size: Size) {
    switch (size) {
      case 'large':
        return 100;
      case 'medium':
        return 50;
      case 'small':
        return 25;
      case 'mini':
        return 15;
      default:
        return 50;
    }
  }
}
