<mat-dialog-content>
  <div class="container">
    <div class="explain">
      <h3>塗装画像の作成</h3>
      <span>
        生成される色画像は実際の色味と違う場合があります。<br />
        マンセル値で生成できる色画像のパターンには限りがあります。
        実在する色味が指定できない場合がありますがご了承ください。
      </span>
    </div>
    <div class="generate">
      <div>
        <mat-radio-group
          class="disable-ripple"
          name="type"
          [(ngModel)]="colorInfo.type"
        >
          <mat-radio-button value="paintNo" [matTooltip]="'(例) 02-50H, N-93'"
            >日塗工番号</mat-radio-button
          >
          <mat-radio-button value="munsell" [matTooltip]="'(例) 2.5R 5/6, N6.5'"
            >マンセル値</mat-radio-button
          >
        </mat-radio-group>

        <table *ngIf="colorInfo.type === 'munsell'; else paintNo">
          <tr>
            <th>色相1</th>
            <th>色相2</th>
            <th>明度</th>
            <th></th>
            <th>彩度</th>
          </tr>
          <tr>
            <td>
              <mat-select
                [class.not-select]="baseH === 'N'"
                name="numH"
                [(ngModel)]="numH"
                (selectionChange)="mV = mC = undefined"
                [disabled]="baseH === 'N'"
              >
                <mat-option [value]="undefined"></mat-option>
                <mat-option *ngFor="let nH of numHList" [value]="nH">{{
                  nH
                }}</mat-option>
              </mat-select>
            </td>
            <td>
              <mat-select
                name="baseH"
                [(ngModel)]="baseH"
                (selectionChange)="selectN(); mV = mC = undefined"
              >
                <mat-option [value]="undefined"></mat-option>
                <mat-option *ngFor="let bH of baseHList" [value]="bH">{{
                  bH
                }}</mat-option>
              </mat-select>
            </td>
            <td>
              <mat-select
                [class.not-select]="(!baseH || !numH) && baseH !== 'N'"
                name="mV"
                [(ngModel)]="mV"
                (selectionChange)="getMcList(numH, baseH, mV)"
                [disabled]="(!baseH || !numH) && baseH !== 'N'"
              >
                <mat-option [value]="undefined"></mat-option>
                <mat-option *ngFor="let v of vList" [value]="v">{{
                  v
                }}</mat-option>
              </mat-select>
            </td>
            <td style="text-align: center">/</td>
            <td>
              <mat-select
                [class.not-select]="!mV || baseH === 'N'"
                name="mC"
                [(ngModel)]="mC"
                [disabled]="!mV || baseH === 'N'"
              >
                <mat-option [value]="undefined"></mat-option>
                <mat-option *ngFor="let c of mCList" [value]="c.mC">{{
                  c.mC
                }}</mat-option>
              </mat-select>
            </td>
          </tr>
        </table>
        <div class="buttons">
          <button
            class="generate-button"
            mat-stroked-button
            color="primary"
            (click)="onGenerate()"
            [disabled]="
              !(
                (colorInfo.type === 'munsell' &&
                  (!!mC || (baseH === 'N' && mV))) ||
                (colorInfo.type !== 'munsell' && (!!pC || (pH === 'N' && pV)))
              )
            "
          >
            画像を生成する
          </button>
        </div>
      </div>

      <mat-divider class="vertical" [vertical]="true"></mat-divider>
      <div class="image">
        <div class="image-header">
          <span> 生成画像プレビュー </span>
          <button mat-icon-button (click)="resetColorInfo()">
            <mat-icon>remove_circle_outline</mat-icon>
          </button>
        </div>
        <ls-spinner *ngIf="isLoading"></ls-spinner>
        <img *ngIf="!isLoading" [lsSrc]="colorInfo.filePath" />
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="left">
    <ls-button (click)="cancel()" [border]="true">保存しないで閉じる</ls-button>
  </div>
  <div class="right">
    <button
      ls-button
      [disabled]="!colorInfo.filePath"
      (click)="onSave()"
      theme="primary"
      [border]="true"
    >
      保存
    </button>
  </div>
</mat-dialog-actions>

<ng-template #paintNo>
  <table>
    <tr>
      <th>色相</th>
      <th></th>
      <th>明度</th>
      <th>彩度</th>
    </tr>
    <tr>
      <td>
        <mat-select
          name="pH"
          [(ngModel)]="pH"
          (selectionChange)="getPvList(pH)"
        >
          <mat-option [value]="undefined"></mat-option>
          <mat-option *ngFor="let h of pHList" [value]="h">{{ h }}</mat-option>
        </mat-select>
      </td>
      <td style="text-align: center">-</td>
      <td>
        <mat-select
          [class.not-select]="pH === undefined"
          name="pV"
          [(ngModel)]="pV"
          (selectionChange)="getPcList(pH, pV)"
          [disabled]="pH === undefined"
        >
          <mat-option [value]="undefined"></mat-option>
          <mat-option *ngFor="let v of pVList" [value]="v">{{ v }}</mat-option>
        </mat-select>
      </td>
      <td>
        <mat-select
          [class.not-select]="pV === undefined || pH === 'N'"
          name="pC"
          [(ngModel)]="pC"
          [disabled]="pV === undefined || pH === 'N'"
        >
          <mat-option [value]="undefined"></mat-option>
          <mat-option *ngFor="let c of pCList" [value]="c">{{ c }}</mat-option>
        </mat-select>
      </td>
    </tr>
  </table>
</ng-template>
