import { Injectable } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { JoineryComponent } from '@interfaces/joinery-component';
import { ProductService } from '@shared/api/product.service';
import { ProjectService as ApiProjectService } from '@shared/api/project.service';
import { AddManualInputCandidateDialogComponent } from '@shared/dialog/add-manual-input-candidate-dialog/add-manual-input-candidate-dialog.component';
import { ListLinkCheckDialogComponent } from '@shared/dialog/list-link-check-dialog/list-link-check-dialog.component';
import { FloorGroup } from '@shared/models/response/floor-group';
import { InteriorCategory } from '@shared/models/response/interior-category';
import {
  InteriorPicklistInfo,
  InteriorSetParts,
} from '@shared/models/response/interior-set-parts';
import { RoomGroup } from '@shared/models/response/room-group';
import { ColorImage } from '@shared/models/response/sub/color-image';
import { PicklistStatus } from '@shared/models/response/sub/picklist-status';
import { ProductState } from '@shared/models/response/sub/product-state';
import { ProjectRangeType } from '@shared/models/response/sub/project-range-type';
import { ThumbnailType } from '@shared/models/response/sub/thumbnail-type';
import { UploadFileService } from '@shared/service/upload-file.service';
import { PicklistApiService } from 'app/services/api/picklist-api.service';
import { CandidateProductFacade } from 'app/store/candidate-product/facades/candidate-product.facade';
import { ExteriorCategory } from 'app/v2/general/features/project/_type/exterior-category';
import {
  ExteriorSet,
  ExteriorPicklistInfo,
} from 'app/v2/general/features/project/_type/exterior-category-set';
import { combineLatest, firstValueFrom, Observable, of, Subject } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { DecidedProductInfo } from '../models/response/decided-product-info';
import { CandidateProduct, ListProduct } from '../models/response/list-product';
import { initPicklist, Picklist } from '../models/response/picklist';
import { Product } from '../models/response/product';
import { ProductCache } from '../models/response/product-cache';
import { PicklistProductStatus } from '../models/response/sub/picklist-product-status';
import { PicklistType } from '../models/response/sub/picklist-type';

import { ProjectService } from './project.service';

@Injectable({
  providedIn: 'root',
})
export class PicklistService {
  constructor(
    private productApiService: ProductService,
    private apiProjectService: ApiProjectService,
    private candidateProductFacade: CandidateProductFacade,
    private projectService: ProjectService,
    private fileUploader: UploadFileService,
    private picklistApi: PicklistApiService,
    private readonly dialog: MatLegacyDialog,
  ) {}

  public onReloadCandidateListProducts$ = new Subject<ListProduct>();
  public onReloadUpdatedProductInfoAt$ = new Subject<Date>();

  isDecided(picklist: Picklist): boolean {
    return !!this.getDecidedListProductByPicklist(picklist);
  }

  isFixed(picklist: Picklist): boolean {
    return (
      this.getDecidedListProductByPicklist(picklist)?.status ===
      PicklistProductStatus.Fixed
    );
  }

  //queryに行き
  isFixed$(picklistId: number): Observable<boolean> {
    return this.candidateProductFacade
      .getDecidedProductByPicklistId(picklistId)
      .pipe(
        map((candidate) => candidate?.status === PicklistProductStatus.Fixed),
      );
  }

  hasRequestedListProducts(picklist: Picklist): boolean {
    return (
      picklist.listProducts.filter((listProduct) => {
        return listProduct.status == PicklistProductStatus.Requested;
      }).length > 0
    );
  }

  getReqeustedCountsEachPicklistType(
    type: PicklistType,
    picklists: Picklist[],
  ): Observable<number> {
    // リクエストメモがあるもしくは提案権限の候補製品がある材料の領域ごとのカウント
    const picklistIds = picklists
      .filter((picklist) => picklist.type === type && !picklist.request_memo)
      .map((picklist) => picklist.id);
    const requesteMemoCount = picklists.filter(
      (picklist) => !!picklist.request_memo,
    ).length;

    return this.candidateProductFacade.candidateProducts$.pipe(
      map(
        (candidates) =>
          candidates
            .filter((candidate) => picklistIds.includes(candidate.list_id))
            .filter(
              (candidate) =>
                candidate.status === PicklistProductStatus.Requested,
            )
            .map((candidate) => candidate.list_id)
            .filter((v, i, self) => self.indexOf(v) === i).length +
          requesteMemoCount,
      ),
    );
  }

  isDecidedListProduct(listProduct: ListProduct): boolean {
    const status = listProduct.status;

    return (
      status === PicklistProductStatus.Decided ||
      status === PicklistProductStatus.Fixed
    );
  }

  getStatusUsingPriority(
    picklist: Picklist,
  ): PicklistProductStatus | undefined | void {
    const candidateStatuses = picklist.listProducts.map(
      (listProduct) => listProduct.status,
    );

    if (candidateStatuses.includes(PicklistProductStatus.Requested))
      return PicklistProductStatus.Requested;

    if (this.isFixed(picklist)) return PicklistProductStatus.Fixed;

    if (this.isDecided(picklist)) return PicklistProductStatus.Decided;

    if (candidateStatuses.includes(PicklistProductStatus.Candidate))
      return PicklistProductStatus.Candidate;

    if (candidateStatuses.includes(PicklistProductStatus.Reject))
      return PicklistProductStatus.Reject;

    if (picklist.listProducts.length < 1) return undefined;
  }

  isManualInput(listProduct: ListProduct | CandidateProduct): boolean {
    return !listProduct.product_unique_key;
  }

  getListProductUniqueKey(listProduct: ListProduct): string {
    return (
      listProduct.selected_variation_unique_key ||
      listProduct.product_unique_key ||
      ''
    );
  }

  getAllProductUniqueKey(picklist: Picklist): string[] {
    return picklist.listProducts
      .map((listProduct) => this.getListProductUniqueKey(listProduct))
      .filter((v, i, self) => self.indexOf(v) === i)
      .filter((uniqueKey) => !!uniqueKey);
  }

  getDecidedListProduct$(
    picklistId: number,
  ): Observable<CandidateProduct | undefined> {
    return this.candidateProductFacade.getDecidedProductByPicklistId(
      picklistId,
    );
  }

  //picklist-logic
  getDecidedListProductByPicklist(picklist: Picklist): ListProduct | undefined {
    return !this.projectService.isParentPicklist(picklist) &&
      picklist.listProducts
      ? picklist?.listProducts.find(
          (listProduct) =>
            listProduct.status === PicklistProductStatus.Decided ||
            listProduct.status === PicklistProductStatus.Fixed,
        )
      : undefined;
  }

  getDecidedOrFirstListProductByPicklist$(
    picklistId: number,
  ): Observable<CandidateProduct | undefined> {
    return this.candidateProductFacade
      .getCandidateProductsByPicklistId(picklistId)
      .pipe(
        map((candidates) => {
          const decidedProduct = candidates.find(
            (candidate) =>
              candidate.status === PicklistProductStatus.Decided ||
              candidate.status === PicklistProductStatus.Fixed,
          );

          if (decidedProduct) return decidedProduct;

          return candidates[0];
        }),
      );
  }

  getAllPicklistUniqueKeyOfPicklistType(
    picklists: Picklist[],
    picklistType: PicklistType,
  ): string[] {
    return picklists
      .filter((picklist) => picklist.type === picklistType)
      .map((picklist) => {
        const decidedListProduct =
          this.getDecidedListProductByPicklist(picklist);

        return decidedListProduct
          ? this.getListProductUniqueKey(decidedListProduct)
          : '';
      })
      .filter((v, i, self) => self.indexOf(v) === i)
      .filter((uniqueKey) => uniqueKey !== '');
  }

  //logicいき
  getDecidedProductInfoByListProduct(
    listProduct: ListProduct | CandidateProduct,
  ): DecidedProductInfo {
    const decidedProductInfo: DecidedProductInfo = {
      name: listProduct.name,
      specification: listProduct.specification,
      number: listProduct.number,
      maker: listProduct.maker,
      certification: listProduct.certification,
      sickhouse: listProduct.sickhouse,
      thickness: listProduct.thickness,
      price: listProduct.price,
      price_unit: listProduct.price_unit,
      lumberjack: listProduct.lumberjack,
      thumbnail_image: listProduct.thumbnail_image,
      original_thumbnail_image: listProduct.original_thumbnail_image,
      uploadedThumbnail: listProduct.uploadedThumbnail,
      texture_image: listProduct.texture_image,
      uploadedTexture: listProduct.uploadedTexture,
      texture_width: listProduct.texture_width,
      texture_height: listProduct.texture_height,
      uniclass_code: listProduct.uniclass_code,
      color_image: listProduct.color_image,
    };

    return decidedProductInfo;
  }

  //product-cache-logicいき
  getDecidedProductInfoByProductCache(
    product: ProductCache,
    picklistType: PicklistType,
  ): DecidedProductInfo {
    if (!product) return this.getEmptyDecidedProduct();

    const details = product.details;

    let priceItem: string | undefined;
    let priceUnit: string | undefined;
    [
      '^設計単価\\s*\\[円\\/㎡\\]<!--検索用-->$',
      '^設計単価\\s*\\[円\\/個\\]<!--検索用-->$',
    ].forEach((regExp) => {
      Object.keys(details)
        .filter(
          (k) =>
            k.match(new RegExp(regExp)) &&
            Number.isFinite(parseInt((details[k] || '').replace(/,/g, ''))),
        )
        .forEach((k) => {
          const match = k.match(/.*\[(.*)\]/);

          if (match && !priceItem) {
            priceItem = k;
            priceUnit = match[1];
          }
        });
    });

    const productInfo: DecidedProductInfo = {
      name: details['製品名'],
      specification: Object.keys(details)
        .filter((k) => {
          return (
            k.match(/<!--バリエーション用-->/) &&
            !!details[k] &&
            details[k] != '-'
          );
        })
        .map((k) => k.replace(/<!--.*-->/g, '') + ': ' + details[k])
        .join('\n'),
      number: details['品番<!--リスト用-->'],
      maker: details['メーカー'],
      sickhouse: details['ホルムアルデヒド放散区分<!--検索用-->'] || undefined,
      thickness: !isNaN(parseFloat(details['厚み<!--検索用-->']))
        ? parseFloat(details['厚み<!--検索用-->'].replace(/,/g, ''))
        : undefined,
      price: priceItem
        ? parseInt((details[priceItem] || '').replace(/,/g, ''))
        : undefined,
      price_unit: priceUnit || undefined,
      lumberjack: details['材工/材のみ<!--検索用-->'],
      thumbnail_image: product.display_params.image!.main,
      original_thumbnail_image: product.display_params.image!.main,
      texture_image: product.display_params.image!.texture,
      texture_width: product.details['テクスチャ縦幅']
        ? parseInt(product.details['テクスチャ縦幅'])
        : null,
      texture_height: product.details['テクスチャ横幅']
        ? parseInt(product.details['テクスチャ横幅'])
        : null,
      uniclass_code: product.details['uniclass'],
    };

    if (product.restriction) {
      const restrictions = product.restriction!.set.filter(
        (s) => s.picklist_type == picklistType,
      );

      if (restrictions.length == 1) {
        productInfo.certification = restrictions[0]!.no;
      }
    }

    return productInfo;
  }

  //product-cache-logicいき
  public getEmptyDecidedProduct(): DecidedProductInfo {
    return {
      name: null,
      specification: null,
      number: null,
      maker: null,
      certification: null,
      sickhouse: null,
      thickness: null,
      price: null,
      price_unit: null,
      lumberjack: null,
      thumbnail_image: null,
      original_thumbnail_image: null,
      texture_image: null,
      texture_width: null,
      texture_height: null,
      uniclass_code: null,
    };
  }

  //list-product-logicいき
  public getEmptyListProduct(): ListProduct {
    const decidedProductInfo = this.getEmptyDecidedProduct();
    const emptyValue = Object.assign(
      {
        id: 0,
        project_id: 0,
        list_id: 0,
        product_unique_key: '',
        has_variation: false,
        status: PicklistProductStatus.Candidate,
        added_user_id: 0,
        updated_at: '',
        created_at: '',
        use_image: ThumbnailType.Product,
      },
      decidedProductInfo,
    );

    return emptyValue;
  }

  //マルっとusecase
  async addListProductAndFillId(
    picklist: Picklist,
    source: Product | DecidedProductInfo,
    isOnlyRequest?: boolean,
    isChartOrProductDetail?: boolean,
  ): Promise<ListProduct> {
    const listProduct = this.getEmptyListProduct();

    let decidedProductInfo: DecidedProductInfo;
    let product: Product | null = null;

    if ('unique_key' in source) {
      source as Product;
      decidedProductInfo =
        (source.variation_count && source.variation_count === 1) ||
        isChartOrProductDetail
          ? this.getDecidedProductInfoByProductCache(
              source.cache,
              picklist.type,
            )
          : this.getEmptyDecidedProduct();
      product = source;
    } else {
      source as DecidedProductInfo;
      decidedProductInfo = source;
    }

    Object.assign(listProduct, decidedProductInfo);

    if (isOnlyRequest) {
      listProduct.status = PicklistProductStatus.Requested;
    } else {
      listProduct.status = PicklistProductStatus.Candidate;
    }

    if (product) {
      listProduct.product_unique_key = product.unique_key;
      listProduct.has_variation = product.variation_count
        ? product.variation_count > 1
        : false;

      if (!this.hasVariation(listProduct) || isChartOrProductDetail) {
        listProduct.order_tag =
          product.cache.details['オーダー品<!--検索用-->'];
        listProduct.selected_variation_unique_key = product.unique_key;
      }
    }

    if (listProduct.uploadedThumbnail || listProduct.uploadedTexture) {
      const listProductInImage = await this.changeNewThumbnailOrTextureImage(
        listProduct,
        picklist,
      );
      Object.assign(listProduct, listProductInImage);
    }

    await firstValueFrom(this.checkListLink(picklist));

    // API呼んでListProduct作成。サブスクライブ
    // listProductのidに戻り値のidをセット
    const newListProducts = await firstValueFrom(
      this.productApiService.addListProduct(listProduct, picklist),
    );

    newListProducts.forEach(async (newListProduct) => {
      if (!newListProduct.product_unique_key && newListProduct.color_image) {
        const manualInputHasColorImage =
          await this.addColorInfoToAddedManualInput(newListProduct);
        newListProduct = Object.assign(
          newListProduct,
          manualInputHasColorImage,
        );
      }
      this.candidateProductFacade.create(newListProduct);
    });

    return newListProducts.find((newListProduct) => {
      return newListProduct.list_id == picklist.id;
    })!;
  }

  deleteListProduct(listProductId: number): Observable<number> {
    return this.productApiService.deleteListProduct(listProductId);
  }

  selectVariation(
    listProduct: ListProduct,
    decidedProductInfo: DecidedProductInfo,
    product?: Product,
    needSave: boolean = true,
    picklist?: Picklist,
  ): Observable<ListProduct | undefined> {
    const newListProduct = { ...listProduct };

    if (product) {
      newListProduct.selected_variation_unique_key = product.unique_key;
    }
    Object.assign(newListProduct, decidedProductInfo);

    // needSaveがtrueならAPI呼んで保存
    if (needSave) {
      this.updateListProduct(newListProduct, picklist!, product);
    }

    return needSave ? of(newListProduct) : of(undefined);
  }

  //usecaseいき
  async updateListProduct(
    listProduct: ListProduct,
    picklist: Picklist,
    decidedProduct?: Product,
  ): Promise<ListProduct> {
    //isDecidedListProductはlogic
    listProduct.order_tag =
      this.isDecidedListProduct(listProduct) &&
      decidedProduct?.cache?.details['オーダー品<!--検索用-->']
        ? decidedProduct!.cache.details['オーダー品<!--検索用-->']
        : null;

    this.candidateProductFacade.addProcessingId(listProduct.id);

    if (listProduct.uploadedThumbnail || listProduct.uploadedTexture) {
      //マルっとusecase
      listProduct = await this.changeNewThumbnailOrTextureImage(
        listProduct,
        picklist,
      );
    }

    //マルっとusecase
    await firstValueFrom(this.checkListLink(picklist));

    const prevListProduct = await firstValueFrom(
      this.candidateProductFacade.getCandidateProductByListProductId(
        listProduct.id,
      ),
    );

    const newListProduct = await firstValueFrom(
      this.productApiService.updateListProduct(listProduct, decidedProduct),
    );

    if (
      JSON.stringify(prevListProduct?.filter_condition) !==
        JSON.stringify(newListProduct.filter_condition) &&
      !newListProduct.selected_variation_unique_key
    ) {
      const updatedValue = {
        ...newListProduct,
        product: decidedProduct ? decidedProduct : listProduct.product,
      };
      this.candidateProductFacade.getVariationTotal([updatedValue]);
    }

    const otherListLink = this.projectService.getOtherLinkPicklist(picklist);

    if (otherListLink) {
      const otherListProducts = await firstValueFrom(
        this.candidateProductFacade.getCandidateProductsByPicklistId(
          otherListLink.id,
        ),
      );

      let otherListProduct = otherListProducts.find((_listProduct) => {
        return listProduct.product_unique_key
          ? listProduct.product_unique_key == _listProduct.product_unique_key
          : listProduct.name == _listProduct.name;
      });

      if (otherListProduct) {
        otherListProduct = { ...otherListProduct };
        Object.assign(
          otherListProduct,
          this.getDecidedProductInfoByListProduct(newListProduct),
        );
        otherListProduct.status = newListProduct.status;
        otherListProduct.filter_condition = newListProduct.filter_condition;
        otherListProduct.selected_variation_unique_key =
          newListProduct.selected_variation_unique_key;
        otherListProduct.updated_at = newListProduct.updated_at;
        otherListProduct.updated_product_info_at =
          newListProduct.updated_product_info_at;
        this.candidateProductFacade.update(otherListProduct);
      }
    }

    this.candidateProductFacade.update(newListProduct);

    return newListProduct;
  }

  //list-product-logicいき
  hasVariation(listProductOrPicklist: ListProduct | Picklist): boolean {
    const listProduct: ListProduct | undefined =
      this.getListProductByListProductOrPicklist(listProductOrPicklist);

    return listProduct ? listProduct.has_variation : false;
  }

  isSelectedVariation(listProductOrPicklist: ListProduct | Picklist): boolean {
    const listProduct = this.getListProductByListProductOrPicklist(
      listProductOrPicklist,
    );

    return !!listProduct?.selected_variation_unique_key;
  }

  getListProductByListProductOrPicklist(
    listProductOrPicklist: ListProduct | Picklist,
  ): ListProduct | undefined {
    let listProduct: ListProduct | undefined;

    if ('listProducts' in listProductOrPicklist) {
      const picklist = listProductOrPicklist as Picklist;
      listProduct = this.getDecidedListProductByPicklist(picklist);
    } else {
      listProduct = listProductOrPicklist as ListProduct;
    }

    return listProduct;
  }

  getOrderTag(picklist: Picklist): string | undefined {
    const orderTag = this.getDecidedListProductByPicklist(picklist)?.order_tag;

    return !!orderTag && orderTag != '' ? orderTag : undefined;
  }

  getCandidateProductsByPicklists(
    picklists: Picklist[],
  ): Observable<CandidateProduct[]> {
    const uniqueKeys = picklists.flatMap((picklist) => {
      if (!picklist.listProducts) return '';

      return this.getAllProductUniqueKey(picklist);
    });
    const listProducts = picklists.flatMap((picklist) => picklist.listProducts);

    return this.mixProductsToListProducts(listProducts, uniqueKeys);
  }

  mixProductsToListProducts(
    listProducts: ListProduct[],
    uniqueKeys?: string[],
  ): Observable<CandidateProduct[]> {
    if (!uniqueKeys) {
      uniqueKeys =
        listProducts
          .map((listProduct) => this.getListProductUniqueKey(listProduct) ?? '')
          .filter((key) => !!key) ?? [];
    }

    if (uniqueKeys.length === 0) return of(listProducts);

    const products$ =
      this.productApiService.getProductsByUniquekeys(uniqueKeys);
    const candidateProducts$ = combineLatest([
      products$,
      of(listProducts),
    ]).pipe(
      map(([products, listProducts]) => {
        const candidateProducts: CandidateProduct[] = listProducts.map(
          (listProduct) => {
            const uniqueKey = this.getListProductUniqueKey(listProduct);

            if (!uniqueKey) return listProduct;

            const product = products.find(
              (product) => product.unique_key === uniqueKey,
            );

            if (!product) return listProduct;

            const candidateProduct = Object.assign(
              { ...listProduct },
              {
                product,
              },
            ) as CandidateProduct;

            return candidateProduct;
          },
        );

        return candidateProducts;
      }),
    );

    return candidateProducts$;
  }

  mixProductToListProduct(
    listProduct: ListProduct,
  ): Observable<CandidateProduct> {
    const uniqueKey = this.getListProductUniqueKey(listProduct);

    if (!uniqueKey) return of(listProduct);

    const product$ = this.productApiService.getProductByUniquekey(uniqueKey);
    const candidateProduct$ = combineLatest([product$, of(listProduct)]).pipe(
      map(([product, listProduct]) => {
        if (!product) return listProduct;

        const candidateProduct = Object.assign(
          { ...listProduct },
          {
            product,
          },
        ) as CandidateProduct;

        return candidateProduct;
      }),
    );

    return candidateProduct$;
  }

  getListProductThumbnail(
    listProduct: ListProduct | CandidateProduct,
  ): string | null | undefined {
    const useImage = listProduct.use_image;
    switch (useImage) {
      case ThumbnailType.Product:
        return (
          listProduct.thumbnail_image ??
          listProduct.original_thumbnail_image ??
          listProduct.product?.cache?.display_params?.image?.main
        );
      case ThumbnailType.Texture:
        return listProduct.texture_image;
      default:
        return undefined;
    }
  }

  public getPicklistStatusFromStore(
    picklist?: Picklist,
  ): Observable<PicklistStatus> {
    return this.candidateProductFacade
      .getCandidateProductsByPicklistId(picklist?.id)
      .pipe(
        map((candidateProducts: CandidateProduct[]) =>
          this.judgePicklistStatus(candidateProducts),
        ),
      );
  }

  judgePicklistStatus(candidateProducts: CandidateProduct[]): PicklistStatus {
    const isAddedProduct = candidateProducts.length > 0;
    const isDecided = !!candidateProducts.find(
      (candidate) => candidate.status === PicklistProductStatus.Decided,
    );
    const isFixed = !!candidateProducts.find(
      (candidate) => candidate.status === PicklistProductStatus.Fixed,
    );

    if (!isAddedProduct && !isDecided && !isFixed) {
      return PicklistStatus.NotYet;
    } else if (isAddedProduct && !isDecided && !isFixed) {
      return PicklistStatus.AddedProduct;
    } else if (isDecided && !isFixed) {
      return PicklistStatus.Decided;
    } else if (isFixed) {
      return PicklistStatus.Fixed;
    } else {
      return PicklistStatus.NotYet;
    }
  }

  public getUsedRoomGroups(
    picklist: Picklist,
    floorGroups: FloorGroup[],
  ): RoomGroup[] {
    return floorGroups
      .reduce((collection, floorGroup) => {
        return [...collection, ...floorGroup.room_groups];
      }, [] as RoomGroup[])
      .filter((roomGroup) => {
        return !!Object.values(roomGroup.interiorSet.parts)
          .reduce((collection, parts) => {
            return [
              ...collection,
              ...this.projectService.picklists.filter((p) =>
                parts.picklistInfo.map((info) => info.id).includes(p.id),
              ),
            ];
          }, [] as Picklist[])
          .find((p) => p.id == picklist.id);
      });
  }

  public getInteriorUsedIn(
    picklist: Picklist,
    floorGroups: FloorGroup[],
    interiorCategories: InteriorCategory[],
  ): {
    roomGroup: RoomGroup;
    categoryId: number;
    parts: InteriorSetParts;
    categoryName: string;
    picklistInfo: InteriorPicklistInfo;
    picklist: Picklist;
  }[] {
    return this.getUsedRoomGroups(picklist, floorGroups).reduce(
      (collection, roomGroup) => {
        interiorCategories.forEach((category) => {
          const categoryName = category.name + (category.type || '');
          const categoryId = category.id;
          const parts = roomGroup.interiorSet.parts[categoryId];

          if (parts) {
            const picklistInfo = parts.picklistInfo.find(
              (info) => info.id == picklist.id,
            );

            if (picklistInfo) {
              collection.push({
                roomGroup,
                categoryId,
                parts,
                categoryName,
                picklistInfo,
                picklist,
              });
            }
          }
        });

        return collection;
      },
      [] as {
        roomGroup: RoomGroup;
        categoryId: number;
        parts: InteriorSetParts;
        categoryName: string;
        picklistInfo: InteriorPicklistInfo;
        picklist: Picklist;
      }[],
    );
  }

  public getUsedExteriorSet(
    picklist: Picklist,
    exteriorCategories: ExteriorCategory[],
  ): ExteriorSet[] {
    return exteriorCategories.reduce((collection, category) => {
      collection.push(
        ...category.sets.filter(
          (s) =>
            s.picklists.filter((_picklist) => {
              return _picklist.id == picklist.id;
            }).length > 0,
        ),
      );

      return collection;
    }, [] as ExteriorSet[]);
  }

  public getExteriorUsedIn(
    picklist: Picklist,
    exteriorCategories: ExteriorCategory[],
  ): {
    exteriorSet: ExteriorSet;
    categoryId: number;
    categoryName: string;
    picklistInfo: ExteriorPicklistInfo;
    picklist: Picklist;
  }[] {
    const exteriorSets = this.getUsedExteriorSet(picklist, exteriorCategories);

    return exteriorCategories.reduce(
      (collection, category) => {
        exteriorSets
          .filter((set) => set.exterior_category_id == category.id)
          .forEach((exteriorSet) => {
            const categoryName = category.name;
            const categoryId = category.id;
            const picklistInfo = exteriorSet.picklistInfo.find(
              (info) => info.id == picklist.id,
            )!;

            collection.push({
              exteriorSet,
              categoryId,
              categoryName,
              picklistInfo,
              picklist,
            });
          });

        return collection;
      },
      [] as {
        exteriorSet: ExteriorSet;
        categoryId: number;
        categoryName: string;
        picklistInfo: ExteriorPicklistInfo;
        picklist: Picklist;
      }[],
    );
  }

  public checkEditedDecidedProductInfo(
    decidedListProductInfo?: DecidedProductInfo,
    decidedProductCacheInfo?: DecidedProductInfo,
    isEmpty?: boolean,
  ): Map<string, boolean> {
    const result = new Map<string, boolean>();

    const checkKeys: (keyof DecidedProductInfo)[] = [
      'name',
      'specification',
      'number',
      'maker',
      'sickhouse',
      'thickness',
      'price',
      'lumberjack',
      'thumbnail_image',
      'texture_image',
    ];

    checkKeys.forEach((key) => {
      if (
        !isEmpty &&
        decidedListProductInfo &&
        decidedProductCacheInfo &&
        key in decidedListProductInfo &&
        key in decidedProductCacheInfo &&
        (!!decidedListProductInfo[key] || !!decidedProductCacheInfo[key])
      ) {
        result.set(
          key,
          decidedListProductInfo[key] != decidedProductCacheInfo[key],
        );
      } else {
        result.set(key, false);
      }
    });

    return result;
  }

  public isEditedDecidedProductInfo(
    decidedListProductInfo?: DecidedProductInfo,
    decidedProductCacheInfo?: DecidedProductInfo,
  ): boolean {
    const results = this.checkEditedDecidedProductInfo(
      decidedListProductInfo,
      decidedProductCacheInfo,
    );

    return !![...results.values()].find((result) => {
      return result == true;
    });
  }

  //usecase
  public checkListLink(picklist: Picklist): Observable<undefined> {
    const onEnd$ = new Subject<undefined>();

    if (picklist.list_link_id) {
      this.dialog
        .open(ListLinkCheckDialogComponent, {
          data: picklist,
          disableClose: true,
        })
        .afterClosed()
        .subscribe((r) => {
          if (r) {
            this.removeListLink(picklist).subscribe((_) => {
              onEnd$.next(undefined);
              onEnd$.complete();
            });
          } else {
            onEnd$.next(undefined);
            onEnd$.complete();
          }
        });
    } else {
      setTimeout(() => {
        onEnd$.next(undefined);
        onEnd$.complete();
      }, 10);
    }

    return onEnd$.asObservable();
  }

  //usecase
  public removeListLink(picklist: Picklist): Observable<undefined> {
    const onEnd$ = new Subject<undefined>();
    this.apiProjectService
      .removeListLink(picklist.project_id, picklist)
      .subscribe((_) => {
        onEnd$.next(undefined);
        onEnd$.complete();
      });

    return onEnd$.asObservable();
  }

  //usecase
  public async addListProductByManualInput(
    picklist: Picklist,
    isOnlyRequest?: boolean,
  ): Promise<ListProduct | undefined> {
    const emptyProductInfo = this.getEmptyDecidedProduct();
    const emptyListProduct = this.getEmptyListProduct();
    const result = await firstValueFrom(
      this.dialog
        .open(AddManualInputCandidateDialogComponent, {
          minWidth: 950,
          maxHeight: 800,
          data: {
            newDecidedProductInfo: emptyProductInfo,
            listProduct: emptyListProduct,
            picklist,
            productCache: undefined,
            createMode: true,
          },
        })
        .afterClosed(),
    );

    if (result) {
      return this.addListProductAndFillId(picklist, result, isOnlyRequest);
    } else {
      return undefined;
    }
  }

  isColorImage(
    src: string | null | undefined,
    listProduct: ListProduct,
  ): boolean {
    const colorImage: ColorImage | undefined =
      listProduct.color_image ?? undefined;

    if (src) {
      return src === colorImage?.filePath
        ? src === colorImage?.filePath
        : !!src.match(/^.*\/color\/.*/);
    } else {
      return false;
    }
  }

  getAllDecidedProductsByPicklists(
    picklists: Picklist[],
    type: PicklistType,
  ): Observable<Product[]> {
    const uniqueKeys = this.getAllPicklistUniqueKeyOfPicklistType(
      picklists,
      type,
    );

    return this.productApiService.getProductsByUniquekeys(uniqueKeys);
  }

  judgeSelectableStatuses(
    canRequest: boolean,
    isBrowseMode: boolean,
    listProduct: ListProduct | CandidateProduct,
  ): Observable<
    Map<PicklistProductStatus, { canSelect: boolean; tooltipText?: string }>
  > {
    // 対象の候補製品が確定製品だった場合=>全ステータスを選択可能
    // 対象の候補製品以外に確定候補がある場合=>仮採用・確定が選択不可
    // 閲覧モードの場合は全て選択不可
    // 提案ステータスは提案権限がないと選択不可
    // 判定条件変わったらjudgeNonAvailableStatus()の判定条件も変更する必要あり（要改善）
    // 選択できるステータスの場合canSelectにtrueをセット
    // 選択できないステータスの場合はtooltipTextにできない理由を書いてあげる
    // 廃盤製品の場合却下しか選択できなくする

    const statuses = Object.values(PicklistProductStatus);
    const isFixedPicklist$ = this.isFixed$(listProduct.list_id);
    const isFixedProduct = listProduct.status === PicklistProductStatus.Fixed;
    const isDiscontinue =
      listProduct.product?.state === ProductState.Discontinued;

    const isSelectableStatues$: Observable<
      Map<PicklistProductStatus, { canSelect: boolean; tooltipText?: string }>
    > = isFixedPicklist$.pipe(
      map((isFixedPicklist) => {
        const isSelectableEachStatuses = new Map<
          PicklistProductStatus,
          { canSelect: boolean; tooltipText?: string }
        >();
        statuses.forEach((status) => {
          if (isBrowseMode) {
            isSelectableEachStatuses.set(status, {
              canSelect: false,
              tooltipText: '閲覧モードのため編集できません',
            });
          } else {
            switch (status) {
              case PicklistProductStatus.Requested:
                if (isDiscontinue) {
                  isSelectableEachStatuses.set(status, {
                    canSelect: false,
                    tooltipText: '廃盤製品のため選択できません',
                  });
                  break;
                }
                isSelectableEachStatuses.set(
                  status,
                  canRequest
                    ? { canSelect: true }
                    : {
                        canSelect: false,
                        tooltipText: '提案権限がないため選択できません',
                      },
                );
                break;
              case PicklistProductStatus.Decided:
              case PicklistProductStatus.Fixed:
                if (isDiscontinue) {
                  isSelectableEachStatuses.set(status, {
                    canSelect: false,
                    tooltipText: '廃盤製品のため選択できません',
                  });
                  break;
                }
                isSelectableEachStatuses.set(
                  status,
                  isFixedProduct
                    ? { canSelect: true }
                    : {
                        canSelect: !isFixedPicklist,
                        tooltipText: !isFixedPicklist
                          ? undefined
                          : '候補製品のいずれかが確定になっているため選択できません',
                      },
                );
                break;
              default:
                if (isDiscontinue && status !== PicklistProductStatus.Reject) {
                  isSelectableEachStatuses.set(status, {
                    canSelect: false,
                    tooltipText: '廃盤製品のため選択できません',
                  });
                  break;
                }
                isSelectableEachStatuses.set(status, { canSelect: true });
                break;
            }
          }
        });

        return isSelectableEachStatuses;
      }),
    );

    return isSelectableStatues$;
  }

  public judgeNonAvailableStatus(
    status: PicklistProductStatus | string,
    canRequest: boolean,
    listProduct: ListProduct,
    isBrowseMode: boolean,
  ): Observable<boolean> {
    // judgeSelectableStatus()と判定条件は同じ(status単体で判定したい・Mapで結果が返ってくると不便な時用)
    // 選択できないステータスの場合trueを返す
    const isFixedProduct = listProduct.status === PicklistProductStatus.Fixed;

    return this.isFixed$(listProduct.list_id).pipe(
      map((isFixed) => {
        if (isBrowseMode) return true;
        switch (status) {
          case PicklistProductStatus.Requested:
            return isFixed && !isFixedProduct ? true : !canRequest;
          case PicklistProductStatus.Decided:
          case PicklistProductStatus.Fixed:
            if (isFixedProduct) return false;

            return isFixed;
          default:
            return false;
        }
      }),
    );
  }

  //usecase?
  uploadThumbnailOrTextureImage(
    projectId: number,
    picklist: Picklist,
    listProduct: ListProduct,
  ): Observable<{ thumbnail?: string; texture?: string }> {
    const thumbnailOrTexture$ = new Subject<{
      thumbnail?: string;
      texture?: string;
    }>();
    this.fileUploader
      .uploadImageForListProduct(projectId, picklist, listProduct)
      .pipe(take(1))
      .subscribe({
        next: (v) => {
          const uploded: { thumbnail?: string; texture?: string } = {
            thumbnail: undefined,
            texture: undefined,
          };
          uploded.thumbnail = v.thumbnail ?? undefined;
          uploded.texture = v.texture ?? undefined;
          thumbnailOrTexture$.next(uploded);
        },
        error: (e) => console.log('error upload file', e),
        complete: () => console.log('complete'),
      });

    return thumbnailOrTexture$;
  }

  //logicいきだがuploadedImages取得は別
  async changeNewThumbnailOrTextureImage(
    listProduct: ListProduct,
    picklist: Picklist,
  ): Promise<ListProduct> {
    const uploadedImages = await firstValueFrom(
      this.uploadThumbnailOrTextureImage(
        picklist.project_id,
        picklist,
        listProduct,
      ),
    );

    listProduct.thumbnail_image = uploadedImages.thumbnail
      ? uploadedImages.thumbnail
      : listProduct.thumbnail_image;

    listProduct.texture_image = uploadedImages.texture
      ? uploadedImages.texture
      : listProduct.texture_image;

    return listProduct;
  }

  //usecase
  createColorImageForAddManualInput(
    listProduct: ListProduct,
  ): Observable<ColorImage> {
    const addManualInputColorImage$ = new Subject<ColorImage>();
    this.apiProjectService
      .generateColorImage(
        listProduct.project_id,
        listProduct.id,
        listProduct.color_image!,
      )
      .subscribe((colorInfo) => {
        addManualInputColorImage$.next(colorInfo);
      });

    return addManualInputColorImage$;
  }

  //usecase
  async addColorInfoToAddedManualInput(
    listProduct: ListProduct,
  ): Promise<ListProduct> {
    const colorInfo = await firstValueFrom(
      this.createColorImageForAddManualInput(listProduct),
    );

    listProduct.color_image! = colorInfo;
    listProduct.thumbnail_image = colorInfo.filePath;

    const manualInputHasColorImage = await firstValueFrom(
      this.productApiService.updateListProduct(listProduct),
    );

    return manualInputHasColorImage;
  }

  judgeIncludeFixedProductInPicklists(
    picklists: Picklist[],
  ): Observable<boolean> {
    const picklistIds = picklists.map((picklist) => picklist.id);

    return this.candidateProductFacade
      .getCandidateProductsByPicklistIds(picklistIds)
      .pipe(
        map(
          (candidates) =>
            !!candidates.find(
              (candidate) => candidate.status === PicklistProductStatus.Fixed,
            ),
        ),
      );
  }

  getEmptyPicklist(
    projectId: number,
    picklistType: PicklistType,
    name: string,
    parentPicklist?: Picklist,
  ) {
    const emptyPicklist: Picklist = {
      ...initPicklist(picklistType),
      assign_type: 'input popular name',
      project_id: projectId,
      name,
      is_parent: !parentPicklist,
      group_id: parentPicklist?.id ?? null,
    };

    return emptyPicklist;
  }

  judgePicklistTypeByRegion(region: ProjectRangeType): PicklistType {
    switch (region) {
      case ProjectRangeType.Window:
        return PicklistType.WindowFrame;
      case ProjectRangeType.Door:
        return PicklistType.DoorFrame;
      case ProjectRangeType.Shutter:
        return PicklistType.Shutter;
      case ProjectRangeType.Interior:
        return PicklistType.Interior;
      case ProjectRangeType.Exterior:
        return PicklistType.Exterior;
      default:
        return PicklistType.Other;
    }
  }

  addJoineryPicklist(
    projectId: number,
    region: ProjectRangeType,
    businessGroupId: number,
    standardComponentId: number,
    picklistName: string,
    parentPicklist?: Picklist,
  ): Observable<Picklist> {
    const newPicklist: Picklist = {
      ...this.getEmptyPicklist(
        projectId,
        this.judgePicklistTypeByRegion(region),
        picklistName,
        parentPicklist,
      ),
      joinery_standard_component_id: standardComponentId,
    };

    return this.picklistApi.addPicklistToStandardComponent(
      projectId,
      region,
      businessGroupId,
      standardComponentId,
      newPicklist,
      parentPicklist,
    );
  }

  async addJoineryPicklistToComponent(
    projectId: number,
    region: ProjectRangeType,
    businessGroupId: number,
    component: JoineryComponent,
    picklistName: string,
    parentPicklist?: Picklist,
  ): Promise<Picklist[]> {
    // 親材料がなかったら親材料も作る
    if (parentPicklist) {
      const newPicklist: Picklist = {
        ...this.getEmptyPicklist(
          projectId,
          this.judgePicklistTypeByRegion(region),
          picklistName,
          parentPicklist,
        ),
        joinery_standard_component_id: component.joinery_standard_component_id,
        joinery_component_ids: [component.id],
      };

      return firstValueFrom(
        this.picklistApi
          .addPicklistToComponent(
            projectId,
            region,
            businessGroupId,
            component,
            newPicklist,
          )
          .pipe(map((picklist) => [picklist])),
      );
    } else {
      const parent: Picklist = {
        ...this.getEmptyPicklist(
          projectId,
          this.judgePicklistTypeByRegion(region),
          picklistName,
        ),
        joinery_standard_component_id: component.joinery_standard_component_id,
      };
      const newParent = await firstValueFrom(
        this.picklistApi.addPicklistToStandardComponent(
          projectId,
          region,
          businessGroupId,
          component.joinery_standard_component_id,
          parent,
          parentPicklist,
        ),
      );
      const child: Picklist = {
        ...this.getEmptyPicklist(
          projectId,
          this.judgePicklistTypeByRegion(region),
          picklistName,
          newParent,
        ),
        joinery_standard_component_id: component.joinery_standard_component_id,
        joinery_component_ids: [component.id],
      };

      return firstValueFrom(
        this.picklistApi
          .addPicklistToComponent(
            projectId,
            region,
            businessGroupId,
            component,
            child,
          )
          .pipe(map((picklist) => [newParent, picklist])),
      );
    }
  }

  duplicateJoineryPicklist(
    projectId: number,
    region: ProjectRangeType,
    businessGroupId: number,
    picklist: Picklist,
  ): Observable<Picklist> {
    const newPicklist: Picklist = {
      ...picklist,
      name: picklist.name + 'のコピー',
      joinery_component_ids: [],
    };

    return this.picklistApi.addPicklistToStandardComponent(
      projectId,
      region,
      businessGroupId,
      newPicklist.joinery_standard_component_id!,
      newPicklist,
    );
  }
}
