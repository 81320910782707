import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'ls-basic-button',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule],
  templateUrl: './basic-button.component.html',
  styleUrls: ['./basic-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasicButtonComponent {
  @Input() label: string;
  @Input() prefixIcon: string;
  @Input() suffixIcon: string;
  @Input() active: boolean;
  @Input() disable: boolean;
  @Input() warn: boolean;
  @Input() color: 'default' | 'black' | 'warn' | 'primary' = 'default';
  @Output() emitClick = new EventEmitter<MouseEvent>();

  onEmit(event: MouseEvent) {
    this.emitClick.emit(event);
  }
}
