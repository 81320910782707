<mat-dialog-content>
  <div class="header">
    <h3>
      この製品は複数のカテゴリーに属しています。<br />どのカテゴリーで検索しますか？
    </h3>
  </div>
  <div class="categories" *ngIf="!isLoading">
    <div class="category-container">
      <mat-radio-group [(ngModel)]="selectedCategories">
        <mat-radio-button
          *ngFor="let category of categories"
          [value]="category['id']"
          color="primary"
          [checked]="selectedCategories == category['id']"
          >{{ category['name'] }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="button-container">
    <a ls-button (click)="cancel()" [border]="true"> キャンセル </a>
    <button
      class="button-search"
      mat-flat-button
      color="primary"
      (click)="okClick()"
    >
      検索する
    </button>
  </div>
  <ls-spinner *ngIf="isLoading"></ls-spinner>
</mat-dialog-content>
