import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { IllegalInteriorList } from '@shared/models/response/sub/illegal-interior-list';

import { List } from '../../models/list';
import { ButtonComponent } from '../../ui/button/button/button.component';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'ls-alert-interior-restriction-dialog',
    templateUrl: './alert-interior-restriction-dialog.component.html',
    styleUrls: ['./alert-interior-restriction-dialog.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        MatLegacyDialogModule,
        NgFor,
        ButtonComponent,
    ],
})
export class AlertInteriorRestrictionDialogComponent {
  lowLang: List<string> = {
    '《不》': '下地共不燃材',
    '〈不〉': '不燃材',
    '《準》': '下地共準不燃材',
    '〈準〉': '準不燃材以上',
    '〈難〉': '難燃材以上',
  };

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      illegal: IllegalInteriorList[];
      setPicklist: boolean;
      decided: boolean;
      singleSelect: boolean;
    },
    private dialogRef: MatDialogRef<
      AlertInteriorRestrictionDialogComponent,
      any
    >,
  ) {
    dialogRef.disableClose = true;
  }

  cancel() {
    this.dialogRef.close(false);
  }

  clickStore() {
    this.dialogRef.close(true);
  }
}
