import { Injectable } from '@angular/core';

import { SrcService } from './src.service';

@Injectable({ providedIn: 'root' })
export class PicklistSrcService extends SrcService {
  size(src: string, size: string) {
    // {prefix}/{id}-{size}.{extension}
    const match = src.match(
      /(.*)\/([0-9a-z\-]*)-([a-z]*)\.(png|jpg|jpeg|gif)$/,
    );
    if (!match) {
      return src;
    }
    const [, prefix, id, , extension] = match;

    return `${prefix}/${id}-${size}.${extension}`;
  }
}
