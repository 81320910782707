<h3>製品情報を手入力で追加する</h3>
<div class="message">
  <p *ngIf="!productCache">
    採用製品が決定している場合は必ず製品名を入力してください。<br />採用製品が未決定で一部の項目のみ埋める場合は、製品名を入力せずに保存してください。
  </p>
  <p
    class="alert"
    *ngIf="
      productCache &&
      productCache.restriction &&
      !newDecidedProductInfo.certification
    "
  >
    「認定番号・告示」を選択してください。
  </p>
</div>

<div class="container">
  <table>
    <tr>
      <th>メーカー名</th>
      <td>
        <input
          name="product_maker"
          type="text"
          [(ngModel)]="newDecidedProductInfo.maker"
          placeholder=""
          [matAutocomplete]="auto"
          (click)="autocomplete.openPanel()"
          [matAutocompleteDisabled]="newDecidedProductInfo.maker"
        />
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option
            *ngFor="let maker of manualMakersSuggention"
            [value]="maker"
          >
            {{ maker }}
          </mat-option>
        </mat-autocomplete>
      </td>
    </tr>
    <tr>
      <th>製品名<ls-required-tag></ls-required-tag></th>
      <td>
        <input
          name="product_name"
          type="text"
          [(ngModel)]="newDecidedProductInfo.name"
          placeholder=""
        />
      </td>
    </tr>
    <tr>
      <th>品番</th>
      <td>
        <input
          name="product_number"
          type="text"
          [(ngModel)]="newDecidedProductInfo.number"
          placeholder=""
        />
      </td>
    </tr>
    <tr>
      <th>規格</th>
      <td>
        <input
          name="product_specification"
          type="text"
          [(ngModel)]="newDecidedProductInfo.specification"
          placeholder="例) 厚み:10.0, 形状:600角 など品番で指示できない情報を入力"
        />
      </td>
    </tr>
    <tr>
      <th>認定番号・告示</th>
      <td>
        <mat-select
          #select
          *ngIf="restrictionSet.length > 0"
          [ngModel]="newDecidedProductInfo.certification"
          (selectionChange)="checkIllegalRoomAtSelect($event)"
        >
          <mat-option [value]="''"></mat-option>
          <mat-option
            *ngFor="let set of restrictionSet"
            value="{{ set.no }}"
            [matTooltip]="set.memo"
            >{{ set.no }}</mat-option
          >
        </mat-select>
        <input
          *ngIf="restrictionSet.length === 0"
          name="product_certification"
          type="text"
          [(ngModel)]="newDecidedProductInfo.certification"
          (change)="checkIllegalRoomAtInput($event.target)"
          placeholder=""
        />
      </td>
    </tr>
    <tr *ngIf="picklist.type == 'interior'">
      <th>シックハウス規制</th>
      <td>
        <input
          name="product_sickhouse"
          type="text"
          [(ngModel)]="newDecidedProductInfo.sickhouse"
          placeholder=""
        />
      </td>
    </tr>
    <tr class="flex">
      <th class="flex__item">厚み</th>
      <th class="flex__item">設計価格</th>
      <td class="thickness flex__item">
        <input
          name="product_thickness"
          type="number"
          class="small"
          [(ngModel)]="newDecidedProductInfo.thickness"
          placeholder=""
        /><span>mm</span>
      </td>
      <td class="price flex__item">
        <input
          name="product_price"
          type="number"
          class="small"
          [(ngModel)]="newDecidedProductInfo.price"
          placeholder=""
        />
        <mat-radio-group
          name="product_price_unit"
          [(ngModel)]="newDecidedProductInfo.price_unit"
        >
          <mat-radio-button value="円/㎡">円/㎡</mat-radio-button>
          <mat-radio-button value="円/個">円/個</mat-radio-button>
        </mat-radio-group>
      </td>
    </tr>
    <tr>
      <th>材工／材のみ</th>
      <td class="lumberjack">
        <mat-radio-group
          name="product_lumberjack"
          [(ngModel)]="newDecidedProductInfo.lumberjack"
        >
          <mat-radio-button value="材工">材工</mat-radio-button>
          <mat-radio-button value="材のみ">材のみ</mat-radio-button>
        </mat-radio-group>
      </td>
    </tr>
  </table>
  <mat-divider class="vertical" [vertical]="true"></mat-divider>

  <div class="images">
    <div class="image-header">
      <span>製品画像</span>
      <span class="spacer"></span>
    </div>

    <div class="image-container">
      <div>
        <input
          type="file"
          class="uploader"
          name="thumbnail"
          accept="image/*"
          #uploadThumbnail
          (change)="selectThumbnail(uploadThumbnail.files?.[0])"
          (click)="clearFilePath(uploadThumbnail)"
        />
        <div class="product-image">
          <img *ngIf="!!newThumbnailData" [src]="newThumbnailData" />
          <img
            *ngIf="!newThumbnailData && !!newDecidedProductInfo.thumbnail_image"
            [lsSrc]="newDecidedProductInfo.thumbnail_image"
            type="picklist"
            [size]="
              isColorImage(newDecidedProductInfo.thumbnail_image)
                ? 'original'
                : 'medium'
            "
          />
          <div
            *ngIf="!newThumbnailData && !newDecidedProductInfo.thumbnail_image"
            class="no-image"
          >
            未設定
          </div>
        </div>
        <div class="image-container__delete-button-wrap">
          <ls-basic-button
            label="削除する"
            prefixIcon="delete"
            (emitClick)="deleteThumbnail()"
            color="warn"
            [disable]="
              !newThumbnailData && !newDecidedProductInfo.thumbnail_image
            "
          ></ls-basic-button>
        </div>
      </div>

      <div class="image-container__buttons image-container__buttons--thumbnail">
        <ls-basic-button
          label="アップロードする"
          prefixIcon="file_upload"
          (emitClick)="uploadThumbnail.click()"
        ></ls-basic-button>
        <ls-basic-button
          label="塗装画像を作成する"
          prefixIcon="format_color_fill"
          (emitClick)="onClickCreateImage()"
        ></ls-basic-button>
      </div>
    </div>

    <mat-divider class="horizon"></mat-divider>

    <div class="image-header">
      <span>パース用画像</span>
      <span class="spacer"></span>
    </div>

    <div class="image-container">
      <div>
        <input
          type="file"
          class="uploader"
          name="texture"
          accept="image/*"
          #uploadTexture
          (change)="selectTexture(uploadTexture.files?.[0])"
          (click)="clearFilePath(uploadTexture)"
        />
        <div class="texture-image">
          <img *ngIf="!!newTextureData" [src]="newTextureData" size="medium" />
          <img
            *ngIf="!newTextureData && !!newDecidedProductInfo.texture_image"
            [lsSrc]="newDecidedProductInfo.texture_image"
            type="picklist"
            size="medium"
          />
          <div
            *ngIf="!newTextureData && !newDecidedProductInfo.texture_image"
            class="no-image"
          >
            未設定
          </div>
        </div>

        <div class="image-container__delete-button-wrap">
          <ls-basic-button
            label="削除する"
            prefixIcon="delete"
            (emitClick)="deleteTexture()"
            color="warn"
            [disable]="!newTextureData && !newDecidedProductInfo.texture_image"
          ></ls-basic-button>
        </div>
      </div>

      <div class="image-container__buttons">
        <ls-basic-button
          label="アップロードする"
          prefixIcon="format_color_fill"
          (emitClick)="uploadTexture.click()"
        ></ls-basic-button>
        <div
          [class.disabled]="
            !newTextureData && !newDecidedProductInfo.texture_image
          "
          class="size"
        >
          <div class="size__title">寸法</div>
          <div class="size__input">
            <div>
              縦&nbsp;<input
                name="prodct_texture_height"
                type="number"
                [(ngModel)]="newDecidedProductInfo.texture_height"
                placeholder=""
              />&nbsp;mm
            </div>
            <div>
              横&nbsp;<input
                name="product_texture_width"
                type="number"
                [(ngModel)]="newDecidedProductInfo.texture_width"
                placeholder=""
              />&nbsp;mm
            </div>
          </div>
        </div>
      </div>
    </div>

    <mat-divider class="horizon"></mat-divider>

    <div class="thumbnail-switch">
      <p class="thumbnail-switch__title">サムネイルに使用する画像</p>
      <mat-radio-group
        class="thumbnail-switch__radio-buttons"
        [(ngModel)]="data.listProduct.use_image"
        (change)="selectUseImage(listProduct.use_image!)"
      >
        <mat-radio-button
          [disabled]="
            !newDecidedProductInfo.uploadedThumbnail &&
            !newDecidedProductInfo.thumbnail_image
          "
          [value]="_thumbnailType.Product"
          >製品画像</mat-radio-button
        >
        <mat-radio-button
          [disabled]="!newDecidedProductInfo.uploadedTexture"
          [value]="_thumbnailType.Texture"
          >パース用画像</mat-radio-button
        >
      </mat-radio-group>
    </div>
  </div>
</div>

<mat-dialog-actions>
  <div class="left">
    <ls-button (click)="cancel()" [border]="true">キャンセル</ls-button>
  </div>
  <div class="right">
    <ls-button
      (click)="onSave()"
      theme="primary"
      [border]="true"
      [class.disabled]="!canSubmit || !isSetTexture"
      >保存</ls-button
    >
  </div>
</mat-dialog-actions>
