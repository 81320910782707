import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';

import { CurrentTenantIdLogic } from '../_logic/current-tenant-id.logic';
import { Tenant, UserTenant } from '../_type/tenant';

@Injectable({
  providedIn: 'root',
})
export class TenantApi {
  private http = inject(HttpClient);
  private tenantIdLogic = inject(CurrentTenantIdLogic);

  findManyByUserId(userId: number): Observable<UserTenant[]> {
    return this.http.get<UserTenant[]>(`api/v2/users/${userId}/tenants`);
  }

  findUnique(id: number): Observable<Tenant> {
    return this.http.get<Tenant>(`api/v2/tenants/${id}`).pipe(
      catchError((error: HttpErrorResponse) => {
        this.tenantIdLogic.invalidIdHandler(error);

        return throwError(() => error);
      }),
    );
  }

  setTenantId(id: number): Observable<void> {
    const params = { tenant_id: id };

    return this.http.post<void>(`api/v2/tenants/${id}`, params);
  }
}
