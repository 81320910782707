import { Injectable } from '@angular/core';

export const DUMMY_IMAGE_DEFAULT_BG_COLOR = '9e9e9e';
export const DUMMY_IMAGE_DEFAULT_TEXT_COLOR = 'f5f5f5';
export const DUMMY_IMAGE_DEFAULT_FILE_NAME = 'dummyimage';
export const DUMMY_IMAGE_DEFAULT_FORMAT = 'png';
export const DUMMY_IMAGE_DEFAULT_TEXT = '  No image  ';

export type DummyImageFormat = 'png' | 'jpg' | 'gif';

export interface DummyImageParams {
  width?: number;
  height?: number;
  ratio?: [number, number];
  bgColor?: string;
  textColor?: string;
  fileName?: string;
  format?: DummyImageFormat;
  text?: string;
}

@Injectable({ providedIn: 'root' })
/**
 * @link https://dummyimage.com/
 */
export class DummyImageService {
  private size(params: DummyImageParams) {
    const hasWidth = !!params.width;
    const hasRatio = !!params.ratio;
    const hasHeight = !!params.height;

    if (!(hasWidth || (hasRatio && hasHeight))) {
      throw new Error(
        'width or ratio and height are required. See https://dummyimage.com/',
      );
    }

    const values: string[] = [];

    if (hasWidth) {
      values.push(params.width + '');
    }

    if (hasRatio) {
      values.push(`${params.ratio![0]}:${params.ratio![1]}`);
    }

    if (hasHeight) {
      values.push(params.height + '');
    }

    return values.join('x');
  }

  srcUrl(params: DummyImageParams) {
    const urlPaths = [
      'https://dummyimage.com',
      this.size(params),
      params.bgColor || DUMMY_IMAGE_DEFAULT_BG_COLOR,
      params.textColor || DUMMY_IMAGE_DEFAULT_TEXT_COLOR,
      `${params.fileName || DUMMY_IMAGE_DEFAULT_FILE_NAME}.${
        params.format || DUMMY_IMAGE_DEFAULT_FORMAT
      }`,
      `?text=${params.text || DUMMY_IMAGE_DEFAULT_TEXT}`,
    ];

    return urlPaths.join('/');
  }
}
