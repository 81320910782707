import { Injectable } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MatLegacyDialog as MatDialog,
} from '@angular/material/legacy-dialog';
import { ListProduct } from '@shared/models/response/list-product';
import { Picklist } from '@shared/models/response/picklist';

import { List } from '../../models/list';
import { ColorImage } from '../../models/response/sub/color-image';

import { AddColorImageDialogComponent } from './add-color-image-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class AddColorImageDialogService extends MatDialog {
  openDialog(
    listProduct: ListProduct,
    picklist?: Picklist,
  ): MatDialogRef<AddColorImageDialogComponent, ColorImage> {
    return this.open(AddColorImageDialogComponent, {
      data: { listProduct, picklist },
    });
  }

  paintNoLists: List<string>[] = [
    { pH: 'N', pV: '95', pC: '' },
    { pH: 'N', pV: '93', pC: '' },
    { pH: 'N', pV: '90', pC: '' },
    { pH: 'N', pV: '87', pC: '' },
    { pH: 'N', pV: '85', pC: '' },
    { pH: 'N', pV: '82', pC: '' },
    { pH: 'N', pV: '80', pC: '' },
    { pH: 'N', pV: '77', pC: '' },
    { pH: 'N', pV: '75', pC: '' },
    { pH: 'N', pV: '72', pC: '' },
    { pH: 'N', pV: '70', pC: '' },
    { pH: 'N', pV: '67', pC: '' },
    { pH: 'N', pV: '65', pC: '' },
    { pH: 'N', pV: '60', pC: '' },
    { pH: 'N', pV: '55', pC: '' },
    { pH: 'N', pV: '50', pC: '' },
    { pH: 'N', pV: '45', pC: '' },
    { pH: 'N', pV: '40', pC: '' },
    { pH: 'N', pV: '35', pC: '' },
    { pH: 'N', pV: '30', pC: '' },
    { pH: 'N', pV: '25', pC: '' },
    { pH: 'N', pV: '20', pC: '' },
    { pH: 'N', pV: '15', pC: '' },
    { pH: 'N', pV: '10', pC: '' },
    { pH: '02', pV: '80', pC: 'H' },
    { pH: '02', pV: '80', pC: 'L' },
    { pH: '02', pV: '70', pC: 'T' },
    { pH: '02', pV: '60', pC: 'H' },
    { pH: '02', pV: '50', pC: 'H' },
    { pH: '05', pV: '40', pC: 'D' },
    { pH: '05', pV: '30', pC: 'B' },
    { pH: '05', pV: '20', pC: 'B' },
    { pH: '05', pV: '90', pC: 'A' },
    { pH: '05', pV: '85', pC: 'A' },
    { pH: '05', pV: '80', pC: 'A' },
    { pH: '05', pV: '75', pC: 'A' },
    { pH: '05', pV: '70', pC: 'A' },
    { pH: '05', pV: '60', pC: 'B' },
    { pH: '05', pV: '50', pC: 'B' },
    { pH: '05', pV: '40', pC: 'B' },
    { pH: '05', pV: '92', pC: 'B' },
    { pH: '05', pV: '90', pC: 'B' },
    { pH: '05', pV: '85', pC: 'B' },
    { pH: '05', pV: '80', pC: 'B' },
    { pH: '05', pV: '75', pC: 'B' },
    { pH: '05', pV: '70', pC: 'B' },
    { pH: '05', pV: '50', pC: 'D' },
    { pH: '05', pV: '30', pC: 'D' },
    { pH: '05', pV: '90', pC: 'D' },
    { pH: '05', pV: '80', pC: 'D' },
    { pH: '05', pV: '70', pC: 'D' },
    { pH: '05', pV: '60', pC: 'D' },
    { pH: '05', pV: '60', pC: 'F' },
    { pH: '05', pV: '60', pC: 'L' },
    { pH: '05', pV: '50', pC: 'F' },
    { pH: '05', pV: '40', pC: 'F' },
    { pH: '05', pV: '80', pC: 'L' },
    { pH: '05', pV: '70', pC: 'L' },
    { pH: '07', pV: '60', pC: 'H' },
    { pH: '07', pV: '60', pC: 'T' },
    { pH: '07', pV: '50', pC: 'L' },
    { pH: '07', pV: '40', pC: 'P' },
    { pH: '07', pV: '30', pC: 'L' },
    { pH: '07', pV: '20', pC: 'H' },
    { pH: '07', pV: '80', pC: 'H' },
    { pH: '07', pV: '70', pC: 'F' },
    { pH: '09', pV: '60', pC: 'B' },
    { pH: '09', pV: '60', pC: 'D' },
    { pH: '09', pV: '50', pC: 'F' },
    { pH: '09', pV: '30', pC: 'F' },
    { pH: '09', pV: '30', pC: 'D' },
    { pH: '09', pV: '20', pC: 'B' },
    { pH: '09', pV: '80', pC: 'D' },
    { pH: '09', pV: '70', pC: 'D' },
    { pH: '09', pV: '60', pC: 'H' },
    { pH: '09', pV: '60', pC: 'L' },
    { pH: '09', pV: '50', pC: 'L' },
    { pH: '09', pV: '40', pC: 'L' },
    { pH: '09', pV: '30', pC: 'L' },
    { pH: '09', pV: '20', pC: 'D' },
    { pH: '09', pV: '90', pC: 'D' },
    { pH: '09', pV: '80', pC: 'F' },
    { pH: '09', pV: '70', pC: 'H' },
    { pH: '09', pV: '70', pC: 'T' },
    { pH: '09', pV: '50', pC: 'T' },
    { pH: '12', pV: '50', pC: 'H' },
    { pH: '12', pV: '50', pC: 'L' },
    { pH: '12', pV: '30', pC: 'H' },
    { pH: '12', pV: '80', pC: 'F' },
    { pH: '12', pV: '80', pC: 'H' },
    { pH: '12', pV: '70', pC: 'L' },
    { pH: '12', pV: '70', pC: 'T' },
    { pH: '15', pV: '60', pC: 'F' },
    { pH: '15', pV: '40', pC: 'H' },
    { pH: '15', pV: '30', pC: 'F' },
    { pH: '15', pV: '30', pC: 'D' },
    { pH: '15', pV: '90', pC: 'A' },
    { pH: '15', pV: '85', pC: 'A' },
    { pH: '15', pV: '80', pC: 'A' },
    { pH: '15', pV: '75', pC: 'A' },
    { pH: '15', pV: '70', pC: 'A' },
    { pH: '15', pV: '65', pC: 'A' },
    { pH: '15', pV: '50', pC: 'B' },
    { pH: '15', pV: '40', pC: 'B' },
    { pH: '15', pV: '92', pC: 'B' },
    { pH: '15', pV: '90', pC: 'B' },
    { pH: '15', pV: '85', pC: 'B' },
    { pH: '15', pV: '80', pC: 'B' },
    { pH: '15', pV: '75', pC: 'B' },
    { pH: '15', pV: '70', pC: 'B' },
    { pH: '15', pV: '65', pC: 'B' },
    { pH: '15', pV: '60', pC: 'B' },
    { pH: '15', pV: '90', pC: 'D' },
    { pH: '15', pV: '80', pC: 'D' },
    { pH: '15', pV: '70', pC: 'D' },
    { pH: '15', pV: '60', pC: 'D' },
    { pH: '15', pV: '50', pC: 'D' },
    { pH: '15', pV: '40', pC: 'D' },
    { pH: '15', pV: '30', pC: 'B' },
    { pH: '15', pV: '20', pC: 'B' },
    { pH: '15', pV: '80', pC: 'F' },
    { pH: '15', pV: '70', pC: 'H' },
    { pH: '17', pV: '70', pC: 'H' },
    { pH: '17', pV: '70', pC: 'L' },
    { pH: '17', pV: '60', pC: 'H' },
    { pH: '17', pV: '50', pC: 'L' },
    { pH: '17', pV: '50', pC: 'P' },
    { pH: '17', pV: '40', pC: 'H' },
    { pH: '17', pV: '90', pC: 'D' },
    { pH: '17', pV: '80', pC: 'D' },
    { pH: '17', pV: '70', pC: 'D' },
    { pH: '17', pV: '60', pC: 'D' },
    { pH: '17', pV: '50', pC: 'D' },
    { pH: '17', pV: '50', pC: 'F' },
    { pH: '17', pV: '40', pC: 'D' },
    { pH: '17', pV: '30', pC: 'F' },
    { pH: '17', pV: '80', pC: 'F' },
    { pH: '17', pV: '80', pC: 'H' },
    { pH: '19', pV: '60', pC: 'H' },
    { pH: '19', pV: '50', pC: 'H' },
    { pH: '19', pV: '40', pC: 'L' },
    { pH: '19', pV: '40', pC: 'H' },
    { pH: '19', pV: '40', pC: 'F' },
    { pH: '19', pV: '30', pC: 'D' },
    { pH: '19', pV: '90', pC: 'A' },
    { pH: '19', pV: '85', pC: 'A' },
    { pH: '19', pV: '80', pC: 'A' },
    { pH: '19', pV: '75', pC: 'A' },
    { pH: '19', pV: '70', pC: 'A' },
    { pH: '19', pV: '65', pC: 'A' },
    { pH: '19', pV: '50', pC: 'B' },
    { pH: '19', pV: '30', pC: 'A' },
    { pH: '19', pV: '92', pC: 'B' },
    { pH: '19', pV: '90', pC: 'B' },
    { pH: '19', pV: '85', pC: 'B' },
    { pH: '19', pV: '80', pC: 'B' },
    { pH: '19', pV: '75', pC: 'B' },
    { pH: '19', pV: '70', pC: 'B' },
    { pH: '19', pV: '65', pC: 'B' },
    { pH: '19', pV: '60', pC: 'B' },
    { pH: '19', pV: '90', pC: 'C' },
    { pH: '19', pV: '85', pC: 'C' },
    { pH: '19', pV: '80', pC: 'C' },
    { pH: '19', pV: '75', pC: 'C' },
    { pH: '19', pV: '70', pC: 'C' },
    { pH: '19', pV: '60', pC: 'C' },
    { pH: '19', pV: '40', pC: 'B' },
    { pH: '19', pV: '30', pC: 'B' },
    { pH: '19', pV: '90', pC: 'D' },
    { pH: '19', pV: '85', pC: 'D' },
    { pH: '19', pV: '80', pC: 'D' },
    { pH: '19', pV: '75', pC: 'D' },
    { pH: '19', pV: '70', pC: 'D' },
    { pH: '19', pV: '65', pC: 'D' },
    { pH: '19', pV: '60', pC: 'D' },
    { pH: '19', pV: '50', pC: 'D' },
    { pH: '19', pV: '90', pC: 'F' },
    { pH: '19', pV: '85', pC: 'F' },
    { pH: '19', pV: '80', pC: 'F' },
    { pH: '19', pV: '70', pC: 'F' },
    { pH: '19', pV: '60', pC: 'F' },
    { pH: '19', pV: '50', pC: 'F' },
    { pH: '19', pV: '40', pC: 'D' },
    { pH: '19', pV: '20', pC: 'B' },
    { pH: '19', pV: '85', pC: 'L' },
    { pH: '19', pV: '80', pC: 'L' },
    { pH: '19', pV: '75', pC: 'L' },
    { pH: '19', pV: '70', pC: 'L' },
    { pH: '19', pV: '60', pC: 'T' },
    { pH: '22', pV: '50', pC: 'P' },
    { pH: '22', pV: '50', pC: 'H' },
    { pH: '22', pV: '40', pC: 'H' },
    { pH: '22', pV: '90', pC: 'B' },
    { pH: '22', pV: '87', pC: 'C' },
    { pH: '22', pV: '85', pC: 'B' },
    { pH: '22', pV: '80', pC: 'B' },
    { pH: '22', pV: '75', pC: 'B' },
    { pH: '22', pV: '70', pC: 'B' },
    { pH: '22', pV: '65', pC: 'C' },
    { pH: '22', pV: '60', pC: 'B' },
    { pH: '22', pV: '90', pC: 'C' },
    { pH: '22', pV: '85', pC: 'C' },
    { pH: '22', pV: '80', pC: 'C' },
    { pH: '22', pV: '75', pC: 'C' },
    { pH: '22', pV: '70', pC: 'C' },
    { pH: '22', pV: '60', pC: 'C' },
    { pH: '22', pV: '50', pC: 'B' },
    { pH: '22', pV: '40', pC: 'B' },
    { pH: '22', pV: '85', pC: 'D' },
    { pH: '22', pV: '80', pC: 'D' },
    { pH: '22', pV: '75', pC: 'D' },
    { pH: '22', pV: '70', pC: 'D' },
    { pH: '22', pV: '60', pC: 'D' },
    { pH: '22', pV: '50', pC: 'D' },
    { pH: '22', pV: '40', pC: 'D' },
    { pH: '22', pV: '30', pC: 'B' },
    { pH: '22', pV: '90', pC: 'D' },
    { pH: '22', pV: '85', pC: 'F' },
    { pH: '22', pV: '80', pC: 'F' },
    { pH: '22', pV: '75', pC: 'F' },
    { pH: '22', pV: '70', pC: 'H' },
    { pH: '22', pV: '70', pC: 'L' },
    { pH: '22', pV: '50', pC: 'F' },
    { pH: '22', pV: '30', pC: 'D' },
    { pH: '22', pV: '90', pC: 'H' },
    { pH: '22', pV: '85', pC: 'H' },
    { pH: '22', pV: '80', pC: 'H' },
    { pH: '22', pV: '80', pC: 'L' },
    { pH: '25', pV: '80', pC: 'P' },
    { pH: '25', pV: '70', pC: 'L' },
    { pH: '25', pV: '60', pC: 'P' },
    { pH: '25', pV: '40', pC: 'H' },
    { pH: '25', pV: '90', pC: 'A' },
    { pH: '25', pV: '85', pC: 'A' },
    { pH: '25', pV: '80', pC: 'A' },
    { pH: '25', pV: '75', pC: 'A' },
    { pH: '25', pV: '70', pC: 'A' },
    { pH: '25', pV: '65', pC: 'A' },
    { pH: '25', pV: '50', pC: 'B' },
    { pH: '25', pV: '40', pC: 'B' },
    { pH: '25', pV: '92', pC: 'B' },
    { pH: '25', pV: '90', pC: 'B' },
    { pH: '25', pV: '85', pC: 'B' },
    { pH: '25', pV: '80', pC: 'B' },
    { pH: '25', pV: '75', pC: 'B' },
    { pH: '25', pV: '70', pC: 'B' },
    { pH: '25', pV: '65', pC: 'B' },
    { pH: '25', pV: '60', pC: 'B' },
    { pH: '25', pV: '90', pC: 'C' },
    { pH: '25', pV: '85', pC: 'C' },
    { pH: '25', pV: '80', pC: 'C' },
    { pH: '25', pV: '75', pC: 'C' },
    { pH: '25', pV: '70', pC: 'C' },
    { pH: '25', pV: '50', pC: 'D' },
    { pH: '25', pV: '30', pC: 'B' },
    { pH: '25', pV: '20', pC: 'B' },
    { pH: '25', pV: '90', pC: 'D' },
    { pH: '25', pV: '85', pC: 'D' },
    { pH: '25', pV: '80', pC: 'D' },
    { pH: '25', pV: '80', pC: 'H' },
    { pH: '25', pV: '70', pC: 'D' },
    { pH: '25', pV: '60', pC: 'D' },
    { pH: '25', pV: '40', pC: 'D' },
    { pH: '25', pV: '30', pC: 'D' },
    { pH: '25', pV: '85', pC: 'F' },
    { pH: '25', pV: '90', pC: 'H' },
    { pH: '27', pV: '90', pC: 'P' },
    { pH: '27', pV: '85', pC: 'H' },
    { pH: '27', pV: '70', pC: 'L' },
    { pH: '27', pV: '60', pC: 'L' },
    { pH: '27', pV: '50', pC: 'H' },
    { pH: '27', pV: '50', pC: 'D' },
    { pH: '27', pV: '90', pC: 'B' },
    { pH: '27', pV: '90', pC: 'F' },
    { pH: '27', pV: '85', pC: 'B' },
    { pH: '27', pV: '80', pC: 'B' },
    { pH: '27', pV: '70', pC: 'D' },
    { pH: '27', pV: '60', pC: 'D' },
    { pH: '27', pV: '60', pC: 'F' },
    { pH: '29', pV: '40', pC: 'H' },
    { pH: '29', pV: '92', pC: 'B' },
    { pH: '29', pV: '90', pC: 'B' },
    { pH: '29', pV: '85', pC: 'B' },
    { pH: '29', pV: '80', pC: 'B' },
    { pH: '29', pV: '70', pC: 'B' },
    { pH: '29', pV: '60', pC: 'D' },
    { pH: '29', pV: '60', pC: 'H' },
    { pH: '29', pV: '50', pC: 'D' },
    { pH: '29', pV: '90', pC: 'H' },
    { pH: '29', pV: '85', pC: 'P' },
    { pH: '29', pV: '80', pC: 'H' },
    { pH: '32', pV: '90', pC: 'D' },
    { pH: '32', pV: '80', pC: 'D' },
    { pH: '32', pV: '80', pC: 'P' },
    { pH: '32', pV: '60', pC: 'H' },
    { pH: '32', pV: '50', pC: 'L' },
    { pH: '35', pV: '90', pC: 'A' },
    { pH: '35', pV: '85', pC: 'A' },
    { pH: '35', pV: '80', pC: 'A' },
    { pH: '35', pV: '70', pC: 'A' },
    { pH: '35', pV: '65', pC: 'A' },
    { pH: '35', pV: '50', pC: 'B' },
    { pH: '35', pV: '40', pC: 'B' },
    { pH: '35', pV: '20', pC: 'B' },
    { pH: '35', pV: '92', pC: 'B' },
    { pH: '35', pV: '85', pC: 'B' },
    { pH: '35', pV: '80', pC: 'B' },
    { pH: '35', pV: '70', pC: 'B' },
    { pH: '35', pV: '60', pC: 'B' },
    { pH: '35', pV: '50', pC: 'D' },
    { pH: '35', pV: '40', pC: 'D' },
    { pH: '35', pV: '30', pC: 'B' },
    { pH: '35', pV: '90', pC: 'B' },
    { pH: '35', pV: '90', pC: 'D' },
    { pH: '35', pV: '80', pC: 'D' },
    { pH: '35', pV: '70', pC: 'D' },
    { pH: '35', pV: '70', pC: 'H' },
    { pH: '35', pV: '60', pC: 'D' },
    { pH: '35', pV: '50', pC: 'H' },
    { pH: '35', pV: '30', pC: 'D' },
    { pH: '35', pV: '80', pC: 'H' },
    { pH: '37', pV: '80', pC: 'L' },
    { pH: '37', pV: '80', pC: 'H' },
    { pH: '37', pV: '80', pC: 'D' },
    { pH: '37', pV: '60', pC: 'D' },
    { pH: '37', pV: '50', pC: 'H' },
    { pH: '37', pV: '50', pC: 'L' },
    { pH: '39', pV: '40', pC: 'P' },
    { pH: '39', pV: '80', pC: 'B' },
    { pH: '39', pV: '80', pC: 'D' },
    { pH: '39', pV: '80', pC: 'H' },
    { pH: '39', pV: '70', pC: 'H' },
    { pH: '39', pV: '60', pC: 'L' },
    { pH: '39', pV: '50', pC: 'H' },
    { pH: '39', pV: '40', pC: 'H' },
    { pH: '39', pV: '40', pC: 'L' },
    { pH: '42', pV: '90', pC: 'D' },
    { pH: '42', pV: '80', pC: 'D' },
    { pH: '42', pV: '70', pC: 'D' },
    { pH: '42', pV: '70', pC: 'H' },
    { pH: '42', pV: '50', pC: 'L' },
    { pH: '42', pV: '30', pC: 'H' },
    { pH: '45', pV: '20', pC: 'D' },
    { pH: '45', pV: '20', pC: 'B' },
    { pH: '45', pV: '90', pC: 'A' },
    { pH: '45', pV: '85', pC: 'A' },
    { pH: '45', pV: '80', pC: 'A' },
    { pH: '45', pV: '70', pC: 'A' },
    { pH: '45', pV: '65', pC: 'A' },
    { pH: '45', pV: '50', pC: 'B' },
    { pH: '45', pV: '40', pC: 'B' },
    { pH: '45', pV: '30', pC: 'B' },
    { pH: '45', pV: '90', pC: 'B' },
    { pH: '45', pV: '85', pC: 'B' },
    { pH: '45', pV: '80', pC: 'B' },
    { pH: '45', pV: '70', pC: 'B' },
    { pH: '45', pV: '60', pC: 'B' },
    { pH: '45', pV: '50', pC: 'D' },
    { pH: '45', pV: '40', pC: 'D' },
    { pH: '45', pV: '30', pC: 'D' },
    { pH: '45', pV: '90', pC: 'D' },
    { pH: '45', pV: '80', pC: 'D' },
    { pH: '45', pV: '70', pC: 'D' },
    { pH: '45', pV: '60', pC: 'D' },
    { pH: '45', pV: '60', pC: 'H' },
    { pH: '45', pV: '60', pC: 'L' },
    { pH: '45', pV: '50', pC: 'H' },
    { pH: '45', pV: '30', pC: 'L' },
    { pH: '45', pV: '80', pC: 'H' },
    { pH: '45', pV: '70', pC: 'H' },
    { pH: '45', pV: '70', pC: 'P' },
    { pH: '49', pV: '80', pC: 'H' },
    { pH: '49', pV: '70', pC: 'H' },
    { pH: '49', pV: '60', pC: 'P' },
    { pH: '49', pV: '30', pC: 'L' },
    { pH: '49', pV: '20', pC: 'H' },
    { pH: '52', pV: '70', pC: 'D' },
    { pH: '52', pV: '60', pC: 'H' },
    { pH: '52', pV: '60', pC: 'L' },
    { pH: '52', pV: '50', pC: 'L' },
    { pH: '52', pV: '40', pC: 'L' },
    { pH: '55', pV: '30', pC: 'L' },
    { pH: '55', pV: '20', pC: 'D' },
    { pH: '55', pV: '20', pC: 'B' },
    { pH: '55', pV: '90', pC: 'A' },
    { pH: '55', pV: '85', pC: 'A' },
    { pH: '55', pV: '80', pC: 'A' },
    { pH: '55', pV: '70', pC: 'A' },
    { pH: '55', pV: '60', pC: 'B' },
    { pH: '55', pV: '50', pC: 'B' },
    { pH: '55', pV: '40', pC: 'B' },
    { pH: '55', pV: '30', pC: 'B' },
    { pH: '55', pV: '90', pC: 'B' },
    { pH: '55', pV: '85', pC: 'B' },
    { pH: '55', pV: '80', pC: 'B' },
    { pH: '55', pV: '70', pC: 'B' },
    { pH: '55', pV: '60', pC: 'D' },
    { pH: '55', pV: '50', pC: 'D' },
    { pH: '55', pV: '40', pC: 'D' },
    { pH: '55', pV: '30', pC: 'D' },
    { pH: '55', pV: '90', pC: 'D' },
    { pH: '55', pV: '80', pC: 'D' },
    { pH: '55', pV: '80', pC: 'H' },
    { pH: '55', pV: '70', pC: 'D' },
    { pH: '55', pV: '70', pC: 'H' },
    { pH: '55', pV: '50', pC: 'H' },
    { pH: '57', pV: '70', pC: 'D' },
    { pH: '57', pV: '60', pC: 'C' },
    { pH: '59', pV: '80', pC: 'D' },
    { pH: '59', pV: '70', pC: 'L' },
    { pH: '59', pV: '60', pC: 'H' },
    { pH: '59', pV: '40', pC: 'P' },
    { pH: '59', pV: '30', pC: 'H' },
    { pH: '62', pV: '60', pC: 'H' },
    { pH: '62', pV: '60', pC: 'L' },
    { pH: '62', pV: '50', pC: 'H' },
    { pH: '65', pV: '90', pC: 'A' },
    { pH: '65', pV: '85', pC: 'A' },
    { pH: '65', pV: '80', pC: 'A' },
    { pH: '65', pV: '70', pC: 'A' },
    { pH: '65', pV: '50', pC: 'B' },
    { pH: '65', pV: '40', pC: 'B' },
    { pH: '65', pV: '30', pC: 'B' },
    { pH: '65', pV: '20', pC: 'B' },
    { pH: '65', pV: '90', pC: 'B' },
    { pH: '65', pV: '85', pC: 'B' },
    { pH: '65', pV: '80', pC: 'B' },
    { pH: '65', pV: '70', pC: 'B' },
    { pH: '65', pV: '60', pC: 'B' },
    { pH: '65', pV: '60', pC: 'D' },
    { pH: '65', pV: '50', pC: 'D' },
    { pH: '65', pV: '40', pC: 'D' },
    { pH: '65', pV: '90', pC: 'D' },
    { pH: '65', pV: '80', pC: 'D' },
    { pH: '65', pV: '70', pC: 'D' },
    { pH: '65', pV: '70', pC: 'H' },
    { pH: '65', pV: '60', pC: 'P' },
    { pH: '65', pV: '40', pC: 'H' },
    { pH: '65', pV: '30', pC: 'D' },
    { pH: '65', pV: '20', pC: 'L' },
    { pH: '65', pV: '80', pC: 'H' },
    { pH: '65', pV: '70', pC: 'L' },
    { pH: '69', pV: '70', pC: 'P' },
    { pH: '69', pV: '60', pC: 'T' },
    { pH: '69', pV: '60', pC: 'L' },
    { pH: '69', pV: '60', pC: 'H' },
    { pH: '69', pV: '50', pC: 'D' },
    { pH: '69', pV: '40', pC: 'D' },
    { pH: '69', pV: '80', pC: 'H' },
    { pH: '69', pV: '70', pC: 'L' },
    { pH: '72', pV: '70', pC: 'D' },
    { pH: '72', pV: '60', pC: 'D' },
    { pH: '72', pV: '60', pC: 'H' },
    { pH: '72', pV: '50', pC: 'L' },
    { pH: '72', pV: '50', pC: 'P' },
    { pH: '72', pV: '30', pC: 'H' },
    { pH: '72', pV: '80', pC: 'D' },
    { pH: '72', pV: '80', pC: 'H' },
    { pH: '75', pV: '70', pC: 'L' },
    { pH: '75', pV: '60', pC: 'P' },
    { pH: '75', pV: '40', pC: 'L' },
    { pH: '75', pV: '20', pC: 'L' },
    { pH: '75', pV: '20', pC: 'D' },
    { pH: '75', pV: '20', pC: 'B' },
    { pH: '75', pV: '90', pC: 'A' },
    { pH: '75', pV: '85', pC: 'A' },
    { pH: '75', pV: '80', pC: 'A' },
    { pH: '75', pV: '75', pC: 'A' },
    { pH: '75', pV: '70', pC: 'A' },
    { pH: '75', pV: '50', pC: 'B' },
    { pH: '75', pV: '40', pC: 'B' },
    { pH: '75', pV: '30', pC: 'B' },
    { pH: '75', pV: '90', pC: 'B' },
    { pH: '75', pV: '85', pC: 'B' },
    { pH: '75', pV: '80', pC: 'B' },
    { pH: '75', pV: '70', pC: 'B' },
    { pH: '75', pV: '60', pC: 'B' },
    { pH: '75', pV: '50', pC: 'D' },
    { pH: '75', pV: '40', pC: 'D' },
    { pH: '75', pV: '30', pC: 'D' },
    { pH: '75', pV: '90', pC: 'D' },
    { pH: '75', pV: '80', pC: 'D' },
    { pH: '75', pV: '70', pC: 'D' },
    { pH: '75', pV: '60', pC: 'D' },
    { pH: '75', pV: '50', pC: 'H' },
    { pH: '76', pV: '70', pC: 'L' },
    { pH: '76', pV: '50', pC: 'L' },
    { pH: '76', pV: '40', pC: 'P' },
    { pH: '76', pV: '80', pC: 'D' },
    { pH: '76', pV: '80', pC: 'H' },
    { pH: '77', pV: '70', pC: 'D' },
    { pH: '77', pV: '70', pC: 'H' },
    { pH: '77', pV: '60', pC: 'P' },
    { pH: '77', pV: '50', pC: 'L' },
    { pH: '77', pV: '40', pC: 'L' },
    { pH: '77', pV: '20', pC: 'L' },
    { pH: '79', pV: '70', pC: 'L' },
    { pH: '82', pV: '70', pC: 'H' },
    { pH: '82', pV: '50', pC: 'H' },
    { pH: '82', pV: '50', pC: 'L' },
    { pH: '85', pV: '60', pC: 'H' },
    { pH: '85', pV: '40', pC: 'D' },
    { pH: '85', pV: '20', pC: 'D' },
    { pH: '85', pV: '20', pC: 'B' },
    { pH: '85', pV: '90', pC: 'A' },
    { pH: '85', pV: '85', pC: 'A' },
    { pH: '85', pV: '80', pC: 'A' },
    { pH: '85', pV: '70', pC: 'A' },
    { pH: '85', pV: '60', pC: 'B' },
    { pH: '85', pV: '50', pC: 'B' },
    { pH: '85', pV: '40', pC: 'B' },
    { pH: '85', pV: '30', pC: 'B' },
    { pH: '85', pV: '90', pC: 'B' },
    { pH: '85', pV: '85', pC: 'B' },
    { pH: '85', pV: '80', pC: 'B' },
    { pH: '85', pV: '70', pC: 'B' },
    { pH: '85', pV: '70', pC: 'D' },
    { pH: '85', pV: '60', pC: 'D' },
    { pH: '85', pV: '50', pC: 'D' },
    { pH: '85', pV: '30', pC: 'D' },
    { pH: '85', pV: '90', pC: 'D' },
    { pH: '85', pV: '80', pC: 'D' },
    { pH: '85', pV: '80', pC: 'H' },
    { pH: '85', pV: '70', pC: 'H' },
    { pH: '85', pV: '70', pC: 'L' },
    { pH: '85', pV: '50', pC: 'P' },
    { pH: '85', pV: '40', pC: 'L' },
    { pH: '87', pV: '30', pC: 'H' },
    { pH: '87', pV: '60', pC: 'H' },
    { pH: '87', pV: '40', pC: 'H' },
    { pH: '89', pV: '30', pC: 'H' },
    { pH: '89', pV: '70', pC: 'H' },
    { pH: '89', pV: '60', pC: 'T' },
    { pH: '92', pV: '40', pC: 'L' },
    { pH: '92', pV: '80', pC: 'H' },
    { pH: '95', pV: '70', pC: 'L' },
    { pH: '95', pV: '90', pC: 'A' },
    { pH: '95', pV: '85', pC: 'A' },
    { pH: '95', pV: '80', pC: 'A' },
    { pH: '95', pV: '70', pC: 'A' },
    { pH: '95', pV: '50', pC: 'B' },
    { pH: '95', pV: '40', pC: 'B' },
    { pH: '95', pV: '30', pC: 'B' },
    { pH: '95', pV: '20', pC: 'B' },
    { pH: '95', pV: '90', pC: 'B' },
    { pH: '95', pV: '85', pC: 'B' },
    { pH: '95', pV: '80', pC: 'B' },
    { pH: '95', pV: '70', pC: 'B' },
    { pH: '95', pV: '60', pC: 'B' },
    { pH: '95', pV: '50', pC: 'D' },
    { pH: '95', pV: '40', pC: 'D' },
    { pH: '95', pV: '20', pC: 'D' },
    { pH: '95', pV: '90', pC: 'D' },
    { pH: '95', pV: '80', pC: 'D' },
    { pH: '95', pV: '70', pC: 'D' },
    { pH: '95', pV: '70', pC: 'H' },
    { pH: '95', pV: '60', pC: 'D' },
    { pH: '95', pV: '50', pC: 'H' },
    { pH: '95', pV: '30', pC: 'H' },
    { pH: '95', pV: '30', pC: 'D' },
    { pH: '95', pV: '60', pC: 'P' },
    { pH: '97', pV: '70', pC: 'P' },
    { pH: '99', pV: '70', pC: 'L' },
    { pH: '99', pV: '70', pC: 'H' },
    { pH: '99', pV: '70', pC: 'D' },
    { pH: '99', pV: '60', pC: 'H' },
    { pH: '99', pV: '40', pC: 'H' },
    { pH: '99', pV: '30', pC: 'P' },
    { pH: '05', pV: '50', pC: 'V' },
    { pH: '05', pV: '40', pC: 'V' },
    { pH: '05', pV: '40', pC: 'X' },
    { pH: '05', pV: '30', pC: 'T' },
    { pH: '07', pV: '40', pC: 'X' },
    { pH: '08', pV: '50', pC: 'V' },
    { pH: '08', pV: '45', pC: 'V' },
    { pH: '09', pV: '50', pC: 'X' },
    { pH: '09', pV: '60', pC: 'V' },
    { pH: '12', pV: '60', pC: 'X' },
    { pH: '12', pV: '50', pC: 'V' },
    { pH: '15', pV: '60', pC: 'V' },
    { pH: '15', pV: '65', pC: 'X' },
    { pH: '15', pV: '70', pC: 'V' },
    { pH: '17', pV: '70', pC: 'X' },
    { pH: '19', pV: '70', pC: 'V' },
    { pH: '19', pV: '75', pC: 'X' },
    { pH: '22', pV: '80', pC: 'X' },
    { pH: '22', pV: '80', pC: 'V' },
    { pH: '22', pV: '70', pC: 'V' },
    { pH: '25', pV: '70', pC: 'T' },
    { pH: '25', pV: '80', pC: 'W' },
    { pH: '27', pV: '85', pC: 'V' },
    { pH: '29', pV: '80', pC: 'V' },
    { pH: '29', pV: '70', pC: 'T' },
    { pH: '32', pV: '70', pC: 'T' },
    { pH: '35', pV: '80', pC: 'T' },
    { pH: '35', pV: '70', pC: 'V' },
    { pH: '37', pV: '60', pC: 'T' },
    { pH: '39', pV: '60', pC: 'V' },
    { pH: '42', pV: '60', pC: 'T' },
    { pH: '42', pV: '50', pC: 'T' },
    { pH: '45', pV: '40', pC: 'P' },
    { pH: '45', pV: '50', pC: 'T' },
    { pH: '47', pV: '60', pC: 'T' },
    { pH: '49', pV: '40', pC: 'T' },
    { pH: '52', pV: '60', pC: 'T' },
    { pH: '52', pV: '50', pC: 'P' },
    { pH: '55', pV: '50', pC: 'P' },
    { pH: '55', pV: '40', pC: 'P' },
    { pH: '55', pV: '70', pC: 'P' },
    { pH: '59', pV: '60', pC: 'P' },
    { pH: '65', pV: '40', pC: 'P' },
    { pH: '69', pV: '50', pC: 'T' },
    { pH: '69', pV: '40', pC: 'T' },
    { pH: '69', pV: '30', pC: 'P' },
    { pH: '72', pV: '40', pC: 'T' },
    { pH: '75', pV: '30', pC: 'P' },
    { pH: '75', pV: '40', pC: 'T' },
    { pH: '76', pV: '50', pC: 'T' },
    { pH: '77', pV: '50', pC: 'T' },
    { pH: '77', pV: '40', pC: 'V' },
    { pH: '77', pV: '30', pC: 'T' },
    { pH: '79', pV: '40', pC: 'T' },
    { pH: '82', pV: '40', pC: 'T' },
    { pH: '85', pV: '30', pC: 'P' },
    { pH: '85', pV: '40', pC: 'T' },
    { pH: '89', pV: '40', pC: 'T' },
    { pH: '92', pV: '40', pC: 'V' },
    { pH: '95', pV: '40', pC: 'V' },
    { pH: '95', pV: '50', pC: 'V' },
    { pH: '97', pV: '50', pC: 'V' },
    { pH: '99', pV: '50', pC: 'X' },
    { pH: '99', pV: '40', pC: 'V' },
  ];

  mCLists: List<string>[] = [
    { numH: '10', baseH: 'RP', mV: '1', mC: '2' },
    { numH: '10', baseH: 'RP', mV: '1', mC: '4' },
    { numH: '10', baseH: 'RP', mV: '1', mC: '6' },
    { numH: '10', baseH: 'RP', mV: '1', mC: '8' },
    { numH: '10', baseH: 'RP', mV: '1', mC: '10' },
    { numH: '10', baseH: 'RP', mV: '1', mC: '12' },
    { numH: '2.5', baseH: 'R', mV: '1', mC: '2' },
    { numH: '2.5', baseH: 'R', mV: '1', mC: '4' },
    { numH: '2.5', baseH: 'R', mV: '1', mC: '6' },
    { numH: '2.5', baseH: 'R', mV: '1', mC: '8' },
    { numH: '2.5', baseH: 'R', mV: '1', mC: '10' },
    { numH: '5', baseH: 'R', mV: '1', mC: '2' },
    { numH: '5', baseH: 'R', mV: '1', mC: '4' },
    { numH: '5', baseH: 'R', mV: '1', mC: '6' },
    { numH: '5', baseH: 'R', mV: '1', mC: '8' },
    { numH: '5', baseH: 'R', mV: '1', mC: '10' },
    { numH: '7.5', baseH: 'R', mV: '1', mC: '2' },
    { numH: '7.5', baseH: 'R', mV: '1', mC: '4' },
    { numH: '7.5', baseH: 'R', mV: '1', mC: '6' },
    { numH: '7.5', baseH: 'R', mV: '1', mC: '8' },
    { numH: '7.5', baseH: 'R', mV: '1', mC: '10' },
    { numH: '10', baseH: 'R', mV: '1', mC: '2' },
    { numH: '10', baseH: 'R', mV: '1', mC: '4' },
    { numH: '10', baseH: 'R', mV: '1', mC: '6' },
    { numH: '10', baseH: 'R', mV: '1', mC: '8' },
    { numH: '10', baseH: 'R', mV: '1', mC: '10' },
    { numH: '2.5', baseH: 'YR', mV: '1', mC: '2' },
    { numH: '2.5', baseH: 'YR', mV: '1', mC: '4' },
    { numH: '2.5', baseH: 'YR', mV: '1', mC: '6' },
    { numH: '2.5', baseH: 'YR', mV: '1', mC: '8' },
    { numH: '5', baseH: 'YR', mV: '1', mC: '2' },
    { numH: '5', baseH: 'YR', mV: '1', mC: '4' },
    { numH: '7.5', baseH: 'YR', mV: '1', mC: '2' },
    { numH: '10', baseH: 'YR', mV: '1', mC: '2' },
    { numH: '2.5', baseH: 'Y', mV: '1', mC: '2' },
    { numH: '5', baseH: 'Y', mV: '1', mC: '2' },
    { numH: '7.5', baseH: 'Y', mV: '1', mC: '2' },
    { numH: '10', baseH: 'Y', mV: '1', mC: '2' },
    { numH: '2.5', baseH: 'GY', mV: '1', mC: '2' },
    { numH: '5', baseH: 'GY', mV: '1', mC: '2' },
    { numH: '5', baseH: 'GY', mV: '1', mC: '4' },
    { numH: '7.5', baseH: 'GY', mV: '1', mC: '2' },
    { numH: '7.5', baseH: 'GY', mV: '1', mC: '4' },
    { numH: '10', baseH: 'GY', mV: '1', mC: '2' },
    { numH: '10', baseH: 'GY', mV: '1', mC: '4' },
    { numH: '10', baseH: 'GY', mV: '1', mC: '6' },
    { numH: '2.5', baseH: 'G', mV: '1', mC: '2' },
    { numH: '2.5', baseH: 'G', mV: '1', mC: '4' },
    { numH: '2.5', baseH: 'G', mV: '1', mC: '6' },
    { numH: '2.5', baseH: 'G', mV: '1', mC: '8' },
    { numH: '5', baseH: 'G', mV: '1', mC: '2' },
    { numH: '5', baseH: 'G', mV: '1', mC: '4' },
    { numH: '5', baseH: 'G', mV: '1', mC: '6' },
    { numH: '5', baseH: 'G', mV: '1', mC: '8' },
    { numH: '7.5', baseH: 'G', mV: '1', mC: '2' },
    { numH: '7.5', baseH: 'G', mV: '1', mC: '4' },
    { numH: '7.5', baseH: 'G', mV: '1', mC: '6' },
    { numH: '7.5', baseH: 'G', mV: '1', mC: '8' },
    { numH: '10', baseH: 'G', mV: '1', mC: '2' },
    { numH: '10', baseH: 'G', mV: '1', mC: '4' },
    { numH: '10', baseH: 'G', mV: '1', mC: '6' },
    { numH: '10', baseH: 'G', mV: '1', mC: '8' },
    { numH: '2.5', baseH: 'BG', mV: '1', mC: '2' },
    { numH: '2.5', baseH: 'BG', mV: '1', mC: '4' },
    { numH: '2.5', baseH: 'BG', mV: '1', mC: '6' },
    { numH: '2.5', baseH: 'BG', mV: '1', mC: '8' },
    { numH: '5', baseH: 'BG', mV: '1', mC: '2' },
    { numH: '5', baseH: 'BG', mV: '1', mC: '4' },
    { numH: '5', baseH: 'BG', mV: '1', mC: '6' },
    { numH: '7.5', baseH: 'BG', mV: '1', mC: '2' },
    { numH: '7.5', baseH: 'BG', mV: '1', mC: '4' },
    { numH: '7.5', baseH: 'BG', mV: '1', mC: '6' },
    { numH: '10', baseH: 'BG', mV: '1', mC: '2' },
    { numH: '10', baseH: 'BG', mV: '1', mC: '4' },
    { numH: '10', baseH: 'BG', mV: '1', mC: '6' },
    { numH: '2.5', baseH: 'B', mV: '1', mC: '2' },
    { numH: '2.5', baseH: 'B', mV: '1', mC: '4' },
    { numH: '2.5', baseH: 'B', mV: '1', mC: '6' },
    { numH: '5', baseH: 'B', mV: '1', mC: '2' },
    { numH: '5', baseH: 'B', mV: '1', mC: '4' },
    { numH: '5', baseH: 'B', mV: '1', mC: '6' },
    { numH: '7.5', baseH: 'B', mV: '1', mC: '2' },
    { numH: '7.5', baseH: 'B', mV: '1', mC: '4' },
    { numH: '7.5', baseH: 'B', mV: '1', mC: '6' },
    { numH: '7.5', baseH: 'B', mV: '1', mC: '8' },
    { numH: '10', baseH: 'B', mV: '1', mC: '2' },
    { numH: '10', baseH: 'B', mV: '1', mC: '4' },
    { numH: '10', baseH: 'B', mV: '1', mC: '6' },
    { numH: '10', baseH: 'B', mV: '1', mC: '8' },
    { numH: '2.5', baseH: 'PB', mV: '1', mC: '2' },
    { numH: '2.5', baseH: 'PB', mV: '1', mC: '4' },
    { numH: '2.5', baseH: 'PB', mV: '1', mC: '6' },
    { numH: '2.5', baseH: 'PB', mV: '1', mC: '8' },
    { numH: '5', baseH: 'PB', mV: '1', mC: '2' },
    { numH: '5', baseH: 'PB', mV: '1', mC: '4' },
    { numH: '5', baseH: 'PB', mV: '1', mC: '6' },
    { numH: '5', baseH: 'PB', mV: '1', mC: '8' },
    { numH: '5', baseH: 'PB', mV: '1', mC: '10' },
    { numH: '7.5', baseH: 'PB', mV: '1', mC: '2' },
    { numH: '7.5', baseH: 'PB', mV: '1', mC: '4' },
    { numH: '7.5', baseH: 'PB', mV: '1', mC: '6' },
    { numH: '7.5', baseH: 'PB', mV: '1', mC: '8' },
    { numH: '7.5', baseH: 'PB', mV: '1', mC: '10' },
    { numH: '7.5', baseH: 'PB', mV: '1', mC: '12' },
    { numH: '7.5', baseH: 'PB', mV: '1', mC: '14' },
    { numH: '7.5', baseH: 'PB', mV: '1', mC: '16' },
    { numH: '7.5', baseH: 'PB', mV: '1', mC: '18' },
    { numH: '7.5', baseH: 'PB', mV: '1', mC: '20' },
    { numH: '7.5', baseH: 'PB', mV: '1', mC: '22' },
    { numH: '7.5', baseH: 'PB', mV: '1', mC: '24' },
    { numH: '7.5', baseH: 'PB', mV: '1', mC: '26' },
    { numH: '7.5', baseH: 'PB', mV: '1', mC: '28' },
    { numH: '7.5', baseH: 'PB', mV: '1', mC: '30' },
    { numH: '7.5', baseH: 'PB', mV: '1', mC: '32' },
    { numH: '7.5', baseH: 'PB', mV: '1', mC: '34' },
    { numH: '7.5', baseH: 'PB', mV: '1', mC: '36' },
    { numH: '7.5', baseH: 'PB', mV: '1', mC: '38' },
    { numH: '10', baseH: 'PB', mV: '1', mC: '2' },
    { numH: '10', baseH: 'PB', mV: '1', mC: '4' },
    { numH: '10', baseH: 'PB', mV: '1', mC: '6' },
    { numH: '10', baseH: 'PB', mV: '1', mC: '8' },
    { numH: '10', baseH: 'PB', mV: '1', mC: '10' },
    { numH: '10', baseH: 'PB', mV: '1', mC: '12' },
    { numH: '10', baseH: 'PB', mV: '1', mC: '14' },
    { numH: '10', baseH: 'PB', mV: '1', mC: '16' },
    { numH: '10', baseH: 'PB', mV: '1', mC: '18' },
    { numH: '10', baseH: 'PB', mV: '1', mC: '20' },
    { numH: '10', baseH: 'PB', mV: '1', mC: '22' },
    { numH: '10', baseH: 'PB', mV: '1', mC: '24' },
    { numH: '10', baseH: 'PB', mV: '1', mC: '26' },
    { numH: '10', baseH: 'PB', mV: '1', mC: '28' },
    { numH: '10', baseH: 'PB', mV: '1', mC: '30' },
    { numH: '2.5', baseH: 'P', mV: '1', mC: '2' },
    { numH: '2.5', baseH: 'P', mV: '1', mC: '4' },
    { numH: '2.5', baseH: 'P', mV: '1', mC: '6' },
    { numH: '2.5', baseH: 'P', mV: '1', mC: '8' },
    { numH: '2.5', baseH: 'P', mV: '1', mC: '10' },
    { numH: '2.5', baseH: 'P', mV: '1', mC: '12' },
    { numH: '2.5', baseH: 'P', mV: '1', mC: '14' },
    { numH: '2.5', baseH: 'P', mV: '1', mC: '16' },
    { numH: '2.5', baseH: 'P', mV: '1', mC: '18' },
    { numH: '2.5', baseH: 'P', mV: '1', mC: '20' },
    { numH: '2.5', baseH: 'P', mV: '1', mC: '22' },
    { numH: '2.5', baseH: 'P', mV: '1', mC: '24' },
    { numH: '2.5', baseH: 'P', mV: '1', mC: '26' },
    { numH: '5', baseH: 'P', mV: '1', mC: '2' },
    { numH: '5', baseH: 'P', mV: '1', mC: '4' },
    { numH: '5', baseH: 'P', mV: '1', mC: '6' },
    { numH: '5', baseH: 'P', mV: '1', mC: '8' },
    { numH: '5', baseH: 'P', mV: '1', mC: '10' },
    { numH: '5', baseH: 'P', mV: '1', mC: '12' },
    { numH: '5', baseH: 'P', mV: '1', mC: '14' },
    { numH: '5', baseH: 'P', mV: '1', mC: '16' },
    { numH: '5', baseH: 'P', mV: '1', mC: '18' },
    { numH: '5', baseH: 'P', mV: '1', mC: '20' },
    { numH: '5', baseH: 'P', mV: '1', mC: '22' },
    { numH: '7.5', baseH: 'P', mV: '1', mC: '2' },
    { numH: '7.5', baseH: 'P', mV: '1', mC: '4' },
    { numH: '7.5', baseH: 'P', mV: '1', mC: '6' },
    { numH: '7.5', baseH: 'P', mV: '1', mC: '8' },
    { numH: '7.5', baseH: 'P', mV: '1', mC: '10' },
    { numH: '7.5', baseH: 'P', mV: '1', mC: '12' },
    { numH: '7.5', baseH: 'P', mV: '1', mC: '14' },
    { numH: '7.5', baseH: 'P', mV: '1', mC: '16' },
    { numH: '7.5', baseH: 'P', mV: '1', mC: '18' },
    { numH: '7.5', baseH: 'P', mV: '1', mC: '20' },
    { numH: '10', baseH: 'P', mV: '1', mC: '2' },
    { numH: '10', baseH: 'P', mV: '1', mC: '4' },
    { numH: '10', baseH: 'P', mV: '1', mC: '6' },
    { numH: '10', baseH: 'P', mV: '1', mC: '8' },
    { numH: '10', baseH: 'P', mV: '1', mC: '10' },
    { numH: '10', baseH: 'P', mV: '1', mC: '12' },
    { numH: '10', baseH: 'P', mV: '1', mC: '14' },
    { numH: '10', baseH: 'P', mV: '1', mC: '16' },
    { numH: '10', baseH: 'P', mV: '1', mC: '18' },
    { numH: '2.5', baseH: 'RP', mV: '1', mC: '2' },
    { numH: '2.5', baseH: 'RP', mV: '1', mC: '4' },
    { numH: '2.5', baseH: 'RP', mV: '1', mC: '6' },
    { numH: '2.5', baseH: 'RP', mV: '1', mC: '8' },
    { numH: '2.5', baseH: 'RP', mV: '1', mC: '10' },
    { numH: '2.5', baseH: 'RP', mV: '1', mC: '12' },
    { numH: '2.5', baseH: 'RP', mV: '1', mC: '14' },
    { numH: '2.5', baseH: 'RP', mV: '1', mC: '16' },
    { numH: '5', baseH: 'RP', mV: '1', mC: '2' },
    { numH: '5', baseH: 'RP', mV: '1', mC: '4' },
    { numH: '5', baseH: 'RP', mV: '1', mC: '6' },
    { numH: '5', baseH: 'RP', mV: '1', mC: '8' },
    { numH: '5', baseH: 'RP', mV: '1', mC: '10' },
    { numH: '5', baseH: 'RP', mV: '1', mC: '12' },
    { numH: '5', baseH: 'RP', mV: '1', mC: '14' },
    { numH: '7.5', baseH: 'RP', mV: '1', mC: '2' },
    { numH: '7.5', baseH: 'RP', mV: '1', mC: '4' },
    { numH: '7.5', baseH: 'RP', mV: '1', mC: '6' },
    { numH: '7.5', baseH: 'RP', mV: '1', mC: '8' },
    { numH: '7.5', baseH: 'RP', mV: '1', mC: '10' },
    { numH: '7.5', baseH: 'RP', mV: '1', mC: '12' },
    { numH: '10', baseH: 'RP', mV: '2', mC: '2' },
    { numH: '10', baseH: 'RP', mV: '2', mC: '4' },
    { numH: '10', baseH: 'RP', mV: '2', mC: '6' },
    { numH: '10', baseH: 'RP', mV: '2', mC: '8' },
    { numH: '10', baseH: 'RP', mV: '2', mC: '10' },
    { numH: '10', baseH: 'RP', mV: '2', mC: '12' },
    { numH: '10', baseH: 'RP', mV: '2', mC: '14' },
    { numH: '2.5', baseH: 'R', mV: '2', mC: '2' },
    { numH: '2.5', baseH: 'R', mV: '2', mC: '4' },
    { numH: '2.5', baseH: 'R', mV: '2', mC: '6' },
    { numH: '2.5', baseH: 'R', mV: '2', mC: '8' },
    { numH: '2.5', baseH: 'R', mV: '2', mC: '10' },
    { numH: '2.5', baseH: 'R', mV: '2', mC: '12' },
    { numH: '2.5', baseH: 'R', mV: '2', mC: '14' },
    { numH: '5', baseH: 'R', mV: '2', mC: '2' },
    { numH: '5', baseH: 'R', mV: '2', mC: '4' },
    { numH: '5', baseH: 'R', mV: '2', mC: '6' },
    { numH: '5', baseH: 'R', mV: '2', mC: '8' },
    { numH: '5', baseH: 'R', mV: '2', mC: '10' },
    { numH: '5', baseH: 'R', mV: '2', mC: '12' },
    { numH: '5', baseH: 'R', mV: '2', mC: '14' },
    { numH: '7.5', baseH: 'R', mV: '2', mC: '2' },
    { numH: '7.5', baseH: 'R', mV: '2', mC: '4' },
    { numH: '7.5', baseH: 'R', mV: '2', mC: '6' },
    { numH: '7.5', baseH: 'R', mV: '2', mC: '8' },
    { numH: '7.5', baseH: 'R', mV: '2', mC: '10' },
    { numH: '7.5', baseH: 'R', mV: '2', mC: '12' },
    { numH: '7.5', baseH: 'R', mV: '2', mC: '14' },
    { numH: '10', baseH: 'R', mV: '2', mC: '2' },
    { numH: '10', baseH: 'R', mV: '2', mC: '4' },
    { numH: '10', baseH: 'R', mV: '2', mC: '6' },
    { numH: '10', baseH: 'R', mV: '2', mC: '8' },
    { numH: '10', baseH: 'R', mV: '2', mC: '10' },
    { numH: '10', baseH: 'R', mV: '2', mC: '12' },
    { numH: '10', baseH: 'R', mV: '2', mC: '14' },
    { numH: '2.5', baseH: 'YR', mV: '2', mC: '2' },
    { numH: '2.5', baseH: 'YR', mV: '2', mC: '4' },
    { numH: '2.5', baseH: 'YR', mV: '2', mC: '6' },
    { numH: '2.5', baseH: 'YR', mV: '2', mC: '8' },
    { numH: '5', baseH: 'YR', mV: '2', mC: '2' },
    { numH: '5', baseH: 'YR', mV: '2', mC: '4' },
    { numH: '5', baseH: 'YR', mV: '2', mC: '6' },
    { numH: '7.5', baseH: 'YR', mV: '2', mC: '2' },
    { numH: '7.5', baseH: 'YR', mV: '2', mC: '4' },
    { numH: '7.5', baseH: 'YR', mV: '2', mC: '6' },
    { numH: '10', baseH: 'YR', mV: '2', mC: '2' },
    { numH: '10', baseH: 'YR', mV: '2', mC: '4' },
    { numH: '2.5', baseH: 'Y', mV: '2', mC: '2' },
    { numH: '2.5', baseH: 'Y', mV: '2', mC: '4' },
    { numH: '5', baseH: 'Y', mV: '2', mC: '2' },
    { numH: '5', baseH: 'Y', mV: '2', mC: '4' },
    { numH: '7.5', baseH: 'Y', mV: '2', mC: '2' },
    { numH: '7.5', baseH: 'Y', mV: '2', mC: '4' },
    { numH: '10', baseH: 'Y', mV: '2', mC: '2' },
    { numH: '10', baseH: 'Y', mV: '2', mC: '4' },
    { numH: '2.5', baseH: 'GY', mV: '2', mC: '2' },
    { numH: '2.5', baseH: 'GY', mV: '2', mC: '4' },
    { numH: '5', baseH: 'GY', mV: '2', mC: '2' },
    { numH: '5', baseH: 'GY', mV: '2', mC: '4' },
    { numH: '5', baseH: 'GY', mV: '2', mC: '6' },
    { numH: '7.5', baseH: 'GY', mV: '2', mC: '2' },
    { numH: '7.5', baseH: 'GY', mV: '2', mC: '4' },
    { numH: '7.5', baseH: 'GY', mV: '2', mC: '6' },
    { numH: '7.5', baseH: 'GY', mV: '2', mC: '8' },
    { numH: '10', baseH: 'GY', mV: '2', mC: '2' },
    { numH: '10', baseH: 'GY', mV: '2', mC: '4' },
    { numH: '10', baseH: 'GY', mV: '2', mC: '6' },
    { numH: '10', baseH: 'GY', mV: '2', mC: '8' },
    { numH: '10', baseH: 'GY', mV: '2', mC: '10' },
    { numH: '10', baseH: 'GY', mV: '2', mC: '12' },
    { numH: '2.5', baseH: 'G', mV: '2', mC: '2' },
    { numH: '2.5', baseH: 'G', mV: '2', mC: '4' },
    { numH: '2.5', baseH: 'G', mV: '2', mC: '6' },
    { numH: '2.5', baseH: 'G', mV: '2', mC: '8' },
    { numH: '2.5', baseH: 'G', mV: '2', mC: '10' },
    { numH: '2.5', baseH: 'G', mV: '2', mC: '12' },
    { numH: '2.5', baseH: 'G', mV: '2', mC: '14' },
    { numH: '2.5', baseH: 'G', mV: '2', mC: '16' },
    { numH: '5', baseH: 'G', mV: '2', mC: '2' },
    { numH: '5', baseH: 'G', mV: '2', mC: '4' },
    { numH: '5', baseH: 'G', mV: '2', mC: '6' },
    { numH: '5', baseH: 'G', mV: '2', mC: '8' },
    { numH: '5', baseH: 'G', mV: '2', mC: '10' },
    { numH: '5', baseH: 'G', mV: '2', mC: '12' },
    { numH: '5', baseH: 'G', mV: '2', mC: '14' },
    { numH: '5', baseH: 'G', mV: '2', mC: '16' },
    { numH: '7.5', baseH: 'G', mV: '2', mC: '2' },
    { numH: '7.5', baseH: 'G', mV: '2', mC: '4' },
    { numH: '7.5', baseH: 'G', mV: '2', mC: '6' },
    { numH: '7.5', baseH: 'G', mV: '2', mC: '8' },
    { numH: '7.5', baseH: 'G', mV: '2', mC: '10' },
    { numH: '7.5', baseH: 'G', mV: '2', mC: '12' },
    { numH: '7.5', baseH: 'G', mV: '2', mC: '14' },
    { numH: '7.5', baseH: 'G', mV: '2', mC: '16' },
    { numH: '10', baseH: 'G', mV: '2', mC: '2' },
    { numH: '10', baseH: 'G', mV: '2', mC: '4' },
    { numH: '10', baseH: 'G', mV: '2', mC: '6' },
    { numH: '10', baseH: 'G', mV: '2', mC: '8' },
    { numH: '10', baseH: 'G', mV: '2', mC: '10' },
    { numH: '10', baseH: 'G', mV: '2', mC: '12' },
    { numH: '10', baseH: 'G', mV: '2', mC: '14' },
    { numH: '10', baseH: 'G', mV: '2', mC: '16' },
    { numH: '2.5', baseH: 'BG', mV: '2', mC: '2' },
    { numH: '2.5', baseH: 'BG', mV: '2', mC: '4' },
    { numH: '2.5', baseH: 'BG', mV: '2', mC: '6' },
    { numH: '2.5', baseH: 'BG', mV: '2', mC: '8' },
    { numH: '2.5', baseH: 'BG', mV: '2', mC: '10' },
    { numH: '2.5', baseH: 'BG', mV: '2', mC: '12' },
    { numH: '2.5', baseH: 'BG', mV: '2', mC: '14' },
    { numH: '5', baseH: 'BG', mV: '2', mC: '2' },
    { numH: '5', baseH: 'BG', mV: '2', mC: '4' },
    { numH: '5', baseH: 'BG', mV: '2', mC: '6' },
    { numH: '5', baseH: 'BG', mV: '2', mC: '8' },
    { numH: '5', baseH: 'BG', mV: '2', mC: '10' },
    { numH: '5', baseH: 'BG', mV: '2', mC: '12' },
    { numH: '7.5', baseH: 'BG', mV: '2', mC: '2' },
    { numH: '7.5', baseH: 'BG', mV: '2', mC: '4' },
    { numH: '7.5', baseH: 'BG', mV: '2', mC: '6' },
    { numH: '7.5', baseH: 'BG', mV: '2', mC: '8' },
    { numH: '7.5', baseH: 'BG', mV: '2', mC: '10' },
    { numH: '7.5', baseH: 'BG', mV: '2', mC: '12' },
    { numH: '10', baseH: 'BG', mV: '2', mC: '2' },
    { numH: '10', baseH: 'BG', mV: '2', mC: '4' },
    { numH: '10', baseH: 'BG', mV: '2', mC: '6' },
    { numH: '10', baseH: 'BG', mV: '2', mC: '8' },
    { numH: '10', baseH: 'BG', mV: '2', mC: '10' },
    { numH: '2.5', baseH: 'B', mV: '2', mC: '2' },
    { numH: '2.5', baseH: 'B', mV: '2', mC: '4' },
    { numH: '2.5', baseH: 'B', mV: '2', mC: '6' },
    { numH: '2.5', baseH: 'B', mV: '2', mC: '8' },
    { numH: '2.5', baseH: 'B', mV: '2', mC: '10' },
    { numH: '5', baseH: 'B', mV: '2', mC: '2' },
    { numH: '5', baseH: 'B', mV: '2', mC: '4' },
    { numH: '5', baseH: 'B', mV: '2', mC: '6' },
    { numH: '5', baseH: 'B', mV: '2', mC: '8' },
    { numH: '5', baseH: 'B', mV: '2', mC: '10' },
    { numH: '7.5', baseH: 'B', mV: '2', mC: '2' },
    { numH: '7.5', baseH: 'B', mV: '2', mC: '4' },
    { numH: '7.5', baseH: 'B', mV: '2', mC: '6' },
    { numH: '7.5', baseH: 'B', mV: '2', mC: '8' },
    { numH: '7.5', baseH: 'B', mV: '2', mC: '10' },
    { numH: '10', baseH: 'B', mV: '2', mC: '2' },
    { numH: '10', baseH: 'B', mV: '2', mC: '4' },
    { numH: '10', baseH: 'B', mV: '2', mC: '6' },
    { numH: '10', baseH: 'B', mV: '2', mC: '8' },
    { numH: '10', baseH: 'B', mV: '2', mC: '10' },
    { numH: '2.5', baseH: 'PB', mV: '2', mC: '2' },
    { numH: '2.5', baseH: 'PB', mV: '2', mC: '4' },
    { numH: '2.5', baseH: 'PB', mV: '2', mC: '6' },
    { numH: '2.5', baseH: 'PB', mV: '2', mC: '8' },
    { numH: '2.5', baseH: 'PB', mV: '2', mC: '10' },
    { numH: '2.5', baseH: 'PB', mV: '2', mC: '12' },
    { numH: '5', baseH: 'PB', mV: '2', mC: '2' },
    { numH: '5', baseH: 'PB', mV: '2', mC: '4' },
    { numH: '5', baseH: 'PB', mV: '2', mC: '6' },
    { numH: '5', baseH: 'PB', mV: '2', mC: '8' },
    { numH: '5', baseH: 'PB', mV: '2', mC: '10' },
    { numH: '5', baseH: 'PB', mV: '2', mC: '12' },
    { numH: '5', baseH: 'PB', mV: '2', mC: '14' },
    { numH: '7.5', baseH: 'PB', mV: '2', mC: '2' },
    { numH: '7.5', baseH: 'PB', mV: '2', mC: '4' },
    { numH: '7.5', baseH: 'PB', mV: '2', mC: '6' },
    { numH: '7.5', baseH: 'PB', mV: '2', mC: '8' },
    { numH: '7.5', baseH: 'PB', mV: '2', mC: '10' },
    { numH: '7.5', baseH: 'PB', mV: '2', mC: '12' },
    { numH: '7.5', baseH: 'PB', mV: '2', mC: '14' },
    { numH: '7.5', baseH: 'PB', mV: '2', mC: '16' },
    { numH: '7.5', baseH: 'PB', mV: '2', mC: '18' },
    { numH: '7.5', baseH: 'PB', mV: '2', mC: '20' },
    { numH: '7.5', baseH: 'PB', mV: '2', mC: '22' },
    { numH: '7.5', baseH: 'PB', mV: '2', mC: '24' },
    { numH: '7.5', baseH: 'PB', mV: '2', mC: '26' },
    { numH: '7.5', baseH: 'PB', mV: '2', mC: '28' },
    { numH: '7.5', baseH: 'PB', mV: '2', mC: '30' },
    { numH: '7.5', baseH: 'PB', mV: '2', mC: '32' },
    { numH: '7.5', baseH: 'PB', mV: '2', mC: '34' },
    { numH: '7.5', baseH: 'PB', mV: '2', mC: '36' },
    { numH: '7.5', baseH: 'PB', mV: '2', mC: '38' },
    { numH: '10', baseH: 'PB', mV: '2', mC: '2' },
    { numH: '10', baseH: 'PB', mV: '2', mC: '4' },
    { numH: '10', baseH: 'PB', mV: '2', mC: '6' },
    { numH: '10', baseH: 'PB', mV: '2', mC: '8' },
    { numH: '10', baseH: 'PB', mV: '2', mC: '10' },
    { numH: '10', baseH: 'PB', mV: '2', mC: '12' },
    { numH: '10', baseH: 'PB', mV: '2', mC: '14' },
    { numH: '10', baseH: 'PB', mV: '2', mC: '16' },
    { numH: '10', baseH: 'PB', mV: '2', mC: '18' },
    { numH: '10', baseH: 'PB', mV: '2', mC: '20' },
    { numH: '10', baseH: 'PB', mV: '2', mC: '22' },
    { numH: '10', baseH: 'PB', mV: '2', mC: '24' },
    { numH: '10', baseH: 'PB', mV: '2', mC: '26' },
    { numH: '10', baseH: 'PB', mV: '2', mC: '28' },
    { numH: '10', baseH: 'PB', mV: '2', mC: '30' },
    { numH: '10', baseH: 'PB', mV: '2', mC: '32' },
    { numH: '10', baseH: 'PB', mV: '2', mC: '34' },
    { numH: '2.5', baseH: 'P', mV: '2', mC: '2' },
    { numH: '2.5', baseH: 'P', mV: '2', mC: '4' },
    { numH: '2.5', baseH: 'P', mV: '2', mC: '6' },
    { numH: '2.5', baseH: 'P', mV: '2', mC: '8' },
    { numH: '2.5', baseH: 'P', mV: '2', mC: '10' },
    { numH: '2.5', baseH: 'P', mV: '2', mC: '12' },
    { numH: '2.5', baseH: 'P', mV: '2', mC: '14' },
    { numH: '2.5', baseH: 'P', mV: '2', mC: '16' },
    { numH: '2.5', baseH: 'P', mV: '2', mC: '18' },
    { numH: '2.5', baseH: 'P', mV: '2', mC: '20' },
    { numH: '2.5', baseH: 'P', mV: '2', mC: '22' },
    { numH: '2.5', baseH: 'P', mV: '2', mC: '24' },
    { numH: '2.5', baseH: 'P', mV: '2', mC: '26' },
    { numH: '2.5', baseH: 'P', mV: '2', mC: '28' },
    { numH: '2.5', baseH: 'P', mV: '2', mC: '30' },
    { numH: '5', baseH: 'P', mV: '2', mC: '2' },
    { numH: '5', baseH: 'P', mV: '2', mC: '4' },
    { numH: '5', baseH: 'P', mV: '2', mC: '6' },
    { numH: '5', baseH: 'P', mV: '2', mC: '8' },
    { numH: '5', baseH: 'P', mV: '2', mC: '10' },
    { numH: '5', baseH: 'P', mV: '2', mC: '12' },
    { numH: '5', baseH: 'P', mV: '2', mC: '14' },
    { numH: '5', baseH: 'P', mV: '2', mC: '16' },
    { numH: '5', baseH: 'P', mV: '2', mC: '18' },
    { numH: '5', baseH: 'P', mV: '2', mC: '20' },
    { numH: '5', baseH: 'P', mV: '2', mC: '22' },
    { numH: '5', baseH: 'P', mV: '2', mC: '24' },
    { numH: '5', baseH: 'P', mV: '2', mC: '26' },
    { numH: '5', baseH: 'P', mV: '2', mC: '28' },
    { numH: '7.5', baseH: 'P', mV: '2', mC: '2' },
    { numH: '7.5', baseH: 'P', mV: '2', mC: '4' },
    { numH: '7.5', baseH: 'P', mV: '2', mC: '6' },
    { numH: '7.5', baseH: 'P', mV: '2', mC: '8' },
    { numH: '7.5', baseH: 'P', mV: '2', mC: '10' },
    { numH: '7.5', baseH: 'P', mV: '2', mC: '12' },
    { numH: '7.5', baseH: 'P', mV: '2', mC: '14' },
    { numH: '7.5', baseH: 'P', mV: '2', mC: '16' },
    { numH: '7.5', baseH: 'P', mV: '2', mC: '18' },
    { numH: '7.5', baseH: 'P', mV: '2', mC: '20' },
    { numH: '7.5', baseH: 'P', mV: '2', mC: '22' },
    { numH: '7.5', baseH: 'P', mV: '2', mC: '24' },
    { numH: '10', baseH: 'P', mV: '2', mC: '2' },
    { numH: '10', baseH: 'P', mV: '2', mC: '4' },
    { numH: '10', baseH: 'P', mV: '2', mC: '6' },
    { numH: '10', baseH: 'P', mV: '2', mC: '8' },
    { numH: '10', baseH: 'P', mV: '2', mC: '10' },
    { numH: '10', baseH: 'P', mV: '2', mC: '12' },
    { numH: '10', baseH: 'P', mV: '2', mC: '14' },
    { numH: '10', baseH: 'P', mV: '2', mC: '16' },
    { numH: '10', baseH: 'P', mV: '2', mC: '18' },
    { numH: '10', baseH: 'P', mV: '2', mC: '20' },
    { numH: '10', baseH: 'P', mV: '2', mC: '22' },
    { numH: '2.5', baseH: 'RP', mV: '2', mC: '2' },
    { numH: '2.5', baseH: 'RP', mV: '2', mC: '4' },
    { numH: '2.5', baseH: 'RP', mV: '2', mC: '6' },
    { numH: '2.5', baseH: 'RP', mV: '2', mC: '8' },
    { numH: '2.5', baseH: 'RP', mV: '2', mC: '10' },
    { numH: '2.5', baseH: 'RP', mV: '2', mC: '12' },
    { numH: '2.5', baseH: 'RP', mV: '2', mC: '14' },
    { numH: '2.5', baseH: 'RP', mV: '2', mC: '16' },
    { numH: '2.5', baseH: 'RP', mV: '2', mC: '18' },
    { numH: '2.5', baseH: 'RP', mV: '2', mC: '20' },
    { numH: '5', baseH: 'RP', mV: '2', mC: '2' },
    { numH: '5', baseH: 'RP', mV: '2', mC: '4' },
    { numH: '5', baseH: 'RP', mV: '2', mC: '6' },
    { numH: '5', baseH: 'RP', mV: '2', mC: '8' },
    { numH: '5', baseH: 'RP', mV: '2', mC: '10' },
    { numH: '5', baseH: 'RP', mV: '2', mC: '12' },
    { numH: '5', baseH: 'RP', mV: '2', mC: '14' },
    { numH: '5', baseH: 'RP', mV: '2', mC: '16' },
    { numH: '5', baseH: 'RP', mV: '2', mC: '18' },
    { numH: '7.5', baseH: 'RP', mV: '2', mC: '2' },
    { numH: '7.5', baseH: 'RP', mV: '2', mC: '4' },
    { numH: '7.5', baseH: 'RP', mV: '2', mC: '6' },
    { numH: '7.5', baseH: 'RP', mV: '2', mC: '8' },
    { numH: '7.5', baseH: 'RP', mV: '2', mC: '10' },
    { numH: '7.5', baseH: 'RP', mV: '2', mC: '12' },
    { numH: '7.5', baseH: 'RP', mV: '2', mC: '14' },
    { numH: '7.5', baseH: 'RP', mV: '2', mC: '16' },
    { numH: '10', baseH: 'RP', mV: '3', mC: '2' },
    { numH: '10', baseH: 'RP', mV: '3', mC: '4' },
    { numH: '10', baseH: 'RP', mV: '3', mC: '6' },
    { numH: '10', baseH: 'RP', mV: '3', mC: '8' },
    { numH: '10', baseH: 'RP', mV: '3', mC: '10' },
    { numH: '10', baseH: 'RP', mV: '3', mC: '12' },
    { numH: '10', baseH: 'RP', mV: '3', mC: '14' },
    { numH: '10', baseH: 'RP', mV: '3', mC: '16' },
    { numH: '2.5', baseH: 'R', mV: '3', mC: '2' },
    { numH: '2.5', baseH: 'R', mV: '3', mC: '4' },
    { numH: '2.5', baseH: 'R', mV: '3', mC: '6' },
    { numH: '2.5', baseH: 'R', mV: '3', mC: '8' },
    { numH: '2.5', baseH: 'R', mV: '3', mC: '10' },
    { numH: '2.5', baseH: 'R', mV: '3', mC: '12' },
    { numH: '2.5', baseH: 'R', mV: '3', mC: '14' },
    { numH: '2.5', baseH: 'R', mV: '3', mC: '16' },
    { numH: '5', baseH: 'R', mV: '3', mC: '2' },
    { numH: '5', baseH: 'R', mV: '3', mC: '4' },
    { numH: '5', baseH: 'R', mV: '3', mC: '6' },
    { numH: '5', baseH: 'R', mV: '3', mC: '8' },
    { numH: '5', baseH: 'R', mV: '3', mC: '10' },
    { numH: '5', baseH: 'R', mV: '3', mC: '12' },
    { numH: '5', baseH: 'R', mV: '3', mC: '14' },
    { numH: '5', baseH: 'R', mV: '3', mC: '16' },
    { numH: '7.5', baseH: 'R', mV: '3', mC: '2' },
    { numH: '7.5', baseH: 'R', mV: '3', mC: '4' },
    { numH: '7.5', baseH: 'R', mV: '3', mC: '6' },
    { numH: '7.5', baseH: 'R', mV: '3', mC: '8' },
    { numH: '7.5', baseH: 'R', mV: '3', mC: '10' },
    { numH: '7.5', baseH: 'R', mV: '3', mC: '12' },
    { numH: '7.5', baseH: 'R', mV: '3', mC: '14' },
    { numH: '7.5', baseH: 'R', mV: '3', mC: '16' },
    { numH: '10', baseH: 'R', mV: '3', mC: '2' },
    { numH: '10', baseH: 'R', mV: '3', mC: '4' },
    { numH: '10', baseH: 'R', mV: '3', mC: '6' },
    { numH: '10', baseH: 'R', mV: '3', mC: '8' },
    { numH: '10', baseH: 'R', mV: '3', mC: '10' },
    { numH: '10', baseH: 'R', mV: '3', mC: '12' },
    { numH: '10', baseH: 'R', mV: '3', mC: '14' },
    { numH: '2.5', baseH: 'YR', mV: '3', mC: '2' },
    { numH: '2.5', baseH: 'YR', mV: '3', mC: '4' },
    { numH: '2.5', baseH: 'YR', mV: '3', mC: '6' },
    { numH: '2.5', baseH: 'YR', mV: '3', mC: '8' },
    { numH: '2.5', baseH: 'YR', mV: '3', mC: '10' },
    { numH: '5', baseH: 'YR', mV: '3', mC: '2' },
    { numH: '5', baseH: 'YR', mV: '3', mC: '4' },
    { numH: '5', baseH: 'YR', mV: '3', mC: '6' },
    { numH: '5', baseH: 'YR', mV: '3', mC: '8' },
    { numH: '7.5', baseH: 'YR', mV: '3', mC: '2' },
    { numH: '7.5', baseH: 'YR', mV: '3', mC: '4' },
    { numH: '7.5', baseH: 'YR', mV: '3', mC: '6' },
    { numH: '7.5', baseH: 'YR', mV: '3', mC: '8' },
    { numH: '10', baseH: 'YR', mV: '3', mC: '2' },
    { numH: '10', baseH: 'YR', mV: '3', mC: '4' },
    { numH: '10', baseH: 'YR', mV: '3', mC: '6' },
    { numH: '10', baseH: 'YR', mV: '3', mC: '8' },
    { numH: '2.5', baseH: 'Y', mV: '3', mC: '2' },
    { numH: '2.5', baseH: 'Y', mV: '3', mC: '4' },
    { numH: '2.5', baseH: 'Y', mV: '3', mC: '6' },
    { numH: '5', baseH: 'Y', mV: '3', mC: '2' },
    { numH: '5', baseH: 'Y', mV: '3', mC: '4' },
    { numH: '5', baseH: 'Y', mV: '3', mC: '6' },
    { numH: '7.5', baseH: 'Y', mV: '3', mC: '2' },
    { numH: '7.5', baseH: 'Y', mV: '3', mC: '4' },
    { numH: '7.5', baseH: 'Y', mV: '3', mC: '6' },
    { numH: '10', baseH: 'Y', mV: '3', mC: '2' },
    { numH: '10', baseH: 'Y', mV: '3', mC: '4' },
    { numH: '10', baseH: 'Y', mV: '3', mC: '6' },
    { numH: '2.5', baseH: 'GY', mV: '3', mC: '2' },
    { numH: '2.5', baseH: 'GY', mV: '3', mC: '4' },
    { numH: '2.5', baseH: 'GY', mV: '3', mC: '6' },
    { numH: '5', baseH: 'GY', mV: '3', mC: '2' },
    { numH: '5', baseH: 'GY', mV: '3', mC: '4' },
    { numH: '5', baseH: 'GY', mV: '3', mC: '6' },
    { numH: '5', baseH: 'GY', mV: '3', mC: '8' },
    { numH: '7.5', baseH: 'GY', mV: '3', mC: '2' },
    { numH: '7.5', baseH: 'GY', mV: '3', mC: '4' },
    { numH: '7.5', baseH: 'GY', mV: '3', mC: '6' },
    { numH: '7.5', baseH: 'GY', mV: '3', mC: '8' },
    { numH: '7.5', baseH: 'GY', mV: '3', mC: '10' },
    { numH: '10', baseH: 'GY', mV: '3', mC: '2' },
    { numH: '10', baseH: 'GY', mV: '3', mC: '4' },
    { numH: '10', baseH: 'GY', mV: '3', mC: '6' },
    { numH: '10', baseH: 'GY', mV: '3', mC: '8' },
    { numH: '10', baseH: 'GY', mV: '3', mC: '10' },
    { numH: '10', baseH: 'GY', mV: '3', mC: '12' },
    { numH: '10', baseH: 'GY', mV: '3', mC: '14' },
    { numH: '2.5', baseH: 'G', mV: '3', mC: '2' },
    { numH: '2.5', baseH: 'G', mV: '3', mC: '4' },
    { numH: '2.5', baseH: 'G', mV: '3', mC: '6' },
    { numH: '2.5', baseH: 'G', mV: '3', mC: '8' },
    { numH: '2.5', baseH: 'G', mV: '3', mC: '10' },
    { numH: '2.5', baseH: 'G', mV: '3', mC: '12' },
    { numH: '2.5', baseH: 'G', mV: '3', mC: '14' },
    { numH: '2.5', baseH: 'G', mV: '3', mC: '16' },
    { numH: '2.5', baseH: 'G', mV: '3', mC: '18' },
    { numH: '2.5', baseH: 'G', mV: '3', mC: '20' },
    { numH: '2.5', baseH: 'G', mV: '3', mC: '22' },
    { numH: '5', baseH: 'G', mV: '3', mC: '2' },
    { numH: '5', baseH: 'G', mV: '3', mC: '4' },
    { numH: '5', baseH: 'G', mV: '3', mC: '6' },
    { numH: '5', baseH: 'G', mV: '3', mC: '8' },
    { numH: '5', baseH: 'G', mV: '3', mC: '10' },
    { numH: '5', baseH: 'G', mV: '3', mC: '12' },
    { numH: '5', baseH: 'G', mV: '3', mC: '14' },
    { numH: '5', baseH: 'G', mV: '3', mC: '16' },
    { numH: '5', baseH: 'G', mV: '3', mC: '18' },
    { numH: '5', baseH: 'G', mV: '3', mC: '20' },
    { numH: '5', baseH: 'G', mV: '3', mC: '22' },
    { numH: '7.5', baseH: 'G', mV: '3', mC: '2' },
    { numH: '7.5', baseH: 'G', mV: '3', mC: '4' },
    { numH: '7.5', baseH: 'G', mV: '3', mC: '6' },
    { numH: '7.5', baseH: 'G', mV: '3', mC: '8' },
    { numH: '7.5', baseH: 'G', mV: '3', mC: '10' },
    { numH: '7.5', baseH: 'G', mV: '3', mC: '12' },
    { numH: '7.5', baseH: 'G', mV: '3', mC: '14' },
    { numH: '7.5', baseH: 'G', mV: '3', mC: '16' },
    { numH: '7.5', baseH: 'G', mV: '3', mC: '18' },
    { numH: '7.5', baseH: 'G', mV: '3', mC: '20' },
    { numH: '7.5', baseH: 'G', mV: '3', mC: '22' },
    { numH: '10', baseH: 'G', mV: '3', mC: '2' },
    { numH: '10', baseH: 'G', mV: '3', mC: '4' },
    { numH: '10', baseH: 'G', mV: '3', mC: '6' },
    { numH: '10', baseH: 'G', mV: '3', mC: '8' },
    { numH: '10', baseH: 'G', mV: '3', mC: '10' },
    { numH: '10', baseH: 'G', mV: '3', mC: '12' },
    { numH: '10', baseH: 'G', mV: '3', mC: '14' },
    { numH: '10', baseH: 'G', mV: '3', mC: '16' },
    { numH: '10', baseH: 'G', mV: '3', mC: '18' },
    { numH: '10', baseH: 'G', mV: '3', mC: '20' },
    { numH: '10', baseH: 'G', mV: '3', mC: '22' },
    { numH: '2.5', baseH: 'BG', mV: '3', mC: '2' },
    { numH: '2.5', baseH: 'BG', mV: '3', mC: '4' },
    { numH: '2.5', baseH: 'BG', mV: '3', mC: '6' },
    { numH: '2.5', baseH: 'BG', mV: '3', mC: '8' },
    { numH: '2.5', baseH: 'BG', mV: '3', mC: '10' },
    { numH: '2.5', baseH: 'BG', mV: '3', mC: '12' },
    { numH: '2.5', baseH: 'BG', mV: '3', mC: '14' },
    { numH: '2.5', baseH: 'BG', mV: '3', mC: '16' },
    { numH: '2.5', baseH: 'BG', mV: '3', mC: '18' },
    { numH: '2.5', baseH: 'BG', mV: '3', mC: '20' },
    { numH: '5', baseH: 'BG', mV: '3', mC: '2' },
    { numH: '5', baseH: 'BG', mV: '3', mC: '4' },
    { numH: '5', baseH: 'BG', mV: '3', mC: '6' },
    { numH: '5', baseH: 'BG', mV: '3', mC: '8' },
    { numH: '5', baseH: 'BG', mV: '3', mC: '10' },
    { numH: '5', baseH: 'BG', mV: '3', mC: '12' },
    { numH: '5', baseH: 'BG', mV: '3', mC: '14' },
    { numH: '5', baseH: 'BG', mV: '3', mC: '16' },
    { numH: '5', baseH: 'BG', mV: '3', mC: '18' },
    { numH: '7.5', baseH: 'BG', mV: '3', mC: '2' },
    { numH: '7.5', baseH: 'BG', mV: '3', mC: '4' },
    { numH: '7.5', baseH: 'BG', mV: '3', mC: '6' },
    { numH: '7.5', baseH: 'BG', mV: '3', mC: '8' },
    { numH: '7.5', baseH: 'BG', mV: '3', mC: '10' },
    { numH: '7.5', baseH: 'BG', mV: '3', mC: '12' },
    { numH: '7.5', baseH: 'BG', mV: '3', mC: '14' },
    { numH: '7.5', baseH: 'BG', mV: '3', mC: '16' },
    { numH: '10', baseH: 'BG', mV: '3', mC: '2' },
    { numH: '10', baseH: 'BG', mV: '3', mC: '4' },
    { numH: '10', baseH: 'BG', mV: '3', mC: '6' },
    { numH: '10', baseH: 'BG', mV: '3', mC: '8' },
    { numH: '10', baseH: 'BG', mV: '3', mC: '10' },
    { numH: '10', baseH: 'BG', mV: '3', mC: '12' },
    { numH: '10', baseH: 'BG', mV: '3', mC: '14' },
    { numH: '2.5', baseH: 'B', mV: '3', mC: '2' },
    { numH: '2.5', baseH: 'B', mV: '3', mC: '4' },
    { numH: '2.5', baseH: 'B', mV: '3', mC: '6' },
    { numH: '2.5', baseH: 'B', mV: '3', mC: '8' },
    { numH: '2.5', baseH: 'B', mV: '3', mC: '10' },
    { numH: '2.5', baseH: 'B', mV: '3', mC: '12' },
    { numH: '5', baseH: 'B', mV: '3', mC: '2' },
    { numH: '5', baseH: 'B', mV: '3', mC: '4' },
    { numH: '5', baseH: 'B', mV: '3', mC: '6' },
    { numH: '5', baseH: 'B', mV: '3', mC: '8' },
    { numH: '5', baseH: 'B', mV: '3', mC: '10' },
    { numH: '5', baseH: 'B', mV: '3', mC: '12' },
    { numH: '7.5', baseH: 'B', mV: '3', mC: '2' },
    { numH: '7.5', baseH: 'B', mV: '3', mC: '4' },
    { numH: '7.5', baseH: 'B', mV: '3', mC: '6' },
    { numH: '7.5', baseH: 'B', mV: '3', mC: '8' },
    { numH: '7.5', baseH: 'B', mV: '3', mC: '10' },
    { numH: '7.5', baseH: 'B', mV: '3', mC: '12' },
    { numH: '10', baseH: 'B', mV: '3', mC: '2' },
    { numH: '10', baseH: 'B', mV: '3', mC: '4' },
    { numH: '10', baseH: 'B', mV: '3', mC: '6' },
    { numH: '10', baseH: 'B', mV: '3', mC: '8' },
    { numH: '10', baseH: 'B', mV: '3', mC: '10' },
    { numH: '10', baseH: 'B', mV: '3', mC: '12' },
    { numH: '10', baseH: 'B', mV: '3', mC: '14' },
    { numH: '2.5', baseH: 'PB', mV: '3', mC: '2' },
    { numH: '2.5', baseH: 'PB', mV: '3', mC: '4' },
    { numH: '2.5', baseH: 'PB', mV: '3', mC: '6' },
    { numH: '2.5', baseH: 'PB', mV: '3', mC: '8' },
    { numH: '2.5', baseH: 'PB', mV: '3', mC: '10' },
    { numH: '2.5', baseH: 'PB', mV: '3', mC: '12' },
    { numH: '2.5', baseH: 'PB', mV: '3', mC: '14' },
    { numH: '5', baseH: 'PB', mV: '3', mC: '2' },
    { numH: '5', baseH: 'PB', mV: '3', mC: '4' },
    { numH: '5', baseH: 'PB', mV: '3', mC: '6' },
    { numH: '5', baseH: 'PB', mV: '3', mC: '8' },
    { numH: '5', baseH: 'PB', mV: '3', mC: '10' },
    { numH: '5', baseH: 'PB', mV: '3', mC: '12' },
    { numH: '5', baseH: 'PB', mV: '3', mC: '14' },
    { numH: '5', baseH: 'PB', mV: '3', mC: '16' },
    { numH: '5', baseH: 'PB', mV: '3', mC: '18' },
    { numH: '7.5', baseH: 'PB', mV: '3', mC: '2' },
    { numH: '7.5', baseH: 'PB', mV: '3', mC: '4' },
    { numH: '7.5', baseH: 'PB', mV: '3', mC: '6' },
    { numH: '7.5', baseH: 'PB', mV: '3', mC: '8' },
    { numH: '7.5', baseH: 'PB', mV: '3', mC: '10' },
    { numH: '7.5', baseH: 'PB', mV: '3', mC: '12' },
    { numH: '7.5', baseH: 'PB', mV: '3', mC: '14' },
    { numH: '7.5', baseH: 'PB', mV: '3', mC: '16' },
    { numH: '7.5', baseH: 'PB', mV: '3', mC: '18' },
    { numH: '7.5', baseH: 'PB', mV: '3', mC: '20' },
    { numH: '7.5', baseH: 'PB', mV: '3', mC: '22' },
    { numH: '7.5', baseH: 'PB', mV: '3', mC: '24' },
    { numH: '7.5', baseH: 'PB', mV: '3', mC: '26' },
    { numH: '7.5', baseH: 'PB', mV: '3', mC: '28' },
    { numH: '7.5', baseH: 'PB', mV: '3', mC: '30' },
    { numH: '7.5', baseH: 'PB', mV: '3', mC: '32' },
    { numH: '7.5', baseH: 'PB', mV: '3', mC: '34' },
    { numH: '10', baseH: 'PB', mV: '3', mC: '2' },
    { numH: '10', baseH: 'PB', mV: '3', mC: '4' },
    { numH: '10', baseH: 'PB', mV: '3', mC: '6' },
    { numH: '10', baseH: 'PB', mV: '3', mC: '8' },
    { numH: '10', baseH: 'PB', mV: '3', mC: '10' },
    { numH: '10', baseH: 'PB', mV: '3', mC: '12' },
    { numH: '10', baseH: 'PB', mV: '3', mC: '14' },
    { numH: '10', baseH: 'PB', mV: '3', mC: '16' },
    { numH: '10', baseH: 'PB', mV: '3', mC: '18' },
    { numH: '10', baseH: 'PB', mV: '3', mC: '20' },
    { numH: '10', baseH: 'PB', mV: '3', mC: '22' },
    { numH: '10', baseH: 'PB', mV: '3', mC: '24' },
    { numH: '10', baseH: 'PB', mV: '3', mC: '26' },
    { numH: '10', baseH: 'PB', mV: '3', mC: '28' },
    { numH: '10', baseH: 'PB', mV: '3', mC: '30' },
    { numH: '10', baseH: 'PB', mV: '3', mC: '32' },
    { numH: '10', baseH: 'PB', mV: '3', mC: '34' },
    { numH: '2.5', baseH: 'P', mV: '3', mC: '2' },
    { numH: '2.5', baseH: 'P', mV: '3', mC: '4' },
    { numH: '2.5', baseH: 'P', mV: '3', mC: '6' },
    { numH: '2.5', baseH: 'P', mV: '3', mC: '8' },
    { numH: '2.5', baseH: 'P', mV: '3', mC: '10' },
    { numH: '2.5', baseH: 'P', mV: '3', mC: '12' },
    { numH: '2.5', baseH: 'P', mV: '3', mC: '14' },
    { numH: '2.5', baseH: 'P', mV: '3', mC: '16' },
    { numH: '2.5', baseH: 'P', mV: '3', mC: '18' },
    { numH: '2.5', baseH: 'P', mV: '3', mC: '20' },
    { numH: '2.5', baseH: 'P', mV: '3', mC: '22' },
    { numH: '2.5', baseH: 'P', mV: '3', mC: '24' },
    { numH: '2.5', baseH: 'P', mV: '3', mC: '26' },
    { numH: '2.5', baseH: 'P', mV: '3', mC: '28' },
    { numH: '2.5', baseH: 'P', mV: '3', mC: '30' },
    { numH: '2.5', baseH: 'P', mV: '3', mC: '32' },
    { numH: '2.5', baseH: 'P', mV: '3', mC: '34' },
    { numH: '5', baseH: 'P', mV: '3', mC: '2' },
    { numH: '5', baseH: 'P', mV: '3', mC: '4' },
    { numH: '5', baseH: 'P', mV: '3', mC: '6' },
    { numH: '5', baseH: 'P', mV: '3', mC: '8' },
    { numH: '5', baseH: 'P', mV: '3', mC: '10' },
    { numH: '5', baseH: 'P', mV: '3', mC: '12' },
    { numH: '5', baseH: 'P', mV: '3', mC: '14' },
    { numH: '5', baseH: 'P', mV: '3', mC: '16' },
    { numH: '5', baseH: 'P', mV: '3', mC: '18' },
    { numH: '5', baseH: 'P', mV: '3', mC: '20' },
    { numH: '5', baseH: 'P', mV: '3', mC: '22' },
    { numH: '5', baseH: 'P', mV: '3', mC: '24' },
    { numH: '5', baseH: 'P', mV: '3', mC: '26' },
    { numH: '5', baseH: 'P', mV: '3', mC: '28' },
    { numH: '5', baseH: 'P', mV: '3', mC: '30' },
    { numH: '5', baseH: 'P', mV: '3', mC: '32' },
    { numH: '7.5', baseH: 'P', mV: '3', mC: '2' },
    { numH: '7.5', baseH: 'P', mV: '3', mC: '4' },
    { numH: '7.5', baseH: 'P', mV: '3', mC: '6' },
    { numH: '7.5', baseH: 'P', mV: '3', mC: '8' },
    { numH: '7.5', baseH: 'P', mV: '3', mC: '10' },
    { numH: '7.5', baseH: 'P', mV: '3', mC: '12' },
    { numH: '7.5', baseH: 'P', mV: '3', mC: '14' },
    { numH: '7.5', baseH: 'P', mV: '3', mC: '16' },
    { numH: '7.5', baseH: 'P', mV: '3', mC: '18' },
    { numH: '7.5', baseH: 'P', mV: '3', mC: '20' },
    { numH: '7.5', baseH: 'P', mV: '3', mC: '22' },
    { numH: '7.5', baseH: 'P', mV: '3', mC: '24' },
    { numH: '7.5', baseH: 'P', mV: '3', mC: '26' },
    { numH: '7.5', baseH: 'P', mV: '3', mC: '28' },
    { numH: '7.5', baseH: 'P', mV: '3', mC: '30' },
    { numH: '10', baseH: 'P', mV: '3', mC: '2' },
    { numH: '10', baseH: 'P', mV: '3', mC: '4' },
    { numH: '10', baseH: 'P', mV: '3', mC: '6' },
    { numH: '10', baseH: 'P', mV: '3', mC: '8' },
    { numH: '10', baseH: 'P', mV: '3', mC: '10' },
    { numH: '10', baseH: 'P', mV: '3', mC: '12' },
    { numH: '10', baseH: 'P', mV: '3', mC: '14' },
    { numH: '10', baseH: 'P', mV: '3', mC: '16' },
    { numH: '10', baseH: 'P', mV: '3', mC: '18' },
    { numH: '10', baseH: 'P', mV: '3', mC: '20' },
    { numH: '10', baseH: 'P', mV: '3', mC: '22' },
    { numH: '10', baseH: 'P', mV: '3', mC: '24' },
    { numH: '10', baseH: 'P', mV: '3', mC: '26' },
    { numH: '2.5', baseH: 'RP', mV: '3', mC: '2' },
    { numH: '2.5', baseH: 'RP', mV: '3', mC: '4' },
    { numH: '2.5', baseH: 'RP', mV: '3', mC: '6' },
    { numH: '2.5', baseH: 'RP', mV: '3', mC: '8' },
    { numH: '2.5', baseH: 'RP', mV: '3', mC: '10' },
    { numH: '2.5', baseH: 'RP', mV: '3', mC: '12' },
    { numH: '2.5', baseH: 'RP', mV: '3', mC: '14' },
    { numH: '2.5', baseH: 'RP', mV: '3', mC: '16' },
    { numH: '2.5', baseH: 'RP', mV: '3', mC: '18' },
    { numH: '2.5', baseH: 'RP', mV: '3', mC: '20' },
    { numH: '2.5', baseH: 'RP', mV: '3', mC: '22' },
    { numH: '5', baseH: 'RP', mV: '3', mC: '2' },
    { numH: '5', baseH: 'RP', mV: '3', mC: '4' },
    { numH: '5', baseH: 'RP', mV: '3', mC: '6' },
    { numH: '5', baseH: 'RP', mV: '3', mC: '8' },
    { numH: '5', baseH: 'RP', mV: '3', mC: '10' },
    { numH: '5', baseH: 'RP', mV: '3', mC: '12' },
    { numH: '5', baseH: 'RP', mV: '3', mC: '14' },
    { numH: '5', baseH: 'RP', mV: '3', mC: '16' },
    { numH: '5', baseH: 'RP', mV: '3', mC: '18' },
    { numH: '5', baseH: 'RP', mV: '3', mC: '20' },
    { numH: '7.5', baseH: 'RP', mV: '3', mC: '2' },
    { numH: '7.5', baseH: 'RP', mV: '3', mC: '4' },
    { numH: '7.5', baseH: 'RP', mV: '3', mC: '6' },
    { numH: '7.5', baseH: 'RP', mV: '3', mC: '8' },
    { numH: '7.5', baseH: 'RP', mV: '3', mC: '10' },
    { numH: '7.5', baseH: 'RP', mV: '3', mC: '12' },
    { numH: '7.5', baseH: 'RP', mV: '3', mC: '14' },
    { numH: '7.5', baseH: 'RP', mV: '3', mC: '16' },
    { numH: '7.5', baseH: 'RP', mV: '3', mC: '18' },
    { numH: '10', baseH: 'RP', mV: '4', mC: '2' },
    { numH: '10', baseH: 'RP', mV: '4', mC: '4' },
    { numH: '10', baseH: 'RP', mV: '4', mC: '6' },
    { numH: '10', baseH: 'RP', mV: '4', mC: '8' },
    { numH: '10', baseH: 'RP', mV: '4', mC: '10' },
    { numH: '10', baseH: 'RP', mV: '4', mC: '12' },
    { numH: '10', baseH: 'RP', mV: '4', mC: '14' },
    { numH: '10', baseH: 'RP', mV: '4', mC: '16' },
    { numH: '10', baseH: 'RP', mV: '4', mC: '18' },
    { numH: '10', baseH: 'RP', mV: '4', mC: '20' },
    { numH: '2.5', baseH: 'R', mV: '4', mC: '2' },
    { numH: '2.5', baseH: 'R', mV: '4', mC: '4' },
    { numH: '2.5', baseH: 'R', mV: '4', mC: '6' },
    { numH: '2.5', baseH: 'R', mV: '4', mC: '8' },
    { numH: '2.5', baseH: 'R', mV: '4', mC: '10' },
    { numH: '2.5', baseH: 'R', mV: '4', mC: '12' },
    { numH: '2.5', baseH: 'R', mV: '4', mC: '14' },
    { numH: '2.5', baseH: 'R', mV: '4', mC: '16' },
    { numH: '2.5', baseH: 'R', mV: '4', mC: '18' },
    { numH: '5', baseH: 'R', mV: '4', mC: '2' },
    { numH: '5', baseH: 'R', mV: '4', mC: '4' },
    { numH: '5', baseH: 'R', mV: '4', mC: '6' },
    { numH: '5', baseH: 'R', mV: '4', mC: '8' },
    { numH: '5', baseH: 'R', mV: '4', mC: '10' },
    { numH: '5', baseH: 'R', mV: '4', mC: '12' },
    { numH: '5', baseH: 'R', mV: '4', mC: '14' },
    { numH: '5', baseH: 'R', mV: '4', mC: '16' },
    { numH: '5', baseH: 'R', mV: '4', mC: '18' },
    { numH: '7.5', baseH: 'R', mV: '4', mC: '2' },
    { numH: '7.5', baseH: 'R', mV: '4', mC: '4' },
    { numH: '7.5', baseH: 'R', mV: '4', mC: '6' },
    { numH: '7.5', baseH: 'R', mV: '4', mC: '8' },
    { numH: '7.5', baseH: 'R', mV: '4', mC: '10' },
    { numH: '7.5', baseH: 'R', mV: '4', mC: '12' },
    { numH: '7.5', baseH: 'R', mV: '4', mC: '14' },
    { numH: '7.5', baseH: 'R', mV: '4', mC: '16' },
    { numH: '7.5', baseH: 'R', mV: '4', mC: '18' },
    { numH: '7.5', baseH: 'R', mV: '4', mC: '20' },
    { numH: '10', baseH: 'R', mV: '4', mC: '2' },
    { numH: '10', baseH: 'R', mV: '4', mC: '4' },
    { numH: '10', baseH: 'R', mV: '4', mC: '6' },
    { numH: '10', baseH: 'R', mV: '4', mC: '8' },
    { numH: '10', baseH: 'R', mV: '4', mC: '10' },
    { numH: '10', baseH: 'R', mV: '4', mC: '12' },
    { numH: '10', baseH: 'R', mV: '4', mC: '14' },
    { numH: '10', baseH: 'R', mV: '4', mC: '16' },
    { numH: '2.5', baseH: 'YR', mV: '4', mC: '2' },
    { numH: '2.5', baseH: 'YR', mV: '4', mC: '4' },
    { numH: '2.5', baseH: 'YR', mV: '4', mC: '6' },
    { numH: '2.5', baseH: 'YR', mV: '4', mC: '8' },
    { numH: '2.5', baseH: 'YR', mV: '4', mC: '10' },
    { numH: '2.5', baseH: 'YR', mV: '4', mC: '12' },
    { numH: '5', baseH: 'YR', mV: '4', mC: '2' },
    { numH: '5', baseH: 'YR', mV: '4', mC: '4' },
    { numH: '5', baseH: 'YR', mV: '4', mC: '6' },
    { numH: '5', baseH: 'YR', mV: '4', mC: '8' },
    { numH: '5', baseH: 'YR', mV: '4', mC: '10' },
    { numH: '5', baseH: 'YR', mV: '4', mC: '12' },
    { numH: '7.5', baseH: 'YR', mV: '4', mC: '2' },
    { numH: '7.5', baseH: 'YR', mV: '4', mC: '4' },
    { numH: '7.5', baseH: 'YR', mV: '4', mC: '6' },
    { numH: '7.5', baseH: 'YR', mV: '4', mC: '8' },
    { numH: '7.5', baseH: 'YR', mV: '4', mC: '10' },
    { numH: '10', baseH: 'YR', mV: '4', mC: '2' },
    { numH: '10', baseH: 'YR', mV: '4', mC: '4' },
    { numH: '10', baseH: 'YR', mV: '4', mC: '6' },
    { numH: '10', baseH: 'YR', mV: '4', mC: '8' },
    { numH: '10', baseH: 'YR', mV: '4', mC: '10' },
    { numH: '2.5', baseH: 'Y', mV: '4', mC: '2' },
    { numH: '2.5', baseH: 'Y', mV: '4', mC: '4' },
    { numH: '2.5', baseH: 'Y', mV: '4', mC: '6' },
    { numH: '2.5', baseH: 'Y', mV: '4', mC: '8' },
    { numH: '2.5', baseH: 'Y', mV: '4', mC: '10' },
    { numH: '5', baseH: 'Y', mV: '4', mC: '2' },
    { numH: '5', baseH: 'Y', mV: '4', mC: '4' },
    { numH: '5', baseH: 'Y', mV: '4', mC: '6' },
    { numH: '5', baseH: 'Y', mV: '4', mC: '8' },
    { numH: '7.5', baseH: 'Y', mV: '4', mC: '2' },
    { numH: '7.5', baseH: 'Y', mV: '4', mC: '4' },
    { numH: '7.5', baseH: 'Y', mV: '4', mC: '6' },
    { numH: '7.5', baseH: 'Y', mV: '4', mC: '8' },
    { numH: '10', baseH: 'Y', mV: '4', mC: '2' },
    { numH: '10', baseH: 'Y', mV: '4', mC: '4' },
    { numH: '10', baseH: 'Y', mV: '4', mC: '6' },
    { numH: '10', baseH: 'Y', mV: '4', mC: '8' },
    { numH: '2.5', baseH: 'GY', mV: '4', mC: '2' },
    { numH: '2.5', baseH: 'GY', mV: '4', mC: '4' },
    { numH: '2.5', baseH: 'GY', mV: '4', mC: '6' },
    { numH: '2.5', baseH: 'GY', mV: '4', mC: '8' },
    { numH: '5', baseH: 'GY', mV: '4', mC: '2' },
    { numH: '5', baseH: 'GY', mV: '4', mC: '4' },
    { numH: '5', baseH: 'GY', mV: '4', mC: '6' },
    { numH: '5', baseH: 'GY', mV: '4', mC: '8' },
    { numH: '5', baseH: 'GY', mV: '4', mC: '10' },
    { numH: '7.5', baseH: 'GY', mV: '4', mC: '2' },
    { numH: '7.5', baseH: 'GY', mV: '4', mC: '4' },
    { numH: '7.5', baseH: 'GY', mV: '4', mC: '6' },
    { numH: '7.5', baseH: 'GY', mV: '4', mC: '8' },
    { numH: '7.5', baseH: 'GY', mV: '4', mC: '10' },
    { numH: '7.5', baseH: 'GY', mV: '4', mC: '12' },
    { numH: '10', baseH: 'GY', mV: '4', mC: '2' },
    { numH: '10', baseH: 'GY', mV: '4', mC: '4' },
    { numH: '10', baseH: 'GY', mV: '4', mC: '6' },
    { numH: '10', baseH: 'GY', mV: '4', mC: '8' },
    { numH: '10', baseH: 'GY', mV: '4', mC: '10' },
    { numH: '10', baseH: 'GY', mV: '4', mC: '12' },
    { numH: '10', baseH: 'GY', mV: '4', mC: '14' },
    { numH: '10', baseH: 'GY', mV: '4', mC: '16' },
    { numH: '2.5', baseH: 'G', mV: '4', mC: '2' },
    { numH: '2.5', baseH: 'G', mV: '4', mC: '4' },
    { numH: '2.5', baseH: 'G', mV: '4', mC: '6' },
    { numH: '2.5', baseH: 'G', mV: '4', mC: '8' },
    { numH: '2.5', baseH: 'G', mV: '4', mC: '10' },
    { numH: '2.5', baseH: 'G', mV: '4', mC: '12' },
    { numH: '2.5', baseH: 'G', mV: '4', mC: '14' },
    { numH: '2.5', baseH: 'G', mV: '4', mC: '16' },
    { numH: '2.5', baseH: 'G', mV: '4', mC: '18' },
    { numH: '2.5', baseH: 'G', mV: '4', mC: '20' },
    { numH: '2.5', baseH: 'G', mV: '4', mC: '22' },
    { numH: '2.5', baseH: 'G', mV: '4', mC: '24' },
    { numH: '2.5', baseH: 'G', mV: '4', mC: '26' },
    { numH: '5', baseH: 'G', mV: '4', mC: '2' },
    { numH: '5', baseH: 'G', mV: '4', mC: '4' },
    { numH: '5', baseH: 'G', mV: '4', mC: '6' },
    { numH: '5', baseH: 'G', mV: '4', mC: '8' },
    { numH: '5', baseH: 'G', mV: '4', mC: '10' },
    { numH: '5', baseH: 'G', mV: '4', mC: '12' },
    { numH: '5', baseH: 'G', mV: '4', mC: '14' },
    { numH: '5', baseH: 'G', mV: '4', mC: '16' },
    { numH: '5', baseH: 'G', mV: '4', mC: '18' },
    { numH: '5', baseH: 'G', mV: '4', mC: '20' },
    { numH: '5', baseH: 'G', mV: '4', mC: '22' },
    { numH: '5', baseH: 'G', mV: '4', mC: '24' },
    { numH: '5', baseH: 'G', mV: '4', mC: '26' },
    { numH: '7.5', baseH: 'G', mV: '4', mC: '2' },
    { numH: '7.5', baseH: 'G', mV: '4', mC: '4' },
    { numH: '7.5', baseH: 'G', mV: '4', mC: '6' },
    { numH: '7.5', baseH: 'G', mV: '4', mC: '8' },
    { numH: '7.5', baseH: 'G', mV: '4', mC: '10' },
    { numH: '7.5', baseH: 'G', mV: '4', mC: '12' },
    { numH: '7.5', baseH: 'G', mV: '4', mC: '14' },
    { numH: '7.5', baseH: 'G', mV: '4', mC: '16' },
    { numH: '7.5', baseH: 'G', mV: '4', mC: '18' },
    { numH: '7.5', baseH: 'G', mV: '4', mC: '20' },
    { numH: '7.5', baseH: 'G', mV: '4', mC: '22' },
    { numH: '7.5', baseH: 'G', mV: '4', mC: '24' },
    { numH: '7.5', baseH: 'G', mV: '4', mC: '26' },
    { numH: '10', baseH: 'G', mV: '4', mC: '2' },
    { numH: '10', baseH: 'G', mV: '4', mC: '4' },
    { numH: '10', baseH: 'G', mV: '4', mC: '6' },
    { numH: '10', baseH: 'G', mV: '4', mC: '8' },
    { numH: '10', baseH: 'G', mV: '4', mC: '10' },
    { numH: '10', baseH: 'G', mV: '4', mC: '12' },
    { numH: '10', baseH: 'G', mV: '4', mC: '14' },
    { numH: '10', baseH: 'G', mV: '4', mC: '16' },
    { numH: '10', baseH: 'G', mV: '4', mC: '18' },
    { numH: '10', baseH: 'G', mV: '4', mC: '20' },
    { numH: '10', baseH: 'G', mV: '4', mC: '22' },
    { numH: '10', baseH: 'G', mV: '4', mC: '24' },
    { numH: '10', baseH: 'G', mV: '4', mC: '26' },
    { numH: '2.5', baseH: 'BG', mV: '4', mC: '2' },
    { numH: '2.5', baseH: 'BG', mV: '4', mC: '4' },
    { numH: '2.5', baseH: 'BG', mV: '4', mC: '6' },
    { numH: '2.5', baseH: 'BG', mV: '4', mC: '8' },
    { numH: '2.5', baseH: 'BG', mV: '4', mC: '10' },
    { numH: '2.5', baseH: 'BG', mV: '4', mC: '12' },
    { numH: '2.5', baseH: 'BG', mV: '4', mC: '14' },
    { numH: '2.5', baseH: 'BG', mV: '4', mC: '16' },
    { numH: '2.5', baseH: 'BG', mV: '4', mC: '18' },
    { numH: '2.5', baseH: 'BG', mV: '4', mC: '20' },
    { numH: '2.5', baseH: 'BG', mV: '4', mC: '22' },
    { numH: '2.5', baseH: 'BG', mV: '4', mC: '24' },
    { numH: '5', baseH: 'BG', mV: '4', mC: '2' },
    { numH: '5', baseH: 'BG', mV: '4', mC: '4' },
    { numH: '5', baseH: 'BG', mV: '4', mC: '6' },
    { numH: '5', baseH: 'BG', mV: '4', mC: '8' },
    { numH: '5', baseH: 'BG', mV: '4', mC: '10' },
    { numH: '5', baseH: 'BG', mV: '4', mC: '12' },
    { numH: '5', baseH: 'BG', mV: '4', mC: '14' },
    { numH: '5', baseH: 'BG', mV: '4', mC: '16' },
    { numH: '5', baseH: 'BG', mV: '4', mC: '18' },
    { numH: '5', baseH: 'BG', mV: '4', mC: '20' },
    { numH: '7.5', baseH: 'BG', mV: '4', mC: '2' },
    { numH: '7.5', baseH: 'BG', mV: '4', mC: '4' },
    { numH: '7.5', baseH: 'BG', mV: '4', mC: '6' },
    { numH: '7.5', baseH: 'BG', mV: '4', mC: '8' },
    { numH: '7.5', baseH: 'BG', mV: '4', mC: '10' },
    { numH: '7.5', baseH: 'BG', mV: '4', mC: '12' },
    { numH: '7.5', baseH: 'BG', mV: '4', mC: '14' },
    { numH: '7.5', baseH: 'BG', mV: '4', mC: '16' },
    { numH: '7.5', baseH: 'BG', mV: '4', mC: '18' },
    { numH: '10', baseH: 'BG', mV: '4', mC: '2' },
    { numH: '10', baseH: 'BG', mV: '4', mC: '4' },
    { numH: '10', baseH: 'BG', mV: '4', mC: '6' },
    { numH: '10', baseH: 'BG', mV: '4', mC: '8' },
    { numH: '10', baseH: 'BG', mV: '4', mC: '10' },
    { numH: '10', baseH: 'BG', mV: '4', mC: '12' },
    { numH: '10', baseH: 'BG', mV: '4', mC: '14' },
    { numH: '10', baseH: 'BG', mV: '4', mC: '16' },
    { numH: '2.5', baseH: 'B', mV: '4', mC: '2' },
    { numH: '2.5', baseH: 'B', mV: '4', mC: '4' },
    { numH: '2.5', baseH: 'B', mV: '4', mC: '6' },
    { numH: '2.5', baseH: 'B', mV: '4', mC: '8' },
    { numH: '2.5', baseH: 'B', mV: '4', mC: '10' },
    { numH: '2.5', baseH: 'B', mV: '4', mC: '12' },
    { numH: '2.5', baseH: 'B', mV: '4', mC: '14' },
    { numH: '2.5', baseH: 'B', mV: '4', mC: '16' },
    { numH: '5', baseH: 'B', mV: '4', mC: '2' },
    { numH: '5', baseH: 'B', mV: '4', mC: '4' },
    { numH: '5', baseH: 'B', mV: '4', mC: '6' },
    { numH: '5', baseH: 'B', mV: '4', mC: '8' },
    { numH: '5', baseH: 'B', mV: '4', mC: '10' },
    { numH: '5', baseH: 'B', mV: '4', mC: '12' },
    { numH: '5', baseH: 'B', mV: '4', mC: '14' },
    { numH: '7.5', baseH: 'B', mV: '4', mC: '2' },
    { numH: '7.5', baseH: 'B', mV: '4', mC: '4' },
    { numH: '7.5', baseH: 'B', mV: '4', mC: '6' },
    { numH: '7.5', baseH: 'B', mV: '4', mC: '8' },
    { numH: '7.5', baseH: 'B', mV: '4', mC: '10' },
    { numH: '7.5', baseH: 'B', mV: '4', mC: '12' },
    { numH: '7.5', baseH: 'B', mV: '4', mC: '14' },
    { numH: '10', baseH: 'B', mV: '4', mC: '2' },
    { numH: '10', baseH: 'B', mV: '4', mC: '4' },
    { numH: '10', baseH: 'B', mV: '4', mC: '6' },
    { numH: '10', baseH: 'B', mV: '4', mC: '8' },
    { numH: '10', baseH: 'B', mV: '4', mC: '10' },
    { numH: '10', baseH: 'B', mV: '4', mC: '12' },
    { numH: '10', baseH: 'B', mV: '4', mC: '14' },
    { numH: '10', baseH: 'B', mV: '4', mC: '16' },
    { numH: '2.5', baseH: 'PB', mV: '4', mC: '2' },
    { numH: '2.5', baseH: 'PB', mV: '4', mC: '4' },
    { numH: '2.5', baseH: 'PB', mV: '4', mC: '6' },
    { numH: '2.5', baseH: 'PB', mV: '4', mC: '8' },
    { numH: '2.5', baseH: 'PB', mV: '4', mC: '10' },
    { numH: '2.5', baseH: 'PB', mV: '4', mC: '12' },
    { numH: '2.5', baseH: 'PB', mV: '4', mC: '14' },
    { numH: '2.5', baseH: 'PB', mV: '4', mC: '16' },
    { numH: '2.5', baseH: 'PB', mV: '4', mC: '18' },
    { numH: '5', baseH: 'PB', mV: '4', mC: '2' },
    { numH: '5', baseH: 'PB', mV: '4', mC: '4' },
    { numH: '5', baseH: 'PB', mV: '4', mC: '6' },
    { numH: '5', baseH: 'PB', mV: '4', mC: '8' },
    { numH: '5', baseH: 'PB', mV: '4', mC: '10' },
    { numH: '5', baseH: 'PB', mV: '4', mC: '12' },
    { numH: '5', baseH: 'PB', mV: '4', mC: '14' },
    { numH: '5', baseH: 'PB', mV: '4', mC: '16' },
    { numH: '5', baseH: 'PB', mV: '4', mC: '18' },
    { numH: '5', baseH: 'PB', mV: '4', mC: '20' },
    { numH: '7.5', baseH: 'PB', mV: '4', mC: '2' },
    { numH: '7.5', baseH: 'PB', mV: '4', mC: '4' },
    { numH: '7.5', baseH: 'PB', mV: '4', mC: '6' },
    { numH: '7.5', baseH: 'PB', mV: '4', mC: '8' },
    { numH: '7.5', baseH: 'PB', mV: '4', mC: '10' },
    { numH: '7.5', baseH: 'PB', mV: '4', mC: '12' },
    { numH: '7.5', baseH: 'PB', mV: '4', mC: '14' },
    { numH: '7.5', baseH: 'PB', mV: '4', mC: '16' },
    { numH: '7.5', baseH: 'PB', mV: '4', mC: '18' },
    { numH: '7.5', baseH: 'PB', mV: '4', mC: '20' },
    { numH: '7.5', baseH: 'PB', mV: '4', mC: '22' },
    { numH: '7.5', baseH: 'PB', mV: '4', mC: '24' },
    { numH: '7.5', baseH: 'PB', mV: '4', mC: '26' },
    { numH: '10', baseH: 'PB', mV: '4', mC: '2' },
    { numH: '10', baseH: 'PB', mV: '4', mC: '4' },
    { numH: '10', baseH: 'PB', mV: '4', mC: '6' },
    { numH: '10', baseH: 'PB', mV: '4', mC: '8' },
    { numH: '10', baseH: 'PB', mV: '4', mC: '10' },
    { numH: '10', baseH: 'PB', mV: '4', mC: '12' },
    { numH: '10', baseH: 'PB', mV: '4', mC: '14' },
    { numH: '10', baseH: 'PB', mV: '4', mC: '16' },
    { numH: '10', baseH: 'PB', mV: '4', mC: '18' },
    { numH: '10', baseH: 'PB', mV: '4', mC: '20' },
    { numH: '10', baseH: 'PB', mV: '4', mC: '22' },
    { numH: '10', baseH: 'PB', mV: '4', mC: '24' },
    { numH: '10', baseH: 'PB', mV: '4', mC: '26' },
    { numH: '10', baseH: 'PB', mV: '4', mC: '28' },
    { numH: '10', baseH: 'PB', mV: '4', mC: '30' },
    { numH: '2.5', baseH: 'P', mV: '4', mC: '2' },
    { numH: '2.5', baseH: 'P', mV: '4', mC: '4' },
    { numH: '2.5', baseH: 'P', mV: '4', mC: '6' },
    { numH: '2.5', baseH: 'P', mV: '4', mC: '8' },
    { numH: '2.5', baseH: 'P', mV: '4', mC: '10' },
    { numH: '2.5', baseH: 'P', mV: '4', mC: '12' },
    { numH: '2.5', baseH: 'P', mV: '4', mC: '14' },
    { numH: '2.5', baseH: 'P', mV: '4', mC: '16' },
    { numH: '2.5', baseH: 'P', mV: '4', mC: '18' },
    { numH: '2.5', baseH: 'P', mV: '4', mC: '20' },
    { numH: '2.5', baseH: 'P', mV: '4', mC: '22' },
    { numH: '2.5', baseH: 'P', mV: '4', mC: '24' },
    { numH: '2.5', baseH: 'P', mV: '4', mC: '26' },
    { numH: '2.5', baseH: 'P', mV: '4', mC: '28' },
    { numH: '2.5', baseH: 'P', mV: '4', mC: '30' },
    { numH: '2.5', baseH: 'P', mV: '4', mC: '32' },
    { numH: '5', baseH: 'P', mV: '4', mC: '2' },
    { numH: '5', baseH: 'P', mV: '4', mC: '4' },
    { numH: '5', baseH: 'P', mV: '4', mC: '6' },
    { numH: '5', baseH: 'P', mV: '4', mC: '8' },
    { numH: '5', baseH: 'P', mV: '4', mC: '10' },
    { numH: '5', baseH: 'P', mV: '4', mC: '12' },
    { numH: '5', baseH: 'P', mV: '4', mC: '14' },
    { numH: '5', baseH: 'P', mV: '4', mC: '16' },
    { numH: '5', baseH: 'P', mV: '4', mC: '18' },
    { numH: '5', baseH: 'P', mV: '4', mC: '20' },
    { numH: '5', baseH: 'P', mV: '4', mC: '22' },
    { numH: '5', baseH: 'P', mV: '4', mC: '24' },
    { numH: '5', baseH: 'P', mV: '4', mC: '26' },
    { numH: '5', baseH: 'P', mV: '4', mC: '28' },
    { numH: '5', baseH: 'P', mV: '4', mC: '30' },
    { numH: '5', baseH: 'P', mV: '4', mC: '32' },
    { numH: '7.5', baseH: 'P', mV: '4', mC: '2' },
    { numH: '7.5', baseH: 'P', mV: '4', mC: '4' },
    { numH: '7.5', baseH: 'P', mV: '4', mC: '6' },
    { numH: '7.5', baseH: 'P', mV: '4', mC: '8' },
    { numH: '7.5', baseH: 'P', mV: '4', mC: '10' },
    { numH: '7.5', baseH: 'P', mV: '4', mC: '12' },
    { numH: '7.5', baseH: 'P', mV: '4', mC: '14' },
    { numH: '7.5', baseH: 'P', mV: '4', mC: '16' },
    { numH: '7.5', baseH: 'P', mV: '4', mC: '18' },
    { numH: '7.5', baseH: 'P', mV: '4', mC: '20' },
    { numH: '7.5', baseH: 'P', mV: '4', mC: '22' },
    { numH: '7.5', baseH: 'P', mV: '4', mC: '24' },
    { numH: '7.5', baseH: 'P', mV: '4', mC: '26' },
    { numH: '7.5', baseH: 'P', mV: '4', mC: '28' },
    { numH: '7.5', baseH: 'P', mV: '4', mC: '30' },
    { numH: '7.5', baseH: 'P', mV: '4', mC: '32' },
    { numH: '10', baseH: 'P', mV: '4', mC: '2' },
    { numH: '10', baseH: 'P', mV: '4', mC: '4' },
    { numH: '10', baseH: 'P', mV: '4', mC: '6' },
    { numH: '10', baseH: 'P', mV: '4', mC: '8' },
    { numH: '10', baseH: 'P', mV: '4', mC: '10' },
    { numH: '10', baseH: 'P', mV: '4', mC: '12' },
    { numH: '10', baseH: 'P', mV: '4', mC: '14' },
    { numH: '10', baseH: 'P', mV: '4', mC: '16' },
    { numH: '10', baseH: 'P', mV: '4', mC: '18' },
    { numH: '10', baseH: 'P', mV: '4', mC: '20' },
    { numH: '10', baseH: 'P', mV: '4', mC: '22' },
    { numH: '10', baseH: 'P', mV: '4', mC: '24' },
    { numH: '10', baseH: 'P', mV: '4', mC: '26' },
    { numH: '10', baseH: 'P', mV: '4', mC: '28' },
    { numH: '10', baseH: 'P', mV: '4', mC: '30' },
    { numH: '2.5', baseH: 'RP', mV: '4', mC: '2' },
    { numH: '2.5', baseH: 'RP', mV: '4', mC: '4' },
    { numH: '2.5', baseH: 'RP', mV: '4', mC: '6' },
    { numH: '2.5', baseH: 'RP', mV: '4', mC: '8' },
    { numH: '2.5', baseH: 'RP', mV: '4', mC: '10' },
    { numH: '2.5', baseH: 'RP', mV: '4', mC: '12' },
    { numH: '2.5', baseH: 'RP', mV: '4', mC: '14' },
    { numH: '2.5', baseH: 'RP', mV: '4', mC: '16' },
    { numH: '2.5', baseH: 'RP', mV: '4', mC: '18' },
    { numH: '2.5', baseH: 'RP', mV: '4', mC: '20' },
    { numH: '2.5', baseH: 'RP', mV: '4', mC: '22' },
    { numH: '2.5', baseH: 'RP', mV: '4', mC: '24' },
    { numH: '2.5', baseH: 'RP', mV: '4', mC: '26' },
    { numH: '5', baseH: 'RP', mV: '4', mC: '2' },
    { numH: '5', baseH: 'RP', mV: '4', mC: '4' },
    { numH: '5', baseH: 'RP', mV: '4', mC: '6' },
    { numH: '5', baseH: 'RP', mV: '4', mC: '8' },
    { numH: '5', baseH: 'RP', mV: '4', mC: '10' },
    { numH: '5', baseH: 'RP', mV: '4', mC: '12' },
    { numH: '5', baseH: 'RP', mV: '4', mC: '14' },
    { numH: '5', baseH: 'RP', mV: '4', mC: '16' },
    { numH: '5', baseH: 'RP', mV: '4', mC: '18' },
    { numH: '5', baseH: 'RP', mV: '4', mC: '20' },
    { numH: '5', baseH: 'RP', mV: '4', mC: '22' },
    { numH: '7.5', baseH: 'RP', mV: '4', mC: '2' },
    { numH: '7.5', baseH: 'RP', mV: '4', mC: '4' },
    { numH: '7.5', baseH: 'RP', mV: '4', mC: '6' },
    { numH: '7.5', baseH: 'RP', mV: '4', mC: '8' },
    { numH: '7.5', baseH: 'RP', mV: '4', mC: '10' },
    { numH: '7.5', baseH: 'RP', mV: '4', mC: '12' },
    { numH: '7.5', baseH: 'RP', mV: '4', mC: '14' },
    { numH: '7.5', baseH: 'RP', mV: '4', mC: '16' },
    { numH: '7.5', baseH: 'RP', mV: '4', mC: '18' },
    { numH: '7.5', baseH: 'RP', mV: '4', mC: '20' },
    { numH: '10', baseH: 'RP', mV: '5', mC: '2' },
    { numH: '10', baseH: 'RP', mV: '5', mC: '4' },
    { numH: '10', baseH: 'RP', mV: '5', mC: '6' },
    { numH: '10', baseH: 'RP', mV: '5', mC: '8' },
    { numH: '10', baseH: 'RP', mV: '5', mC: '10' },
    { numH: '10', baseH: 'RP', mV: '5', mC: '12' },
    { numH: '10', baseH: 'RP', mV: '5', mC: '14' },
    { numH: '10', baseH: 'RP', mV: '5', mC: '16' },
    { numH: '10', baseH: 'RP', mV: '5', mC: '18' },
    { numH: '10', baseH: 'RP', mV: '5', mC: '20' },
    { numH: '2.5', baseH: 'R', mV: '5', mC: '2' },
    { numH: '2.5', baseH: 'R', mV: '5', mC: '4' },
    { numH: '2.5', baseH: 'R', mV: '5', mC: '6' },
    { numH: '2.5', baseH: 'R', mV: '5', mC: '8' },
    { numH: '2.5', baseH: 'R', mV: '5', mC: '10' },
    { numH: '2.5', baseH: 'R', mV: '5', mC: '12' },
    { numH: '2.5', baseH: 'R', mV: '5', mC: '14' },
    { numH: '2.5', baseH: 'R', mV: '5', mC: '16' },
    { numH: '2.5', baseH: 'R', mV: '5', mC: '18' },
    { numH: '2.5', baseH: 'R', mV: '5', mC: '20' },
    { numH: '5', baseH: 'R', mV: '5', mC: '2' },
    { numH: '5', baseH: 'R', mV: '5', mC: '4' },
    { numH: '5', baseH: 'R', mV: '5', mC: '6' },
    { numH: '5', baseH: 'R', mV: '5', mC: '8' },
    { numH: '5', baseH: 'R', mV: '5', mC: '10' },
    { numH: '5', baseH: 'R', mV: '5', mC: '12' },
    { numH: '5', baseH: 'R', mV: '5', mC: '14' },
    { numH: '5', baseH: 'R', mV: '5', mC: '16' },
    { numH: '5', baseH: 'R', mV: '5', mC: '18' },
    { numH: '5', baseH: 'R', mV: '5', mC: '20' },
    { numH: '7.5', baseH: 'R', mV: '5', mC: '2' },
    { numH: '7.5', baseH: 'R', mV: '5', mC: '4' },
    { numH: '7.5', baseH: 'R', mV: '5', mC: '6' },
    { numH: '7.5', baseH: 'R', mV: '5', mC: '8' },
    { numH: '7.5', baseH: 'R', mV: '5', mC: '10' },
    { numH: '7.5', baseH: 'R', mV: '5', mC: '12' },
    { numH: '7.5', baseH: 'R', mV: '5', mC: '14' },
    { numH: '7.5', baseH: 'R', mV: '5', mC: '16' },
    { numH: '7.5', baseH: 'R', mV: '5', mC: '18' },
    { numH: '7.5', baseH: 'R', mV: '5', mC: '20' },
    { numH: '10', baseH: 'R', mV: '5', mC: '2' },
    { numH: '10', baseH: 'R', mV: '5', mC: '4' },
    { numH: '10', baseH: 'R', mV: '5', mC: '6' },
    { numH: '10', baseH: 'R', mV: '5', mC: '8' },
    { numH: '10', baseH: 'R', mV: '5', mC: '10' },
    { numH: '10', baseH: 'R', mV: '5', mC: '12' },
    { numH: '10', baseH: 'R', mV: '5', mC: '14' },
    { numH: '10', baseH: 'R', mV: '5', mC: '16' },
    { numH: '10', baseH: 'R', mV: '5', mC: '18' },
    { numH: '2.5', baseH: 'YR', mV: '5', mC: '2' },
    { numH: '2.5', baseH: 'YR', mV: '5', mC: '4' },
    { numH: '2.5', baseH: 'YR', mV: '5', mC: '6' },
    { numH: '2.5', baseH: 'YR', mV: '5', mC: '8' },
    { numH: '2.5', baseH: 'YR', mV: '5', mC: '10' },
    { numH: '2.5', baseH: 'YR', mV: '5', mC: '12' },
    { numH: '2.5', baseH: 'YR', mV: '5', mC: '14' },
    { numH: '2.5', baseH: 'YR', mV: '5', mC: '16' },
    { numH: '5', baseH: 'YR', mV: '5', mC: '2' },
    { numH: '5', baseH: 'YR', mV: '5', mC: '4' },
    { numH: '5', baseH: 'YR', mV: '5', mC: '6' },
    { numH: '5', baseH: 'YR', mV: '5', mC: '8' },
    { numH: '5', baseH: 'YR', mV: '5', mC: '10' },
    { numH: '5', baseH: 'YR', mV: '5', mC: '12' },
    { numH: '5', baseH: 'YR', mV: '5', mC: '14' },
    { numH: '7.5', baseH: 'YR', mV: '5', mC: '2' },
    { numH: '7.5', baseH: 'YR', mV: '5', mC: '4' },
    { numH: '7.5', baseH: 'YR', mV: '5', mC: '6' },
    { numH: '7.5', baseH: 'YR', mV: '5', mC: '8' },
    { numH: '7.5', baseH: 'YR', mV: '5', mC: '10' },
    { numH: '7.5', baseH: 'YR', mV: '5', mC: '12' },
    { numH: '7.5', baseH: 'YR', mV: '5', mC: '14' },
    { numH: '10', baseH: 'YR', mV: '5', mC: '2' },
    { numH: '10', baseH: 'YR', mV: '5', mC: '4' },
    { numH: '10', baseH: 'YR', mV: '5', mC: '6' },
    { numH: '10', baseH: 'YR', mV: '5', mC: '8' },
    { numH: '10', baseH: 'YR', mV: '5', mC: '10' },
    { numH: '10', baseH: 'YR', mV: '5', mC: '12' },
    { numH: '2.5', baseH: 'Y', mV: '5', mC: '2' },
    { numH: '2.5', baseH: 'Y', mV: '5', mC: '4' },
    { numH: '2.5', baseH: 'Y', mV: '5', mC: '6' },
    { numH: '2.5', baseH: 'Y', mV: '5', mC: '8' },
    { numH: '2.5', baseH: 'Y', mV: '5', mC: '10' },
    { numH: '2.5', baseH: 'Y', mV: '5', mC: '12' },
    { numH: '5', baseH: 'Y', mV: '5', mC: '2' },
    { numH: '5', baseH: 'Y', mV: '5', mC: '4' },
    { numH: '5', baseH: 'Y', mV: '5', mC: '6' },
    { numH: '5', baseH: 'Y', mV: '5', mC: '8' },
    { numH: '5', baseH: 'Y', mV: '5', mC: '10' },
    { numH: '5', baseH: 'Y', mV: '5', mC: '12' },
    { numH: '7.5', baseH: 'Y', mV: '5', mC: '2' },
    { numH: '7.5', baseH: 'Y', mV: '5', mC: '4' },
    { numH: '7.5', baseH: 'Y', mV: '5', mC: '6' },
    { numH: '7.5', baseH: 'Y', mV: '5', mC: '8' },
    { numH: '7.5', baseH: 'Y', mV: '5', mC: '10' },
    { numH: '7.5', baseH: 'Y', mV: '5', mC: '12' },
    { numH: '10', baseH: 'Y', mV: '5', mC: '2' },
    { numH: '10', baseH: 'Y', mV: '5', mC: '4' },
    { numH: '10', baseH: 'Y', mV: '5', mC: '6' },
    { numH: '10', baseH: 'Y', mV: '5', mC: '8' },
    { numH: '10', baseH: 'Y', mV: '5', mC: '10' },
    { numH: '10', baseH: 'Y', mV: '5', mC: '12' },
    { numH: '2.5', baseH: 'GY', mV: '5', mC: '2' },
    { numH: '2.5', baseH: 'GY', mV: '5', mC: '4' },
    { numH: '2.5', baseH: 'GY', mV: '5', mC: '6' },
    { numH: '2.5', baseH: 'GY', mV: '5', mC: '8' },
    { numH: '2.5', baseH: 'GY', mV: '5', mC: '10' },
    { numH: '2.5', baseH: 'GY', mV: '5', mC: '12' },
    { numH: '5', baseH: 'GY', mV: '5', mC: '2' },
    { numH: '5', baseH: 'GY', mV: '5', mC: '4' },
    { numH: '5', baseH: 'GY', mV: '5', mC: '6' },
    { numH: '5', baseH: 'GY', mV: '5', mC: '8' },
    { numH: '5', baseH: 'GY', mV: '5', mC: '10' },
    { numH: '5', baseH: 'GY', mV: '5', mC: '12' },
    { numH: '7.5', baseH: 'GY', mV: '5', mC: '2' },
    { numH: '7.5', baseH: 'GY', mV: '5', mC: '4' },
    { numH: '7.5', baseH: 'GY', mV: '5', mC: '6' },
    { numH: '7.5', baseH: 'GY', mV: '5', mC: '8' },
    { numH: '7.5', baseH: 'GY', mV: '5', mC: '10' },
    { numH: '7.5', baseH: 'GY', mV: '5', mC: '12' },
    { numH: '7.5', baseH: 'GY', mV: '5', mC: '14' },
    { numH: '10', baseH: 'GY', mV: '5', mC: '2' },
    { numH: '10', baseH: 'GY', mV: '5', mC: '4' },
    { numH: '10', baseH: 'GY', mV: '5', mC: '6' },
    { numH: '10', baseH: 'GY', mV: '5', mC: '8' },
    { numH: '10', baseH: 'GY', mV: '5', mC: '10' },
    { numH: '10', baseH: 'GY', mV: '5', mC: '12' },
    { numH: '10', baseH: 'GY', mV: '5', mC: '14' },
    { numH: '10', baseH: 'GY', mV: '5', mC: '16' },
    { numH: '10', baseH: 'GY', mV: '5', mC: '18' },
    { numH: '2.5', baseH: 'G', mV: '5', mC: '2' },
    { numH: '2.5', baseH: 'G', mV: '5', mC: '4' },
    { numH: '2.5', baseH: 'G', mV: '5', mC: '6' },
    { numH: '2.5', baseH: 'G', mV: '5', mC: '8' },
    { numH: '2.5', baseH: 'G', mV: '5', mC: '10' },
    { numH: '2.5', baseH: 'G', mV: '5', mC: '12' },
    { numH: '2.5', baseH: 'G', mV: '5', mC: '14' },
    { numH: '2.5', baseH: 'G', mV: '5', mC: '16' },
    { numH: '2.5', baseH: 'G', mV: '5', mC: '18' },
    { numH: '2.5', baseH: 'G', mV: '5', mC: '20' },
    { numH: '2.5', baseH: 'G', mV: '5', mC: '22' },
    { numH: '2.5', baseH: 'G', mV: '5', mC: '24' },
    { numH: '2.5', baseH: 'G', mV: '5', mC: '26' },
    { numH: '2.5', baseH: 'G', mV: '5', mC: '28' },
    { numH: '5', baseH: 'G', mV: '5', mC: '2' },
    { numH: '5', baseH: 'G', mV: '5', mC: '4' },
    { numH: '5', baseH: 'G', mV: '5', mC: '6' },
    { numH: '5', baseH: 'G', mV: '5', mC: '8' },
    { numH: '5', baseH: 'G', mV: '5', mC: '10' },
    { numH: '5', baseH: 'G', mV: '5', mC: '12' },
    { numH: '5', baseH: 'G', mV: '5', mC: '14' },
    { numH: '5', baseH: 'G', mV: '5', mC: '16' },
    { numH: '5', baseH: 'G', mV: '5', mC: '18' },
    { numH: '5', baseH: 'G', mV: '5', mC: '20' },
    { numH: '5', baseH: 'G', mV: '5', mC: '22' },
    { numH: '5', baseH: 'G', mV: '5', mC: '24' },
    { numH: '5', baseH: 'G', mV: '5', mC: '26' },
    { numH: '5', baseH: 'G', mV: '5', mC: '28' },
    { numH: '7.5', baseH: 'G', mV: '5', mC: '2' },
    { numH: '7.5', baseH: 'G', mV: '5', mC: '4' },
    { numH: '7.5', baseH: 'G', mV: '5', mC: '6' },
    { numH: '7.5', baseH: 'G', mV: '5', mC: '8' },
    { numH: '7.5', baseH: 'G', mV: '5', mC: '10' },
    { numH: '7.5', baseH: 'G', mV: '5', mC: '12' },
    { numH: '7.5', baseH: 'G', mV: '5', mC: '14' },
    { numH: '7.5', baseH: 'G', mV: '5', mC: '16' },
    { numH: '7.5', baseH: 'G', mV: '5', mC: '18' },
    { numH: '7.5', baseH: 'G', mV: '5', mC: '20' },
    { numH: '7.5', baseH: 'G', mV: '5', mC: '22' },
    { numH: '7.5', baseH: 'G', mV: '5', mC: '24' },
    { numH: '7.5', baseH: 'G', mV: '5', mC: '26' },
    { numH: '7.5', baseH: 'G', mV: '5', mC: '28' },
    { numH: '10', baseH: 'G', mV: '5', mC: '2' },
    { numH: '10', baseH: 'G', mV: '5', mC: '4' },
    { numH: '10', baseH: 'G', mV: '5', mC: '6' },
    { numH: '10', baseH: 'G', mV: '5', mC: '8' },
    { numH: '10', baseH: 'G', mV: '5', mC: '10' },
    { numH: '10', baseH: 'G', mV: '5', mC: '12' },
    { numH: '10', baseH: 'G', mV: '5', mC: '14' },
    { numH: '10', baseH: 'G', mV: '5', mC: '16' },
    { numH: '10', baseH: 'G', mV: '5', mC: '18' },
    { numH: '10', baseH: 'G', mV: '5', mC: '20' },
    { numH: '10', baseH: 'G', mV: '5', mC: '22' },
    { numH: '10', baseH: 'G', mV: '5', mC: '24' },
    { numH: '10', baseH: 'G', mV: '5', mC: '26' },
    { numH: '10', baseH: 'G', mV: '5', mC: '28' },
    { numH: '2.5', baseH: 'BG', mV: '5', mC: '2' },
    { numH: '2.5', baseH: 'BG', mV: '5', mC: '4' },
    { numH: '2.5', baseH: 'BG', mV: '5', mC: '6' },
    { numH: '2.5', baseH: 'BG', mV: '5', mC: '8' },
    { numH: '2.5', baseH: 'BG', mV: '5', mC: '10' },
    { numH: '2.5', baseH: 'BG', mV: '5', mC: '12' },
    { numH: '2.5', baseH: 'BG', mV: '5', mC: '14' },
    { numH: '2.5', baseH: 'BG', mV: '5', mC: '16' },
    { numH: '2.5', baseH: 'BG', mV: '5', mC: '18' },
    { numH: '2.5', baseH: 'BG', mV: '5', mC: '20' },
    { numH: '2.5', baseH: 'BG', mV: '5', mC: '22' },
    { numH: '2.5', baseH: 'BG', mV: '5', mC: '24' },
    { numH: '5', baseH: 'BG', mV: '5', mC: '2' },
    { numH: '5', baseH: 'BG', mV: '5', mC: '4' },
    { numH: '5', baseH: 'BG', mV: '5', mC: '6' },
    { numH: '5', baseH: 'BG', mV: '5', mC: '8' },
    { numH: '5', baseH: 'BG', mV: '5', mC: '10' },
    { numH: '5', baseH: 'BG', mV: '5', mC: '12' },
    { numH: '5', baseH: 'BG', mV: '5', mC: '14' },
    { numH: '5', baseH: 'BG', mV: '5', mC: '16' },
    { numH: '5', baseH: 'BG', mV: '5', mC: '18' },
    { numH: '5', baseH: 'BG', mV: '5', mC: '20' },
    { numH: '5', baseH: 'BG', mV: '5', mC: '22' },
    { numH: '7.5', baseH: 'BG', mV: '5', mC: '2' },
    { numH: '7.5', baseH: 'BG', mV: '5', mC: '4' },
    { numH: '7.5', baseH: 'BG', mV: '5', mC: '6' },
    { numH: '7.5', baseH: 'BG', mV: '5', mC: '8' },
    { numH: '7.5', baseH: 'BG', mV: '5', mC: '10' },
    { numH: '7.5', baseH: 'BG', mV: '5', mC: '12' },
    { numH: '7.5', baseH: 'BG', mV: '5', mC: '14' },
    { numH: '7.5', baseH: 'BG', mV: '5', mC: '16' },
    { numH: '7.5', baseH: 'BG', mV: '5', mC: '18' },
    { numH: '10', baseH: 'BG', mV: '5', mC: '2' },
    { numH: '10', baseH: 'BG', mV: '5', mC: '4' },
    { numH: '10', baseH: 'BG', mV: '5', mC: '6' },
    { numH: '10', baseH: 'BG', mV: '5', mC: '8' },
    { numH: '10', baseH: 'BG', mV: '5', mC: '10' },
    { numH: '10', baseH: 'BG', mV: '5', mC: '12' },
    { numH: '10', baseH: 'BG', mV: '5', mC: '14' },
    { numH: '10', baseH: 'BG', mV: '5', mC: '16' },
    { numH: '2.5', baseH: 'B', mV: '5', mC: '2' },
    { numH: '2.5', baseH: 'B', mV: '5', mC: '4' },
    { numH: '2.5', baseH: 'B', mV: '5', mC: '6' },
    { numH: '2.5', baseH: 'B', mV: '5', mC: '8' },
    { numH: '2.5', baseH: 'B', mV: '5', mC: '10' },
    { numH: '2.5', baseH: 'B', mV: '5', mC: '12' },
    { numH: '2.5', baseH: 'B', mV: '5', mC: '14' },
    { numH: '2.5', baseH: 'B', mV: '5', mC: '16' },
    { numH: '5', baseH: 'B', mV: '5', mC: '2' },
    { numH: '5', baseH: 'B', mV: '5', mC: '4' },
    { numH: '5', baseH: 'B', mV: '5', mC: '6' },
    { numH: '5', baseH: 'B', mV: '5', mC: '8' },
    { numH: '5', baseH: 'B', mV: '5', mC: '10' },
    { numH: '5', baseH: 'B', mV: '5', mC: '12' },
    { numH: '5', baseH: 'B', mV: '5', mC: '14' },
    { numH: '5', baseH: 'B', mV: '5', mC: '16' },
    { numH: '7.5', baseH: 'B', mV: '5', mC: '2' },
    { numH: '7.5', baseH: 'B', mV: '5', mC: '4' },
    { numH: '7.5', baseH: 'B', mV: '5', mC: '6' },
    { numH: '7.5', baseH: 'B', mV: '5', mC: '8' },
    { numH: '7.5', baseH: 'B', mV: '5', mC: '10' },
    { numH: '7.5', baseH: 'B', mV: '5', mC: '12' },
    { numH: '7.5', baseH: 'B', mV: '5', mC: '14' },
    { numH: '7.5', baseH: 'B', mV: '5', mC: '16' },
    { numH: '10', baseH: 'B', mV: '5', mC: '2' },
    { numH: '10', baseH: 'B', mV: '5', mC: '4' },
    { numH: '10', baseH: 'B', mV: '5', mC: '6' },
    { numH: '10', baseH: 'B', mV: '5', mC: '8' },
    { numH: '10', baseH: 'B', mV: '5', mC: '10' },
    { numH: '10', baseH: 'B', mV: '5', mC: '12' },
    { numH: '10', baseH: 'B', mV: '5', mC: '14' },
    { numH: '10', baseH: 'B', mV: '5', mC: '16' },
    { numH: '10', baseH: 'B', mV: '5', mC: '18' },
    { numH: '2.5', baseH: 'PB', mV: '5', mC: '2' },
    { numH: '2.5', baseH: 'PB', mV: '5', mC: '4' },
    { numH: '2.5', baseH: 'PB', mV: '5', mC: '6' },
    { numH: '2.5', baseH: 'PB', mV: '5', mC: '8' },
    { numH: '2.5', baseH: 'PB', mV: '5', mC: '10' },
    { numH: '2.5', baseH: 'PB', mV: '5', mC: '12' },
    { numH: '2.5', baseH: 'PB', mV: '5', mC: '14' },
    { numH: '2.5', baseH: 'PB', mV: '5', mC: '16' },
    { numH: '2.5', baseH: 'PB', mV: '5', mC: '18' },
    { numH: '5', baseH: 'PB', mV: '5', mC: '2' },
    { numH: '5', baseH: 'PB', mV: '5', mC: '4' },
    { numH: '5', baseH: 'PB', mV: '5', mC: '6' },
    { numH: '5', baseH: 'PB', mV: '5', mC: '8' },
    { numH: '5', baseH: 'PB', mV: '5', mC: '10' },
    { numH: '5', baseH: 'PB', mV: '5', mC: '12' },
    { numH: '5', baseH: 'PB', mV: '5', mC: '14' },
    { numH: '5', baseH: 'PB', mV: '5', mC: '16' },
    { numH: '5', baseH: 'PB', mV: '5', mC: '18' },
    { numH: '7.5', baseH: 'PB', mV: '5', mC: '2' },
    { numH: '7.5', baseH: 'PB', mV: '5', mC: '4' },
    { numH: '7.5', baseH: 'PB', mV: '5', mC: '6' },
    { numH: '7.5', baseH: 'PB', mV: '5', mC: '8' },
    { numH: '7.5', baseH: 'PB', mV: '5', mC: '10' },
    { numH: '7.5', baseH: 'PB', mV: '5', mC: '12' },
    { numH: '7.5', baseH: 'PB', mV: '5', mC: '14' },
    { numH: '7.5', baseH: 'PB', mV: '5', mC: '16' },
    { numH: '7.5', baseH: 'PB', mV: '5', mC: '18' },
    { numH: '7.5', baseH: 'PB', mV: '5', mC: '20' },
    { numH: '10', baseH: 'PB', mV: '5', mC: '2' },
    { numH: '10', baseH: 'PB', mV: '5', mC: '4' },
    { numH: '10', baseH: 'PB', mV: '5', mC: '6' },
    { numH: '10', baseH: 'PB', mV: '5', mC: '8' },
    { numH: '10', baseH: 'PB', mV: '5', mC: '10' },
    { numH: '10', baseH: 'PB', mV: '5', mC: '12' },
    { numH: '10', baseH: 'PB', mV: '5', mC: '14' },
    { numH: '10', baseH: 'PB', mV: '5', mC: '16' },
    { numH: '10', baseH: 'PB', mV: '5', mC: '18' },
    { numH: '10', baseH: 'PB', mV: '5', mC: '20' },
    { numH: '10', baseH: 'PB', mV: '5', mC: '22' },
    { numH: '2.5', baseH: 'P', mV: '5', mC: '2' },
    { numH: '2.5', baseH: 'P', mV: '5', mC: '4' },
    { numH: '2.5', baseH: 'P', mV: '5', mC: '6' },
    { numH: '2.5', baseH: 'P', mV: '5', mC: '8' },
    { numH: '2.5', baseH: 'P', mV: '5', mC: '10' },
    { numH: '2.5', baseH: 'P', mV: '5', mC: '12' },
    { numH: '2.5', baseH: 'P', mV: '5', mC: '14' },
    { numH: '2.5', baseH: 'P', mV: '5', mC: '16' },
    { numH: '2.5', baseH: 'P', mV: '5', mC: '18' },
    { numH: '2.5', baseH: 'P', mV: '5', mC: '20' },
    { numH: '2.5', baseH: 'P', mV: '5', mC: '22' },
    { numH: '2.5', baseH: 'P', mV: '5', mC: '24' },
    { numH: '2.5', baseH: 'P', mV: '5', mC: '26' },
    { numH: '5', baseH: 'P', mV: '5', mC: '2' },
    { numH: '5', baseH: 'P', mV: '5', mC: '4' },
    { numH: '5', baseH: 'P', mV: '5', mC: '6' },
    { numH: '5', baseH: 'P', mV: '5', mC: '8' },
    { numH: '5', baseH: 'P', mV: '5', mC: '10' },
    { numH: '5', baseH: 'P', mV: '5', mC: '12' },
    { numH: '5', baseH: 'P', mV: '5', mC: '14' },
    { numH: '5', baseH: 'P', mV: '5', mC: '16' },
    { numH: '5', baseH: 'P', mV: '5', mC: '18' },
    { numH: '5', baseH: 'P', mV: '5', mC: '20' },
    { numH: '5', baseH: 'P', mV: '5', mC: '22' },
    { numH: '5', baseH: 'P', mV: '5', mC: '24' },
    { numH: '5', baseH: 'P', mV: '5', mC: '26' },
    { numH: '5', baseH: 'P', mV: '5', mC: '28' },
    { numH: '7.5', baseH: 'P', mV: '5', mC: '2' },
    { numH: '7.5', baseH: 'P', mV: '5', mC: '4' },
    { numH: '7.5', baseH: 'P', mV: '5', mC: '6' },
    { numH: '7.5', baseH: 'P', mV: '5', mC: '8' },
    { numH: '7.5', baseH: 'P', mV: '5', mC: '10' },
    { numH: '7.5', baseH: 'P', mV: '5', mC: '12' },
    { numH: '7.5', baseH: 'P', mV: '5', mC: '14' },
    { numH: '7.5', baseH: 'P', mV: '5', mC: '16' },
    { numH: '7.5', baseH: 'P', mV: '5', mC: '18' },
    { numH: '7.5', baseH: 'P', mV: '5', mC: '20' },
    { numH: '7.5', baseH: 'P', mV: '5', mC: '22' },
    { numH: '7.5', baseH: 'P', mV: '5', mC: '24' },
    { numH: '7.5', baseH: 'P', mV: '5', mC: '26' },
    { numH: '7.5', baseH: 'P', mV: '5', mC: '28' },
    { numH: '7.5', baseH: 'P', mV: '5', mC: '30' },
    { numH: '10', baseH: 'P', mV: '5', mC: '2' },
    { numH: '10', baseH: 'P', mV: '5', mC: '4' },
    { numH: '10', baseH: 'P', mV: '5', mC: '6' },
    { numH: '10', baseH: 'P', mV: '5', mC: '8' },
    { numH: '10', baseH: 'P', mV: '5', mC: '10' },
    { numH: '10', baseH: 'P', mV: '5', mC: '12' },
    { numH: '10', baseH: 'P', mV: '5', mC: '14' },
    { numH: '10', baseH: 'P', mV: '5', mC: '16' },
    { numH: '10', baseH: 'P', mV: '5', mC: '18' },
    { numH: '10', baseH: 'P', mV: '5', mC: '20' },
    { numH: '10', baseH: 'P', mV: '5', mC: '22' },
    { numH: '10', baseH: 'P', mV: '5', mC: '24' },
    { numH: '10', baseH: 'P', mV: '5', mC: '26' },
    { numH: '10', baseH: 'P', mV: '5', mC: '28' },
    { numH: '10', baseH: 'P', mV: '5', mC: '30' },
    { numH: '2.5', baseH: 'RP', mV: '5', mC: '2' },
    { numH: '2.5', baseH: 'RP', mV: '5', mC: '4' },
    { numH: '2.5', baseH: 'RP', mV: '5', mC: '6' },
    { numH: '2.5', baseH: 'RP', mV: '5', mC: '8' },
    { numH: '2.5', baseH: 'RP', mV: '5', mC: '10' },
    { numH: '2.5', baseH: 'RP', mV: '5', mC: '12' },
    { numH: '2.5', baseH: 'RP', mV: '5', mC: '14' },
    { numH: '2.5', baseH: 'RP', mV: '5', mC: '16' },
    { numH: '2.5', baseH: 'RP', mV: '5', mC: '18' },
    { numH: '2.5', baseH: 'RP', mV: '5', mC: '20' },
    { numH: '2.5', baseH: 'RP', mV: '5', mC: '22' },
    { numH: '2.5', baseH: 'RP', mV: '5', mC: '24' },
    { numH: '2.5', baseH: 'RP', mV: '5', mC: '26' },
    { numH: '5', baseH: 'RP', mV: '5', mC: '2' },
    { numH: '5', baseH: 'RP', mV: '5', mC: '4' },
    { numH: '5', baseH: 'RP', mV: '5', mC: '6' },
    { numH: '5', baseH: 'RP', mV: '5', mC: '8' },
    { numH: '5', baseH: 'RP', mV: '5', mC: '10' },
    { numH: '5', baseH: 'RP', mV: '5', mC: '12' },
    { numH: '5', baseH: 'RP', mV: '5', mC: '14' },
    { numH: '5', baseH: 'RP', mV: '5', mC: '16' },
    { numH: '5', baseH: 'RP', mV: '5', mC: '18' },
    { numH: '5', baseH: 'RP', mV: '5', mC: '20' },
    { numH: '5', baseH: 'RP', mV: '5', mC: '22' },
    { numH: '5', baseH: 'RP', mV: '5', mC: '24' },
    { numH: '7.5', baseH: 'RP', mV: '5', mC: '2' },
    { numH: '7.5', baseH: 'RP', mV: '5', mC: '4' },
    { numH: '7.5', baseH: 'RP', mV: '5', mC: '6' },
    { numH: '7.5', baseH: 'RP', mV: '5', mC: '8' },
    { numH: '7.5', baseH: 'RP', mV: '5', mC: '10' },
    { numH: '7.5', baseH: 'RP', mV: '5', mC: '12' },
    { numH: '7.5', baseH: 'RP', mV: '5', mC: '14' },
    { numH: '7.5', baseH: 'RP', mV: '5', mC: '16' },
    { numH: '7.5', baseH: 'RP', mV: '5', mC: '18' },
    { numH: '7.5', baseH: 'RP', mV: '5', mC: '20' },
    { numH: '7.5', baseH: 'RP', mV: '5', mC: '22' },
    { numH: '10', baseH: 'RP', mV: '6', mC: '2' },
    { numH: '10', baseH: 'RP', mV: '6', mC: '4' },
    { numH: '10', baseH: 'RP', mV: '6', mC: '6' },
    { numH: '10', baseH: 'RP', mV: '6', mC: '8' },
    { numH: '10', baseH: 'RP', mV: '6', mC: '10' },
    { numH: '10', baseH: 'RP', mV: '6', mC: '12' },
    { numH: '10', baseH: 'RP', mV: '6', mC: '14' },
    { numH: '10', baseH: 'RP', mV: '6', mC: '16' },
    { numH: '10', baseH: 'RP', mV: '6', mC: '18' },
    { numH: '2.5', baseH: 'R', mV: '6', mC: '2' },
    { numH: '2.5', baseH: 'R', mV: '6', mC: '4' },
    { numH: '2.5', baseH: 'R', mV: '6', mC: '6' },
    { numH: '2.5', baseH: 'R', mV: '6', mC: '8' },
    { numH: '2.5', baseH: 'R', mV: '6', mC: '10' },
    { numH: '2.5', baseH: 'R', mV: '6', mC: '12' },
    { numH: '2.5', baseH: 'R', mV: '6', mC: '14' },
    { numH: '2.5', baseH: 'R', mV: '6', mC: '16' },
    { numH: '2.5', baseH: 'R', mV: '6', mC: '18' },
    { numH: '5', baseH: 'R', mV: '6', mC: '2' },
    { numH: '5', baseH: 'R', mV: '6', mC: '4' },
    { numH: '5', baseH: 'R', mV: '6', mC: '6' },
    { numH: '5', baseH: 'R', mV: '6', mC: '8' },
    { numH: '5', baseH: 'R', mV: '6', mC: '10' },
    { numH: '5', baseH: 'R', mV: '6', mC: '12' },
    { numH: '5', baseH: 'R', mV: '6', mC: '14' },
    { numH: '5', baseH: 'R', mV: '6', mC: '16' },
    { numH: '5', baseH: 'R', mV: '6', mC: '18' },
    { numH: '7.5', baseH: 'R', mV: '6', mC: '2' },
    { numH: '7.5', baseH: 'R', mV: '6', mC: '4' },
    { numH: '7.5', baseH: 'R', mV: '6', mC: '6' },
    { numH: '7.5', baseH: 'R', mV: '6', mC: '8' },
    { numH: '7.5', baseH: 'R', mV: '6', mC: '10' },
    { numH: '7.5', baseH: 'R', mV: '6', mC: '12' },
    { numH: '7.5', baseH: 'R', mV: '6', mC: '14' },
    { numH: '7.5', baseH: 'R', mV: '6', mC: '16' },
    { numH: '7.5', baseH: 'R', mV: '6', mC: '18' },
    { numH: '10', baseH: 'R', mV: '6', mC: '2' },
    { numH: '10', baseH: 'R', mV: '6', mC: '4' },
    { numH: '10', baseH: 'R', mV: '6', mC: '6' },
    { numH: '10', baseH: 'R', mV: '6', mC: '8' },
    { numH: '10', baseH: 'R', mV: '6', mC: '10' },
    { numH: '10', baseH: 'R', mV: '6', mC: '12' },
    { numH: '10', baseH: 'R', mV: '6', mC: '14' },
    { numH: '10', baseH: 'R', mV: '6', mC: '16' },
    { numH: '10', baseH: 'R', mV: '6', mC: '18' },
    { numH: '2.5', baseH: 'YR', mV: '6', mC: '2' },
    { numH: '2.5', baseH: 'YR', mV: '6', mC: '4' },
    { numH: '2.5', baseH: 'YR', mV: '6', mC: '6' },
    { numH: '2.5', baseH: 'YR', mV: '6', mC: '8' },
    { numH: '2.5', baseH: 'YR', mV: '6', mC: '10' },
    { numH: '2.5', baseH: 'YR', mV: '6', mC: '12' },
    { numH: '2.5', baseH: 'YR', mV: '6', mC: '14' },
    { numH: '2.5', baseH: 'YR', mV: '6', mC: '16' },
    { numH: '2.5', baseH: 'YR', mV: '6', mC: '18' },
    { numH: '5', baseH: 'YR', mV: '6', mC: '2' },
    { numH: '5', baseH: 'YR', mV: '6', mC: '4' },
    { numH: '5', baseH: 'YR', mV: '6', mC: '6' },
    { numH: '5', baseH: 'YR', mV: '6', mC: '8' },
    { numH: '5', baseH: 'YR', mV: '6', mC: '10' },
    { numH: '5', baseH: 'YR', mV: '6', mC: '12' },
    { numH: '5', baseH: 'YR', mV: '6', mC: '14' },
    { numH: '5', baseH: 'YR', mV: '6', mC: '16' },
    { numH: '5', baseH: 'YR', mV: '6', mC: '18' },
    { numH: '7.5', baseH: 'YR', mV: '6', mC: '2' },
    { numH: '7.5', baseH: 'YR', mV: '6', mC: '4' },
    { numH: '7.5', baseH: 'YR', mV: '6', mC: '6' },
    { numH: '7.5', baseH: 'YR', mV: '6', mC: '8' },
    { numH: '7.5', baseH: 'YR', mV: '6', mC: '10' },
    { numH: '7.5', baseH: 'YR', mV: '6', mC: '12' },
    { numH: '7.5', baseH: 'YR', mV: '6', mC: '14' },
    { numH: '7.5', baseH: 'YR', mV: '6', mC: '16' },
    { numH: '10', baseH: 'YR', mV: '6', mC: '2' },
    { numH: '10', baseH: 'YR', mV: '6', mC: '4' },
    { numH: '10', baseH: 'YR', mV: '6', mC: '6' },
    { numH: '10', baseH: 'YR', mV: '6', mC: '8' },
    { numH: '10', baseH: 'YR', mV: '6', mC: '10' },
    { numH: '10', baseH: 'YR', mV: '6', mC: '12' },
    { numH: '10', baseH: 'YR', mV: '6', mC: '14' },
    { numH: '2.5', baseH: 'Y', mV: '6', mC: '2' },
    { numH: '2.5', baseH: 'Y', mV: '6', mC: '4' },
    { numH: '2.5', baseH: 'Y', mV: '6', mC: '6' },
    { numH: '2.5', baseH: 'Y', mV: '6', mC: '8' },
    { numH: '2.5', baseH: 'Y', mV: '6', mC: '10' },
    { numH: '2.5', baseH: 'Y', mV: '6', mC: '12' },
    { numH: '2.5', baseH: 'Y', mV: '6', mC: '14' },
    { numH: '5', baseH: 'Y', mV: '6', mC: '2' },
    { numH: '5', baseH: 'Y', mV: '6', mC: '4' },
    { numH: '5', baseH: 'Y', mV: '6', mC: '6' },
    { numH: '5', baseH: 'Y', mV: '6', mC: '8' },
    { numH: '5', baseH: 'Y', mV: '6', mC: '10' },
    { numH: '5', baseH: 'Y', mV: '6', mC: '12' },
    { numH: '5', baseH: 'Y', mV: '6', mC: '14' },
    { numH: '7.5', baseH: 'Y', mV: '6', mC: '2' },
    { numH: '7.5', baseH: 'Y', mV: '6', mC: '4' },
    { numH: '7.5', baseH: 'Y', mV: '6', mC: '6' },
    { numH: '7.5', baseH: 'Y', mV: '6', mC: '8' },
    { numH: '7.5', baseH: 'Y', mV: '6', mC: '10' },
    { numH: '7.5', baseH: 'Y', mV: '6', mC: '12' },
    { numH: '7.5', baseH: 'Y', mV: '6', mC: '14' },
    { numH: '10', baseH: 'Y', mV: '6', mC: '2' },
    { numH: '10', baseH: 'Y', mV: '6', mC: '4' },
    { numH: '10', baseH: 'Y', mV: '6', mC: '6' },
    { numH: '10', baseH: 'Y', mV: '6', mC: '8' },
    { numH: '10', baseH: 'Y', mV: '6', mC: '10' },
    { numH: '10', baseH: 'Y', mV: '6', mC: '12' },
    { numH: '10', baseH: 'Y', mV: '6', mC: '14' },
    { numH: '2.5', baseH: 'GY', mV: '6', mC: '2' },
    { numH: '2.5', baseH: 'GY', mV: '6', mC: '4' },
    { numH: '2.5', baseH: 'GY', mV: '6', mC: '6' },
    { numH: '2.5', baseH: 'GY', mV: '6', mC: '8' },
    { numH: '2.5', baseH: 'GY', mV: '6', mC: '10' },
    { numH: '2.5', baseH: 'GY', mV: '6', mC: '12' },
    { numH: '2.5', baseH: 'GY', mV: '6', mC: '14' },
    { numH: '5', baseH: 'GY', mV: '6', mC: '2' },
    { numH: '5', baseH: 'GY', mV: '6', mC: '4' },
    { numH: '5', baseH: 'GY', mV: '6', mC: '6' },
    { numH: '5', baseH: 'GY', mV: '6', mC: '8' },
    { numH: '5', baseH: 'GY', mV: '6', mC: '10' },
    { numH: '5', baseH: 'GY', mV: '6', mC: '12' },
    { numH: '5', baseH: 'GY', mV: '6', mC: '14' },
    { numH: '7.5', baseH: 'GY', mV: '6', mC: '2' },
    { numH: '7.5', baseH: 'GY', mV: '6', mC: '4' },
    { numH: '7.5', baseH: 'GY', mV: '6', mC: '6' },
    { numH: '7.5', baseH: 'GY', mV: '6', mC: '8' },
    { numH: '7.5', baseH: 'GY', mV: '6', mC: '10' },
    { numH: '7.5', baseH: 'GY', mV: '6', mC: '12' },
    { numH: '7.5', baseH: 'GY', mV: '6', mC: '14' },
    { numH: '7.5', baseH: 'GY', mV: '6', mC: '16' },
    { numH: '10', baseH: 'GY', mV: '6', mC: '2' },
    { numH: '10', baseH: 'GY', mV: '6', mC: '4' },
    { numH: '10', baseH: 'GY', mV: '6', mC: '6' },
    { numH: '10', baseH: 'GY', mV: '6', mC: '8' },
    { numH: '10', baseH: 'GY', mV: '6', mC: '10' },
    { numH: '10', baseH: 'GY', mV: '6', mC: '12' },
    { numH: '10', baseH: 'GY', mV: '6', mC: '14' },
    { numH: '10', baseH: 'GY', mV: '6', mC: '16' },
    { numH: '10', baseH: 'GY', mV: '6', mC: '18' },
    { numH: '10', baseH: 'GY', mV: '6', mC: '20' },
    { numH: '2.5', baseH: 'G', mV: '6', mC: '2' },
    { numH: '2.5', baseH: 'G', mV: '6', mC: '4' },
    { numH: '2.5', baseH: 'G', mV: '6', mC: '6' },
    { numH: '2.5', baseH: 'G', mV: '6', mC: '8' },
    { numH: '2.5', baseH: 'G', mV: '6', mC: '10' },
    { numH: '2.5', baseH: 'G', mV: '6', mC: '12' },
    { numH: '2.5', baseH: 'G', mV: '6', mC: '14' },
    { numH: '2.5', baseH: 'G', mV: '6', mC: '16' },
    { numH: '2.5', baseH: 'G', mV: '6', mC: '18' },
    { numH: '2.5', baseH: 'G', mV: '6', mC: '20' },
    { numH: '2.5', baseH: 'G', mV: '6', mC: '22' },
    { numH: '2.5', baseH: 'G', mV: '6', mC: '24' },
    { numH: '2.5', baseH: 'G', mV: '6', mC: '26' },
    { numH: '2.5', baseH: 'G', mV: '6', mC: '28' },
    { numH: '5', baseH: 'G', mV: '6', mC: '2' },
    { numH: '5', baseH: 'G', mV: '6', mC: '4' },
    { numH: '5', baseH: 'G', mV: '6', mC: '6' },
    { numH: '5', baseH: 'G', mV: '6', mC: '8' },
    { numH: '5', baseH: 'G', mV: '6', mC: '10' },
    { numH: '5', baseH: 'G', mV: '6', mC: '12' },
    { numH: '5', baseH: 'G', mV: '6', mC: '14' },
    { numH: '5', baseH: 'G', mV: '6', mC: '16' },
    { numH: '5', baseH: 'G', mV: '6', mC: '18' },
    { numH: '5', baseH: 'G', mV: '6', mC: '20' },
    { numH: '5', baseH: 'G', mV: '6', mC: '22' },
    { numH: '5', baseH: 'G', mV: '6', mC: '24' },
    { numH: '5', baseH: 'G', mV: '6', mC: '26' },
    { numH: '5', baseH: 'G', mV: '6', mC: '28' },
    { numH: '7.5', baseH: 'G', mV: '6', mC: '2' },
    { numH: '7.5', baseH: 'G', mV: '6', mC: '4' },
    { numH: '7.5', baseH: 'G', mV: '6', mC: '6' },
    { numH: '7.5', baseH: 'G', mV: '6', mC: '8' },
    { numH: '7.5', baseH: 'G', mV: '6', mC: '10' },
    { numH: '7.5', baseH: 'G', mV: '6', mC: '12' },
    { numH: '7.5', baseH: 'G', mV: '6', mC: '14' },
    { numH: '7.5', baseH: 'G', mV: '6', mC: '16' },
    { numH: '7.5', baseH: 'G', mV: '6', mC: '18' },
    { numH: '7.5', baseH: 'G', mV: '6', mC: '20' },
    { numH: '7.5', baseH: 'G', mV: '6', mC: '22' },
    { numH: '7.5', baseH: 'G', mV: '6', mC: '24' },
    { numH: '7.5', baseH: 'G', mV: '6', mC: '26' },
    { numH: '7.5', baseH: 'G', mV: '6', mC: '28' },
    { numH: '10', baseH: 'G', mV: '6', mC: '2' },
    { numH: '10', baseH: 'G', mV: '6', mC: '4' },
    { numH: '10', baseH: 'G', mV: '6', mC: '6' },
    { numH: '10', baseH: 'G', mV: '6', mC: '8' },
    { numH: '10', baseH: 'G', mV: '6', mC: '10' },
    { numH: '10', baseH: 'G', mV: '6', mC: '12' },
    { numH: '10', baseH: 'G', mV: '6', mC: '14' },
    { numH: '10', baseH: 'G', mV: '6', mC: '16' },
    { numH: '10', baseH: 'G', mV: '6', mC: '18' },
    { numH: '10', baseH: 'G', mV: '6', mC: '20' },
    { numH: '10', baseH: 'G', mV: '6', mC: '22' },
    { numH: '10', baseH: 'G', mV: '6', mC: '24' },
    { numH: '10', baseH: 'G', mV: '6', mC: '26' },
    { numH: '2.5', baseH: 'BG', mV: '6', mC: '2' },
    { numH: '2.5', baseH: 'BG', mV: '6', mC: '4' },
    { numH: '2.5', baseH: 'BG', mV: '6', mC: '6' },
    { numH: '2.5', baseH: 'BG', mV: '6', mC: '8' },
    { numH: '2.5', baseH: 'BG', mV: '6', mC: '10' },
    { numH: '2.5', baseH: 'BG', mV: '6', mC: '12' },
    { numH: '2.5', baseH: 'BG', mV: '6', mC: '14' },
    { numH: '2.5', baseH: 'BG', mV: '6', mC: '16' },
    { numH: '2.5', baseH: 'BG', mV: '6', mC: '18' },
    { numH: '2.5', baseH: 'BG', mV: '6', mC: '20' },
    { numH: '2.5', baseH: 'BG', mV: '6', mC: '22' },
    { numH: '5', baseH: 'BG', mV: '6', mC: '2' },
    { numH: '5', baseH: 'BG', mV: '6', mC: '4' },
    { numH: '5', baseH: 'BG', mV: '6', mC: '6' },
    { numH: '5', baseH: 'BG', mV: '6', mC: '8' },
    { numH: '5', baseH: 'BG', mV: '6', mC: '10' },
    { numH: '5', baseH: 'BG', mV: '6', mC: '12' },
    { numH: '5', baseH: 'BG', mV: '6', mC: '14' },
    { numH: '5', baseH: 'BG', mV: '6', mC: '16' },
    { numH: '5', baseH: 'BG', mV: '6', mC: '18' },
    { numH: '5', baseH: 'BG', mV: '6', mC: '20' },
    { numH: '7.5', baseH: 'BG', mV: '6', mC: '2' },
    { numH: '7.5', baseH: 'BG', mV: '6', mC: '4' },
    { numH: '7.5', baseH: 'BG', mV: '6', mC: '6' },
    { numH: '7.5', baseH: 'BG', mV: '6', mC: '8' },
    { numH: '7.5', baseH: 'BG', mV: '6', mC: '10' },
    { numH: '7.5', baseH: 'BG', mV: '6', mC: '12' },
    { numH: '7.5', baseH: 'BG', mV: '6', mC: '14' },
    { numH: '7.5', baseH: 'BG', mV: '6', mC: '16' },
    { numH: '7.5', baseH: 'BG', mV: '6', mC: '18' },
    { numH: '10', baseH: 'BG', mV: '6', mC: '2' },
    { numH: '10', baseH: 'BG', mV: '6', mC: '4' },
    { numH: '10', baseH: 'BG', mV: '6', mC: '6' },
    { numH: '10', baseH: 'BG', mV: '6', mC: '8' },
    { numH: '10', baseH: 'BG', mV: '6', mC: '10' },
    { numH: '10', baseH: 'BG', mV: '6', mC: '12' },
    { numH: '10', baseH: 'BG', mV: '6', mC: '14' },
    { numH: '10', baseH: 'BG', mV: '6', mC: '16' },
    { numH: '10', baseH: 'BG', mV: '6', mC: '18' },
    { numH: '2.5', baseH: 'B', mV: '6', mC: '2' },
    { numH: '2.5', baseH: 'B', mV: '6', mC: '4' },
    { numH: '2.5', baseH: 'B', mV: '6', mC: '6' },
    { numH: '2.5', baseH: 'B', mV: '6', mC: '8' },
    { numH: '2.5', baseH: 'B', mV: '6', mC: '10' },
    { numH: '2.5', baseH: 'B', mV: '6', mC: '12' },
    { numH: '2.5', baseH: 'B', mV: '6', mC: '14' },
    { numH: '2.5', baseH: 'B', mV: '6', mC: '16' },
    { numH: '5', baseH: 'B', mV: '6', mC: '2' },
    { numH: '5', baseH: 'B', mV: '6', mC: '4' },
    { numH: '5', baseH: 'B', mV: '6', mC: '6' },
    { numH: '5', baseH: 'B', mV: '6', mC: '8' },
    { numH: '5', baseH: 'B', mV: '6', mC: '10' },
    { numH: '5', baseH: 'B', mV: '6', mC: '12' },
    { numH: '5', baseH: 'B', mV: '6', mC: '14' },
    { numH: '5', baseH: 'B', mV: '6', mC: '16' },
    { numH: '7.5', baseH: 'B', mV: '6', mC: '2' },
    { numH: '7.5', baseH: 'B', mV: '6', mC: '4' },
    { numH: '7.5', baseH: 'B', mV: '6', mC: '6' },
    { numH: '7.5', baseH: 'B', mV: '6', mC: '8' },
    { numH: '7.5', baseH: 'B', mV: '6', mC: '10' },
    { numH: '7.5', baseH: 'B', mV: '6', mC: '12' },
    { numH: '7.5', baseH: 'B', mV: '6', mC: '14' },
    { numH: '7.5', baseH: 'B', mV: '6', mC: '16' },
    { numH: '10', baseH: 'B', mV: '6', mC: '2' },
    { numH: '10', baseH: 'B', mV: '6', mC: '4' },
    { numH: '10', baseH: 'B', mV: '6', mC: '6' },
    { numH: '10', baseH: 'B', mV: '6', mC: '8' },
    { numH: '10', baseH: 'B', mV: '6', mC: '10' },
    { numH: '10', baseH: 'B', mV: '6', mC: '12' },
    { numH: '10', baseH: 'B', mV: '6', mC: '14' },
    { numH: '10', baseH: 'B', mV: '6', mC: '16' },
    { numH: '2.5', baseH: 'PB', mV: '6', mC: '2' },
    { numH: '2.5', baseH: 'PB', mV: '6', mC: '4' },
    { numH: '2.5', baseH: 'PB', mV: '6', mC: '6' },
    { numH: '2.5', baseH: 'PB', mV: '6', mC: '8' },
    { numH: '2.5', baseH: 'PB', mV: '6', mC: '10' },
    { numH: '2.5', baseH: 'PB', mV: '6', mC: '12' },
    { numH: '2.5', baseH: 'PB', mV: '6', mC: '14' },
    { numH: '5', baseH: 'PB', mV: '6', mC: '2' },
    { numH: '5', baseH: 'PB', mV: '6', mC: '4' },
    { numH: '5', baseH: 'PB', mV: '6', mC: '6' },
    { numH: '5', baseH: 'PB', mV: '6', mC: '8' },
    { numH: '5', baseH: 'PB', mV: '6', mC: '10' },
    { numH: '5', baseH: 'PB', mV: '6', mC: '12' },
    { numH: '5', baseH: 'PB', mV: '6', mC: '14' },
    { numH: '7.5', baseH: 'PB', mV: '6', mC: '2' },
    { numH: '7.5', baseH: 'PB', mV: '6', mC: '4' },
    { numH: '7.5', baseH: 'PB', mV: '6', mC: '6' },
    { numH: '7.5', baseH: 'PB', mV: '6', mC: '8' },
    { numH: '7.5', baseH: 'PB', mV: '6', mC: '10' },
    { numH: '7.5', baseH: 'PB', mV: '6', mC: '12' },
    { numH: '7.5', baseH: 'PB', mV: '6', mC: '14' },
    { numH: '10', baseH: 'PB', mV: '6', mC: '2' },
    { numH: '10', baseH: 'PB', mV: '6', mC: '4' },
    { numH: '10', baseH: 'PB', mV: '6', mC: '6' },
    { numH: '10', baseH: 'PB', mV: '6', mC: '8' },
    { numH: '10', baseH: 'PB', mV: '6', mC: '10' },
    { numH: '10', baseH: 'PB', mV: '6', mC: '12' },
    { numH: '10', baseH: 'PB', mV: '6', mC: '14' },
    { numH: '10', baseH: 'PB', mV: '6', mC: '16' },
    { numH: '2.5', baseH: 'P', mV: '6', mC: '2' },
    { numH: '2.5', baseH: 'P', mV: '6', mC: '4' },
    { numH: '2.5', baseH: 'P', mV: '6', mC: '6' },
    { numH: '2.5', baseH: 'P', mV: '6', mC: '8' },
    { numH: '2.5', baseH: 'P', mV: '6', mC: '10' },
    { numH: '2.5', baseH: 'P', mV: '6', mC: '12' },
    { numH: '2.5', baseH: 'P', mV: '6', mC: '14' },
    { numH: '2.5', baseH: 'P', mV: '6', mC: '16' },
    { numH: '2.5', baseH: 'P', mV: '6', mC: '18' },
    { numH: '5', baseH: 'P', mV: '6', mC: '2' },
    { numH: '5', baseH: 'P', mV: '6', mC: '4' },
    { numH: '5', baseH: 'P', mV: '6', mC: '6' },
    { numH: '5', baseH: 'P', mV: '6', mC: '8' },
    { numH: '5', baseH: 'P', mV: '6', mC: '10' },
    { numH: '5', baseH: 'P', mV: '6', mC: '12' },
    { numH: '5', baseH: 'P', mV: '6', mC: '14' },
    { numH: '5', baseH: 'P', mV: '6', mC: '16' },
    { numH: '5', baseH: 'P', mV: '6', mC: '18' },
    { numH: '5', baseH: 'P', mV: '6', mC: '20' },
    { numH: '7.5', baseH: 'P', mV: '6', mC: '2' },
    { numH: '7.5', baseH: 'P', mV: '6', mC: '4' },
    { numH: '7.5', baseH: 'P', mV: '6', mC: '6' },
    { numH: '7.5', baseH: 'P', mV: '6', mC: '8' },
    { numH: '7.5', baseH: 'P', mV: '6', mC: '10' },
    { numH: '7.5', baseH: 'P', mV: '6', mC: '12' },
    { numH: '7.5', baseH: 'P', mV: '6', mC: '14' },
    { numH: '7.5', baseH: 'P', mV: '6', mC: '16' },
    { numH: '7.5', baseH: 'P', mV: '6', mC: '18' },
    { numH: '7.5', baseH: 'P', mV: '6', mC: '20' },
    { numH: '7.5', baseH: 'P', mV: '6', mC: '22' },
    { numH: '7.5', baseH: 'P', mV: '6', mC: '24' },
    { numH: '10', baseH: 'P', mV: '6', mC: '2' },
    { numH: '10', baseH: 'P', mV: '6', mC: '4' },
    { numH: '10', baseH: 'P', mV: '6', mC: '6' },
    { numH: '10', baseH: 'P', mV: '6', mC: '8' },
    { numH: '10', baseH: 'P', mV: '6', mC: '10' },
    { numH: '10', baseH: 'P', mV: '6', mC: '12' },
    { numH: '10', baseH: 'P', mV: '6', mC: '14' },
    { numH: '10', baseH: 'P', mV: '6', mC: '16' },
    { numH: '10', baseH: 'P', mV: '6', mC: '18' },
    { numH: '10', baseH: 'P', mV: '6', mC: '20' },
    { numH: '10', baseH: 'P', mV: '6', mC: '22' },
    { numH: '10', baseH: 'P', mV: '6', mC: '24' },
    { numH: '10', baseH: 'P', mV: '6', mC: '26' },
    { numH: '2.5', baseH: 'RP', mV: '6', mC: '2' },
    { numH: '2.5', baseH: 'RP', mV: '6', mC: '4' },
    { numH: '2.5', baseH: 'RP', mV: '6', mC: '6' },
    { numH: '2.5', baseH: 'RP', mV: '6', mC: '8' },
    { numH: '2.5', baseH: 'RP', mV: '6', mC: '10' },
    { numH: '2.5', baseH: 'RP', mV: '6', mC: '12' },
    { numH: '2.5', baseH: 'RP', mV: '6', mC: '14' },
    { numH: '2.5', baseH: 'RP', mV: '6', mC: '16' },
    { numH: '2.5', baseH: 'RP', mV: '6', mC: '18' },
    { numH: '2.5', baseH: 'RP', mV: '6', mC: '20' },
    { numH: '2.5', baseH: 'RP', mV: '6', mC: '22' },
    { numH: '2.5', baseH: 'RP', mV: '6', mC: '24' },
    { numH: '5', baseH: 'RP', mV: '6', mC: '2' },
    { numH: '5', baseH: 'RP', mV: '6', mC: '4' },
    { numH: '5', baseH: 'RP', mV: '6', mC: '6' },
    { numH: '5', baseH: 'RP', mV: '6', mC: '8' },
    { numH: '5', baseH: 'RP', mV: '6', mC: '10' },
    { numH: '5', baseH: 'RP', mV: '6', mC: '12' },
    { numH: '5', baseH: 'RP', mV: '6', mC: '14' },
    { numH: '5', baseH: 'RP', mV: '6', mC: '16' },
    { numH: '5', baseH: 'RP', mV: '6', mC: '18' },
    { numH: '5', baseH: 'RP', mV: '6', mC: '20' },
    { numH: '5', baseH: 'RP', mV: '6', mC: '22' },
    { numH: '7.5', baseH: 'RP', mV: '6', mC: '2' },
    { numH: '7.5', baseH: 'RP', mV: '6', mC: '4' },
    { numH: '7.5', baseH: 'RP', mV: '6', mC: '6' },
    { numH: '7.5', baseH: 'RP', mV: '6', mC: '8' },
    { numH: '7.5', baseH: 'RP', mV: '6', mC: '10' },
    { numH: '7.5', baseH: 'RP', mV: '6', mC: '12' },
    { numH: '7.5', baseH: 'RP', mV: '6', mC: '14' },
    { numH: '7.5', baseH: 'RP', mV: '6', mC: '16' },
    { numH: '7.5', baseH: 'RP', mV: '6', mC: '18' },
    { numH: '7.5', baseH: 'RP', mV: '6', mC: '20' },
    { numH: '10', baseH: 'RP', mV: '7', mC: '2' },
    { numH: '10', baseH: 'RP', mV: '7', mC: '4' },
    { numH: '10', baseH: 'RP', mV: '7', mC: '6' },
    { numH: '10', baseH: 'RP', mV: '7', mC: '8' },
    { numH: '10', baseH: 'RP', mV: '7', mC: '10' },
    { numH: '10', baseH: 'RP', mV: '7', mC: '12' },
    { numH: '10', baseH: 'RP', mV: '7', mC: '14' },
    { numH: '10', baseH: 'RP', mV: '7', mC: '16' },
    { numH: '2.5', baseH: 'R', mV: '7', mC: '2' },
    { numH: '2.5', baseH: 'R', mV: '7', mC: '4' },
    { numH: '2.5', baseH: 'R', mV: '7', mC: '6' },
    { numH: '2.5', baseH: 'R', mV: '7', mC: '8' },
    { numH: '2.5', baseH: 'R', mV: '7', mC: '10' },
    { numH: '2.5', baseH: 'R', mV: '7', mC: '12' },
    { numH: '2.5', baseH: 'R', mV: '7', mC: '14' },
    { numH: '2.5', baseH: 'R', mV: '7', mC: '16' },
    { numH: '5', baseH: 'R', mV: '7', mC: '2' },
    { numH: '5', baseH: 'R', mV: '7', mC: '4' },
    { numH: '5', baseH: 'R', mV: '7', mC: '6' },
    { numH: '5', baseH: 'R', mV: '7', mC: '8' },
    { numH: '5', baseH: 'R', mV: '7', mC: '10' },
    { numH: '5', baseH: 'R', mV: '7', mC: '12' },
    { numH: '5', baseH: 'R', mV: '7', mC: '14' },
    { numH: '7.5', baseH: 'R', mV: '7', mC: '2' },
    { numH: '7.5', baseH: 'R', mV: '7', mC: '4' },
    { numH: '7.5', baseH: 'R', mV: '7', mC: '6' },
    { numH: '7.5', baseH: 'R', mV: '7', mC: '8' },
    { numH: '7.5', baseH: 'R', mV: '7', mC: '10' },
    { numH: '7.5', baseH: 'R', mV: '7', mC: '12' },
    { numH: '7.5', baseH: 'R', mV: '7', mC: '14' },
    { numH: '7.5', baseH: 'R', mV: '7', mC: '16' },
    { numH: '10', baseH: 'R', mV: '7', mC: '2' },
    { numH: '10', baseH: 'R', mV: '7', mC: '4' },
    { numH: '10', baseH: 'R', mV: '7', mC: '6' },
    { numH: '10', baseH: 'R', mV: '7', mC: '8' },
    { numH: '10', baseH: 'R', mV: '7', mC: '10' },
    { numH: '10', baseH: 'R', mV: '7', mC: '12' },
    { numH: '10', baseH: 'R', mV: '7', mC: '14' },
    { numH: '10', baseH: 'R', mV: '7', mC: '16' },
    { numH: '2.5', baseH: 'YR', mV: '7', mC: '2' },
    { numH: '2.5', baseH: 'YR', mV: '7', mC: '4' },
    { numH: '2.5', baseH: 'YR', mV: '7', mC: '6' },
    { numH: '2.5', baseH: 'YR', mV: '7', mC: '8' },
    { numH: '2.5', baseH: 'YR', mV: '7', mC: '10' },
    { numH: '2.5', baseH: 'YR', mV: '7', mC: '12' },
    { numH: '2.5', baseH: 'YR', mV: '7', mC: '14' },
    { numH: '2.5', baseH: 'YR', mV: '7', mC: '16' },
    { numH: '2.5', baseH: 'YR', mV: '7', mC: '18' },
    { numH: '2.5', baseH: 'YR', mV: '7', mC: '20' },
    { numH: '5', baseH: 'YR', mV: '7', mC: '2' },
    { numH: '5', baseH: 'YR', mV: '7', mC: '4' },
    { numH: '5', baseH: 'YR', mV: '7', mC: '6' },
    { numH: '5', baseH: 'YR', mV: '7', mC: '8' },
    { numH: '5', baseH: 'YR', mV: '7', mC: '10' },
    { numH: '5', baseH: 'YR', mV: '7', mC: '12' },
    { numH: '5', baseH: 'YR', mV: '7', mC: '14' },
    { numH: '5', baseH: 'YR', mV: '7', mC: '16' },
    { numH: '5', baseH: 'YR', mV: '7', mC: '18' },
    { numH: '5', baseH: 'YR', mV: '7', mC: '20' },
    { numH: '7.5', baseH: 'YR', mV: '7', mC: '2' },
    { numH: '7.5', baseH: 'YR', mV: '7', mC: '4' },
    { numH: '7.5', baseH: 'YR', mV: '7', mC: '6' },
    { numH: '7.5', baseH: 'YR', mV: '7', mC: '8' },
    { numH: '7.5', baseH: 'YR', mV: '7', mC: '10' },
    { numH: '7.5', baseH: 'YR', mV: '7', mC: '12' },
    { numH: '7.5', baseH: 'YR', mV: '7', mC: '14' },
    { numH: '7.5', baseH: 'YR', mV: '7', mC: '16' },
    { numH: '7.5', baseH: 'YR', mV: '7', mC: '18' },
    { numH: '10', baseH: 'YR', mV: '7', mC: '2' },
    { numH: '10', baseH: 'YR', mV: '7', mC: '4' },
    { numH: '10', baseH: 'YR', mV: '7', mC: '6' },
    { numH: '10', baseH: 'YR', mV: '7', mC: '8' },
    { numH: '10', baseH: 'YR', mV: '7', mC: '10' },
    { numH: '10', baseH: 'YR', mV: '7', mC: '12' },
    { numH: '10', baseH: 'YR', mV: '7', mC: '14' },
    { numH: '10', baseH: 'YR', mV: '7', mC: '16' },
    { numH: '10', baseH: 'YR', mV: '7', mC: '18' },
    { numH: '2.5', baseH: 'Y', mV: '7', mC: '2' },
    { numH: '2.5', baseH: 'Y', mV: '7', mC: '4' },
    { numH: '2.5', baseH: 'Y', mV: '7', mC: '6' },
    { numH: '2.5', baseH: 'Y', mV: '7', mC: '8' },
    { numH: '2.5', baseH: 'Y', mV: '7', mC: '10' },
    { numH: '2.5', baseH: 'Y', mV: '7', mC: '12' },
    { numH: '2.5', baseH: 'Y', mV: '7', mC: '14' },
    { numH: '2.5', baseH: 'Y', mV: '7', mC: '16' },
    { numH: '5', baseH: 'Y', mV: '7', mC: '2' },
    { numH: '5', baseH: 'Y', mV: '7', mC: '4' },
    { numH: '5', baseH: 'Y', mV: '7', mC: '6' },
    { numH: '5', baseH: 'Y', mV: '7', mC: '8' },
    { numH: '5', baseH: 'Y', mV: '7', mC: '10' },
    { numH: '5', baseH: 'Y', mV: '7', mC: '12' },
    { numH: '5', baseH: 'Y', mV: '7', mC: '14' },
    { numH: '5', baseH: 'Y', mV: '7', mC: '16' },
    { numH: '7.5', baseH: 'Y', mV: '7', mC: '2' },
    { numH: '7.5', baseH: 'Y', mV: '7', mC: '4' },
    { numH: '7.5', baseH: 'Y', mV: '7', mC: '6' },
    { numH: '7.5', baseH: 'Y', mV: '7', mC: '8' },
    { numH: '7.5', baseH: 'Y', mV: '7', mC: '10' },
    { numH: '7.5', baseH: 'Y', mV: '7', mC: '12' },
    { numH: '7.5', baseH: 'Y', mV: '7', mC: '14' },
    { numH: '7.5', baseH: 'Y', mV: '7', mC: '16' },
    { numH: '10', baseH: 'Y', mV: '7', mC: '2' },
    { numH: '10', baseH: 'Y', mV: '7', mC: '4' },
    { numH: '10', baseH: 'Y', mV: '7', mC: '6' },
    { numH: '10', baseH: 'Y', mV: '7', mC: '8' },
    { numH: '10', baseH: 'Y', mV: '7', mC: '10' },
    { numH: '10', baseH: 'Y', mV: '7', mC: '12' },
    { numH: '10', baseH: 'Y', mV: '7', mC: '14' },
    { numH: '10', baseH: 'Y', mV: '7', mC: '16' },
    { numH: '2.5', baseH: 'GY', mV: '7', mC: '2' },
    { numH: '2.5', baseH: 'GY', mV: '7', mC: '4' },
    { numH: '2.5', baseH: 'GY', mV: '7', mC: '6' },
    { numH: '2.5', baseH: 'GY', mV: '7', mC: '8' },
    { numH: '2.5', baseH: 'GY', mV: '7', mC: '10' },
    { numH: '2.5', baseH: 'GY', mV: '7', mC: '12' },
    { numH: '2.5', baseH: 'GY', mV: '7', mC: '14' },
    { numH: '2.5', baseH: 'GY', mV: '7', mC: '16' },
    { numH: '5', baseH: 'GY', mV: '7', mC: '2' },
    { numH: '5', baseH: 'GY', mV: '7', mC: '4' },
    { numH: '5', baseH: 'GY', mV: '7', mC: '6' },
    { numH: '5', baseH: 'GY', mV: '7', mC: '8' },
    { numH: '5', baseH: 'GY', mV: '7', mC: '10' },
    { numH: '5', baseH: 'GY', mV: '7', mC: '12' },
    { numH: '5', baseH: 'GY', mV: '7', mC: '14' },
    { numH: '5', baseH: 'GY', mV: '7', mC: '16' },
    { numH: '7.5', baseH: 'GY', mV: '7', mC: '2' },
    { numH: '7.5', baseH: 'GY', mV: '7', mC: '4' },
    { numH: '7.5', baseH: 'GY', mV: '7', mC: '6' },
    { numH: '7.5', baseH: 'GY', mV: '7', mC: '8' },
    { numH: '7.5', baseH: 'GY', mV: '7', mC: '10' },
    { numH: '7.5', baseH: 'GY', mV: '7', mC: '12' },
    { numH: '7.5', baseH: 'GY', mV: '7', mC: '14' },
    { numH: '7.5', baseH: 'GY', mV: '7', mC: '16' },
    { numH: '7.5', baseH: 'GY', mV: '7', mC: '18' },
    { numH: '10', baseH: 'GY', mV: '7', mC: '2' },
    { numH: '10', baseH: 'GY', mV: '7', mC: '4' },
    { numH: '10', baseH: 'GY', mV: '7', mC: '6' },
    { numH: '10', baseH: 'GY', mV: '7', mC: '8' },
    { numH: '10', baseH: 'GY', mV: '7', mC: '10' },
    { numH: '10', baseH: 'GY', mV: '7', mC: '12' },
    { numH: '10', baseH: 'GY', mV: '7', mC: '14' },
    { numH: '10', baseH: 'GY', mV: '7', mC: '16' },
    { numH: '10', baseH: 'GY', mV: '7', mC: '18' },
    { numH: '10', baseH: 'GY', mV: '7', mC: '20' },
    { numH: '10', baseH: 'GY', mV: '7', mC: '22' },
    { numH: '2.5', baseH: 'G', mV: '7', mC: '2' },
    { numH: '2.5', baseH: 'G', mV: '7', mC: '4' },
    { numH: '2.5', baseH: 'G', mV: '7', mC: '6' },
    { numH: '2.5', baseH: 'G', mV: '7', mC: '8' },
    { numH: '2.5', baseH: 'G', mV: '7', mC: '10' },
    { numH: '2.5', baseH: 'G', mV: '7', mC: '12' },
    { numH: '2.5', baseH: 'G', mV: '7', mC: '14' },
    { numH: '2.5', baseH: 'G', mV: '7', mC: '16' },
    { numH: '2.5', baseH: 'G', mV: '7', mC: '18' },
    { numH: '2.5', baseH: 'G', mV: '7', mC: '20' },
    { numH: '2.5', baseH: 'G', mV: '7', mC: '22' },
    { numH: '2.5', baseH: 'G', mV: '7', mC: '24' },
    { numH: '2.5', baseH: 'G', mV: '7', mC: '26' },
    { numH: '5', baseH: 'G', mV: '7', mC: '2' },
    { numH: '5', baseH: 'G', mV: '7', mC: '4' },
    { numH: '5', baseH: 'G', mV: '7', mC: '6' },
    { numH: '5', baseH: 'G', mV: '7', mC: '8' },
    { numH: '5', baseH: 'G', mV: '7', mC: '10' },
    { numH: '5', baseH: 'G', mV: '7', mC: '12' },
    { numH: '5', baseH: 'G', mV: '7', mC: '14' },
    { numH: '5', baseH: 'G', mV: '7', mC: '16' },
    { numH: '5', baseH: 'G', mV: '7', mC: '18' },
    { numH: '5', baseH: 'G', mV: '7', mC: '20' },
    { numH: '5', baseH: 'G', mV: '7', mC: '22' },
    { numH: '5', baseH: 'G', mV: '7', mC: '24' },
    { numH: '5', baseH: 'G', mV: '7', mC: '26' },
    { numH: '7.5', baseH: 'G', mV: '7', mC: '2' },
    { numH: '7.5', baseH: 'G', mV: '7', mC: '4' },
    { numH: '7.5', baseH: 'G', mV: '7', mC: '6' },
    { numH: '7.5', baseH: 'G', mV: '7', mC: '8' },
    { numH: '7.5', baseH: 'G', mV: '7', mC: '10' },
    { numH: '7.5', baseH: 'G', mV: '7', mC: '12' },
    { numH: '7.5', baseH: 'G', mV: '7', mC: '14' },
    { numH: '7.5', baseH: 'G', mV: '7', mC: '16' },
    { numH: '7.5', baseH: 'G', mV: '7', mC: '18' },
    { numH: '7.5', baseH: 'G', mV: '7', mC: '20' },
    { numH: '7.5', baseH: 'G', mV: '7', mC: '22' },
    { numH: '7.5', baseH: 'G', mV: '7', mC: '24' },
    { numH: '7.5', baseH: 'G', mV: '7', mC: '26' },
    { numH: '10', baseH: 'G', mV: '7', mC: '2' },
    { numH: '10', baseH: 'G', mV: '7', mC: '4' },
    { numH: '10', baseH: 'G', mV: '7', mC: '6' },
    { numH: '10', baseH: 'G', mV: '7', mC: '8' },
    { numH: '10', baseH: 'G', mV: '7', mC: '10' },
    { numH: '10', baseH: 'G', mV: '7', mC: '12' },
    { numH: '10', baseH: 'G', mV: '7', mC: '14' },
    { numH: '10', baseH: 'G', mV: '7', mC: '16' },
    { numH: '10', baseH: 'G', mV: '7', mC: '18' },
    { numH: '10', baseH: 'G', mV: '7', mC: '20' },
    { numH: '10', baseH: 'G', mV: '7', mC: '22' },
    { numH: '10', baseH: 'G', mV: '7', mC: '24' },
    { numH: '2.5', baseH: 'BG', mV: '7', mC: '2' },
    { numH: '2.5', baseH: 'BG', mV: '7', mC: '4' },
    { numH: '2.5', baseH: 'BG', mV: '7', mC: '6' },
    { numH: '2.5', baseH: 'BG', mV: '7', mC: '8' },
    { numH: '2.5', baseH: 'BG', mV: '7', mC: '10' },
    { numH: '2.5', baseH: 'BG', mV: '7', mC: '12' },
    { numH: '2.5', baseH: 'BG', mV: '7', mC: '14' },
    { numH: '2.5', baseH: 'BG', mV: '7', mC: '16' },
    { numH: '2.5', baseH: 'BG', mV: '7', mC: '18' },
    { numH: '2.5', baseH: 'BG', mV: '7', mC: '20' },
    { numH: '2.5', baseH: 'BG', mV: '7', mC: '22' },
    { numH: '5', baseH: 'BG', mV: '7', mC: '2' },
    { numH: '5', baseH: 'BG', mV: '7', mC: '4' },
    { numH: '5', baseH: 'BG', mV: '7', mC: '6' },
    { numH: '5', baseH: 'BG', mV: '7', mC: '8' },
    { numH: '5', baseH: 'BG', mV: '7', mC: '10' },
    { numH: '5', baseH: 'BG', mV: '7', mC: '12' },
    { numH: '5', baseH: 'BG', mV: '7', mC: '14' },
    { numH: '5', baseH: 'BG', mV: '7', mC: '16' },
    { numH: '5', baseH: 'BG', mV: '7', mC: '18' },
    { numH: '5', baseH: 'BG', mV: '7', mC: '20' },
    { numH: '7.5', baseH: 'BG', mV: '7', mC: '2' },
    { numH: '7.5', baseH: 'BG', mV: '7', mC: '4' },
    { numH: '7.5', baseH: 'BG', mV: '7', mC: '6' },
    { numH: '7.5', baseH: 'BG', mV: '7', mC: '8' },
    { numH: '7.5', baseH: 'BG', mV: '7', mC: '10' },
    { numH: '7.5', baseH: 'BG', mV: '7', mC: '12' },
    { numH: '7.5', baseH: 'BG', mV: '7', mC: '14' },
    { numH: '7.5', baseH: 'BG', mV: '7', mC: '16' },
    { numH: '7.5', baseH: 'BG', mV: '7', mC: '18' },
    { numH: '10', baseH: 'BG', mV: '7', mC: '2' },
    { numH: '10', baseH: 'BG', mV: '7', mC: '4' },
    { numH: '10', baseH: 'BG', mV: '7', mC: '6' },
    { numH: '10', baseH: 'BG', mV: '7', mC: '8' },
    { numH: '10', baseH: 'BG', mV: '7', mC: '10' },
    { numH: '10', baseH: 'BG', mV: '7', mC: '12' },
    { numH: '10', baseH: 'BG', mV: '7', mC: '14' },
    { numH: '10', baseH: 'BG', mV: '7', mC: '16' },
    { numH: '2.5', baseH: 'B', mV: '7', mC: '2' },
    { numH: '2.5', baseH: 'B', mV: '7', mC: '4' },
    { numH: '2.5', baseH: 'B', mV: '7', mC: '6' },
    { numH: '2.5', baseH: 'B', mV: '7', mC: '8' },
    { numH: '2.5', baseH: 'B', mV: '7', mC: '10' },
    { numH: '2.5', baseH: 'B', mV: '7', mC: '12' },
    { numH: '2.5', baseH: 'B', mV: '7', mC: '14' },
    { numH: '2.5', baseH: 'B', mV: '7', mC: '16' },
    { numH: '5', baseH: 'B', mV: '7', mC: '2' },
    { numH: '5', baseH: 'B', mV: '7', mC: '4' },
    { numH: '5', baseH: 'B', mV: '7', mC: '6' },
    { numH: '5', baseH: 'B', mV: '7', mC: '8' },
    { numH: '5', baseH: 'B', mV: '7', mC: '10' },
    { numH: '5', baseH: 'B', mV: '7', mC: '12' },
    { numH: '5', baseH: 'B', mV: '7', mC: '14' },
    { numH: '7.5', baseH: 'B', mV: '7', mC: '2' },
    { numH: '7.5', baseH: 'B', mV: '7', mC: '4' },
    { numH: '7.5', baseH: 'B', mV: '7', mC: '6' },
    { numH: '7.5', baseH: 'B', mV: '7', mC: '8' },
    { numH: '7.5', baseH: 'B', mV: '7', mC: '10' },
    { numH: '7.5', baseH: 'B', mV: '7', mC: '12' },
    { numH: '10', baseH: 'B', mV: '7', mC: '2' },
    { numH: '10', baseH: 'B', mV: '7', mC: '4' },
    { numH: '10', baseH: 'B', mV: '7', mC: '6' },
    { numH: '10', baseH: 'B', mV: '7', mC: '8' },
    { numH: '10', baseH: 'B', mV: '7', mC: '10' },
    { numH: '10', baseH: 'B', mV: '7', mC: '12' },
    { numH: '2.5', baseH: 'PB', mV: '7', mC: '2' },
    { numH: '2.5', baseH: 'PB', mV: '7', mC: '4' },
    { numH: '2.5', baseH: 'PB', mV: '7', mC: '6' },
    { numH: '2.5', baseH: 'PB', mV: '7', mC: '8' },
    { numH: '2.5', baseH: 'PB', mV: '7', mC: '10' },
    { numH: '5', baseH: 'PB', mV: '7', mC: '2' },
    { numH: '5', baseH: 'PB', mV: '7', mC: '4' },
    { numH: '5', baseH: 'PB', mV: '7', mC: '6' },
    { numH: '5', baseH: 'PB', mV: '7', mC: '8' },
    { numH: '5', baseH: 'PB', mV: '7', mC: '10' },
    { numH: '7.5', baseH: 'PB', mV: '7', mC: '2' },
    { numH: '7.5', baseH: 'PB', mV: '7', mC: '4' },
    { numH: '7.5', baseH: 'PB', mV: '7', mC: '6' },
    { numH: '7.5', baseH: 'PB', mV: '7', mC: '8' },
    { numH: '7.5', baseH: 'PB', mV: '7', mC: '10' },
    { numH: '10', baseH: 'PB', mV: '7', mC: '2' },
    { numH: '10', baseH: 'PB', mV: '7', mC: '4' },
    { numH: '10', baseH: 'PB', mV: '7', mC: '6' },
    { numH: '10', baseH: 'PB', mV: '7', mC: '8' },
    { numH: '10', baseH: 'PB', mV: '7', mC: '10' },
    { numH: '10', baseH: 'PB', mV: '7', mC: '12' },
    { numH: '2.5', baseH: 'P', mV: '7', mC: '2' },
    { numH: '2.5', baseH: 'P', mV: '7', mC: '4' },
    { numH: '2.5', baseH: 'P', mV: '7', mC: '6' },
    { numH: '2.5', baseH: 'P', mV: '7', mC: '8' },
    { numH: '2.5', baseH: 'P', mV: '7', mC: '10' },
    { numH: '2.5', baseH: 'P', mV: '7', mC: '12' },
    { numH: '5', baseH: 'P', mV: '7', mC: '2' },
    { numH: '5', baseH: 'P', mV: '7', mC: '4' },
    { numH: '5', baseH: 'P', mV: '7', mC: '6' },
    { numH: '5', baseH: 'P', mV: '7', mC: '8' },
    { numH: '5', baseH: 'P', mV: '7', mC: '10' },
    { numH: '5', baseH: 'P', mV: '7', mC: '12' },
    { numH: '5', baseH: 'P', mV: '7', mC: '14' },
    { numH: '7.5', baseH: 'P', mV: '7', mC: '2' },
    { numH: '7.5', baseH: 'P', mV: '7', mC: '4' },
    { numH: '7.5', baseH: 'P', mV: '7', mC: '6' },
    { numH: '7.5', baseH: 'P', mV: '7', mC: '8' },
    { numH: '7.5', baseH: 'P', mV: '7', mC: '10' },
    { numH: '7.5', baseH: 'P', mV: '7', mC: '12' },
    { numH: '7.5', baseH: 'P', mV: '7', mC: '14' },
    { numH: '7.5', baseH: 'P', mV: '7', mC: '16' },
    { numH: '7.5', baseH: 'P', mV: '7', mC: '18' },
    { numH: '10', baseH: 'P', mV: '7', mC: '2' },
    { numH: '10', baseH: 'P', mV: '7', mC: '4' },
    { numH: '10', baseH: 'P', mV: '7', mC: '6' },
    { numH: '10', baseH: 'P', mV: '7', mC: '8' },
    { numH: '10', baseH: 'P', mV: '7', mC: '10' },
    { numH: '10', baseH: 'P', mV: '7', mC: '12' },
    { numH: '10', baseH: 'P', mV: '7', mC: '14' },
    { numH: '10', baseH: 'P', mV: '7', mC: '16' },
    { numH: '10', baseH: 'P', mV: '7', mC: '18' },
    { numH: '10', baseH: 'P', mV: '7', mC: '20' },
    { numH: '10', baseH: 'P', mV: '7', mC: '22' },
    { numH: '2.5', baseH: 'RP', mV: '7', mC: '2' },
    { numH: '2.5', baseH: 'RP', mV: '7', mC: '4' },
    { numH: '2.5', baseH: 'RP', mV: '7', mC: '6' },
    { numH: '2.5', baseH: 'RP', mV: '7', mC: '8' },
    { numH: '2.5', baseH: 'RP', mV: '7', mC: '10' },
    { numH: '2.5', baseH: 'RP', mV: '7', mC: '12' },
    { numH: '2.5', baseH: 'RP', mV: '7', mC: '14' },
    { numH: '2.5', baseH: 'RP', mV: '7', mC: '16' },
    { numH: '2.5', baseH: 'RP', mV: '7', mC: '18' },
    { numH: '2.5', baseH: 'RP', mV: '7', mC: '20' },
    { numH: '5', baseH: 'RP', mV: '7', mC: '2' },
    { numH: '5', baseH: 'RP', mV: '7', mC: '4' },
    { numH: '5', baseH: 'RP', mV: '7', mC: '6' },
    { numH: '5', baseH: 'RP', mV: '7', mC: '8' },
    { numH: '5', baseH: 'RP', mV: '7', mC: '10' },
    { numH: '5', baseH: 'RP', mV: '7', mC: '12' },
    { numH: '5', baseH: 'RP', mV: '7', mC: '14' },
    { numH: '5', baseH: 'RP', mV: '7', mC: '16' },
    { numH: '5', baseH: 'RP', mV: '7', mC: '18' },
    { numH: '7.5', baseH: 'RP', mV: '7', mC: '2' },
    { numH: '7.5', baseH: 'RP', mV: '7', mC: '4' },
    { numH: '7.5', baseH: 'RP', mV: '7', mC: '6' },
    { numH: '7.5', baseH: 'RP', mV: '7', mC: '8' },
    { numH: '7.5', baseH: 'RP', mV: '7', mC: '10' },
    { numH: '7.5', baseH: 'RP', mV: '7', mC: '12' },
    { numH: '7.5', baseH: 'RP', mV: '7', mC: '14' },
    { numH: '7.5', baseH: 'RP', mV: '7', mC: '16' },
    { numH: '10', baseH: 'RP', mV: '8', mC: '2' },
    { numH: '10', baseH: 'RP', mV: '8', mC: '4' },
    { numH: '10', baseH: 'RP', mV: '8', mC: '6' },
    { numH: '10', baseH: 'RP', mV: '8', mC: '8' },
    { numH: '10', baseH: 'RP', mV: '8', mC: '10' },
    { numH: '2.5', baseH: 'R', mV: '8', mC: '2' },
    { numH: '2.5', baseH: 'R', mV: '8', mC: '4' },
    { numH: '2.5', baseH: 'R', mV: '8', mC: '6' },
    { numH: '2.5', baseH: 'R', mV: '8', mC: '8' },
    { numH: '2.5', baseH: 'R', mV: '8', mC: '10' },
    { numH: '5', baseH: 'R', mV: '8', mC: '2' },
    { numH: '5', baseH: 'R', mV: '8', mC: '4' },
    { numH: '5', baseH: 'R', mV: '8', mC: '6' },
    { numH: '5', baseH: 'R', mV: '8', mC: '8' },
    { numH: '5', baseH: 'R', mV: '8', mC: '10' },
    { numH: '7.5', baseH: 'R', mV: '8', mC: '2' },
    { numH: '7.5', baseH: 'R', mV: '8', mC: '4' },
    { numH: '7.5', baseH: 'R', mV: '8', mC: '6' },
    { numH: '7.5', baseH: 'R', mV: '8', mC: '8' },
    { numH: '7.5', baseH: 'R', mV: '8', mC: '10' },
    { numH: '10', baseH: 'R', mV: '8', mC: '2' },
    { numH: '10', baseH: 'R', mV: '8', mC: '4' },
    { numH: '10', baseH: 'R', mV: '8', mC: '6' },
    { numH: '10', baseH: 'R', mV: '8', mC: '8' },
    { numH: '10', baseH: 'R', mV: '8', mC: '10' },
    { numH: '2.5', baseH: 'YR', mV: '8', mC: '2' },
    { numH: '2.5', baseH: 'YR', mV: '8', mC: '4' },
    { numH: '2.5', baseH: 'YR', mV: '8', mC: '6' },
    { numH: '2.5', baseH: 'YR', mV: '8', mC: '8' },
    { numH: '2.5', baseH: 'YR', mV: '8', mC: '10' },
    { numH: '2.5', baseH: 'YR', mV: '8', mC: '12' },
    { numH: '5', baseH: 'YR', mV: '8', mC: '2' },
    { numH: '5', baseH: 'YR', mV: '8', mC: '4' },
    { numH: '5', baseH: 'YR', mV: '8', mC: '6' },
    { numH: '5', baseH: 'YR', mV: '8', mC: '8' },
    { numH: '5', baseH: 'YR', mV: '8', mC: '10' },
    { numH: '5', baseH: 'YR', mV: '8', mC: '12' },
    { numH: '5', baseH: 'YR', mV: '8', mC: '14' },
    { numH: '7.5', baseH: 'YR', mV: '8', mC: '2' },
    { numH: '7.5', baseH: 'YR', mV: '8', mC: '4' },
    { numH: '7.5', baseH: 'YR', mV: '8', mC: '6' },
    { numH: '7.5', baseH: 'YR', mV: '8', mC: '8' },
    { numH: '7.5', baseH: 'YR', mV: '8', mC: '10' },
    { numH: '7.5', baseH: 'YR', mV: '8', mC: '12' },
    { numH: '7.5', baseH: 'YR', mV: '8', mC: '14' },
    { numH: '7.5', baseH: 'YR', mV: '8', mC: '16' },
    { numH: '7.5', baseH: 'YR', mV: '8', mC: '18' },
    { numH: '7.5', baseH: 'YR', mV: '8', mC: '20' },
    { numH: '10', baseH: 'YR', mV: '8', mC: '2' },
    { numH: '10', baseH: 'YR', mV: '8', mC: '4' },
    { numH: '10', baseH: 'YR', mV: '8', mC: '6' },
    { numH: '10', baseH: 'YR', mV: '8', mC: '8' },
    { numH: '10', baseH: 'YR', mV: '8', mC: '10' },
    { numH: '10', baseH: 'YR', mV: '8', mC: '12' },
    { numH: '10', baseH: 'YR', mV: '8', mC: '14' },
    { numH: '10', baseH: 'YR', mV: '8', mC: '16' },
    { numH: '10', baseH: 'YR', mV: '8', mC: '18' },
    { numH: '10', baseH: 'YR', mV: '8', mC: '20' },
    { numH: '2.5', baseH: 'Y', mV: '8', mC: '2' },
    { numH: '2.5', baseH: 'Y', mV: '8', mC: '4' },
    { numH: '2.5', baseH: 'Y', mV: '8', mC: '6' },
    { numH: '2.5', baseH: 'Y', mV: '8', mC: '8' },
    { numH: '2.5', baseH: 'Y', mV: '8', mC: '10' },
    { numH: '2.5', baseH: 'Y', mV: '8', mC: '12' },
    { numH: '2.5', baseH: 'Y', mV: '8', mC: '14' },
    { numH: '2.5', baseH: 'Y', mV: '8', mC: '16' },
    { numH: '2.5', baseH: 'Y', mV: '8', mC: '18' },
    { numH: '2.5', baseH: 'Y', mV: '8', mC: '20' },
    { numH: '5', baseH: 'Y', mV: '8', mC: '2' },
    { numH: '5', baseH: 'Y', mV: '8', mC: '4' },
    { numH: '5', baseH: 'Y', mV: '8', mC: '6' },
    { numH: '5', baseH: 'Y', mV: '8', mC: '8' },
    { numH: '5', baseH: 'Y', mV: '8', mC: '10' },
    { numH: '5', baseH: 'Y', mV: '8', mC: '12' },
    { numH: '5', baseH: 'Y', mV: '8', mC: '14' },
    { numH: '5', baseH: 'Y', mV: '8', mC: '16' },
    { numH: '5', baseH: 'Y', mV: '8', mC: '18' },
    { numH: '7.5', baseH: 'Y', mV: '8', mC: '2' },
    { numH: '7.5', baseH: 'Y', mV: '8', mC: '4' },
    { numH: '7.5', baseH: 'Y', mV: '8', mC: '6' },
    { numH: '7.5', baseH: 'Y', mV: '8', mC: '8' },
    { numH: '7.5', baseH: 'Y', mV: '8', mC: '10' },
    { numH: '7.5', baseH: 'Y', mV: '8', mC: '12' },
    { numH: '7.5', baseH: 'Y', mV: '8', mC: '14' },
    { numH: '7.5', baseH: 'Y', mV: '8', mC: '16' },
    { numH: '7.5', baseH: 'Y', mV: '8', mC: '18' },
    { numH: '10', baseH: 'Y', mV: '8', mC: '2' },
    { numH: '10', baseH: 'Y', mV: '8', mC: '4' },
    { numH: '10', baseH: 'Y', mV: '8', mC: '6' },
    { numH: '10', baseH: 'Y', mV: '8', mC: '8' },
    { numH: '10', baseH: 'Y', mV: '8', mC: '10' },
    { numH: '10', baseH: 'Y', mV: '8', mC: '12' },
    { numH: '10', baseH: 'Y', mV: '8', mC: '14' },
    { numH: '10', baseH: 'Y', mV: '8', mC: '16' },
    { numH: '10', baseH: 'Y', mV: '8', mC: '18' },
    { numH: '2.5', baseH: 'GY', mV: '8', mC: '2' },
    { numH: '2.5', baseH: 'GY', mV: '8', mC: '4' },
    { numH: '2.5', baseH: 'GY', mV: '8', mC: '6' },
    { numH: '2.5', baseH: 'GY', mV: '8', mC: '8' },
    { numH: '2.5', baseH: 'GY', mV: '8', mC: '10' },
    { numH: '2.5', baseH: 'GY', mV: '8', mC: '12' },
    { numH: '2.5', baseH: 'GY', mV: '8', mC: '14' },
    { numH: '2.5', baseH: 'GY', mV: '8', mC: '16' },
    { numH: '2.5', baseH: 'GY', mV: '8', mC: '18' },
    { numH: '5', baseH: 'GY', mV: '8', mC: '2' },
    { numH: '5', baseH: 'GY', mV: '8', mC: '4' },
    { numH: '5', baseH: 'GY', mV: '8', mC: '6' },
    { numH: '5', baseH: 'GY', mV: '8', mC: '8' },
    { numH: '5', baseH: 'GY', mV: '8', mC: '10' },
    { numH: '5', baseH: 'GY', mV: '8', mC: '12' },
    { numH: '5', baseH: 'GY', mV: '8', mC: '14' },
    { numH: '5', baseH: 'GY', mV: '8', mC: '16' },
    { numH: '5', baseH: 'GY', mV: '8', mC: '18' },
    { numH: '5', baseH: 'GY', mV: '8', mC: '20' },
    { numH: '7.5', baseH: 'GY', mV: '8', mC: '2' },
    { numH: '7.5', baseH: 'GY', mV: '8', mC: '4' },
    { numH: '7.5', baseH: 'GY', mV: '8', mC: '6' },
    { numH: '7.5', baseH: 'GY', mV: '8', mC: '8' },
    { numH: '7.5', baseH: 'GY', mV: '8', mC: '10' },
    { numH: '7.5', baseH: 'GY', mV: '8', mC: '12' },
    { numH: '7.5', baseH: 'GY', mV: '8', mC: '14' },
    { numH: '7.5', baseH: 'GY', mV: '8', mC: '16' },
    { numH: '7.5', baseH: 'GY', mV: '8', mC: '18' },
    { numH: '7.5', baseH: 'GY', mV: '8', mC: '20' },
    { numH: '10', baseH: 'GY', mV: '8', mC: '2' },
    { numH: '10', baseH: 'GY', mV: '8', mC: '4' },
    { numH: '10', baseH: 'GY', mV: '8', mC: '6' },
    { numH: '10', baseH: 'GY', mV: '8', mC: '8' },
    { numH: '10', baseH: 'GY', mV: '8', mC: '10' },
    { numH: '10', baseH: 'GY', mV: '8', mC: '12' },
    { numH: '10', baseH: 'GY', mV: '8', mC: '14' },
    { numH: '10', baseH: 'GY', mV: '8', mC: '16' },
    { numH: '10', baseH: 'GY', mV: '8', mC: '18' },
    { numH: '10', baseH: 'GY', mV: '8', mC: '20' },
    { numH: '10', baseH: 'GY', mV: '8', mC: '22' },
    { numH: '10', baseH: 'GY', mV: '8', mC: '24' },
    { numH: '2.5', baseH: 'G', mV: '8', mC: '2' },
    { numH: '2.5', baseH: 'G', mV: '8', mC: '4' },
    { numH: '2.5', baseH: 'G', mV: '8', mC: '6' },
    { numH: '2.5', baseH: 'G', mV: '8', mC: '8' },
    { numH: '2.5', baseH: 'G', mV: '8', mC: '10' },
    { numH: '2.5', baseH: 'G', mV: '8', mC: '12' },
    { numH: '2.5', baseH: 'G', mV: '8', mC: '14' },
    { numH: '2.5', baseH: 'G', mV: '8', mC: '16' },
    { numH: '2.5', baseH: 'G', mV: '8', mC: '18' },
    { numH: '2.5', baseH: 'G', mV: '8', mC: '20' },
    { numH: '2.5', baseH: 'G', mV: '8', mC: '22' },
    { numH: '2.5', baseH: 'G', mV: '8', mC: '24' },
    { numH: '5', baseH: 'G', mV: '8', mC: '2' },
    { numH: '5', baseH: 'G', mV: '8', mC: '4' },
    { numH: '5', baseH: 'G', mV: '8', mC: '6' },
    { numH: '5', baseH: 'G', mV: '8', mC: '8' },
    { numH: '5', baseH: 'G', mV: '8', mC: '10' },
    { numH: '5', baseH: 'G', mV: '8', mC: '12' },
    { numH: '5', baseH: 'G', mV: '8', mC: '14' },
    { numH: '5', baseH: 'G', mV: '8', mC: '16' },
    { numH: '5', baseH: 'G', mV: '8', mC: '18' },
    { numH: '5', baseH: 'G', mV: '8', mC: '20' },
    { numH: '5', baseH: 'G', mV: '8', mC: '22' },
    { numH: '7.5', baseH: 'G', mV: '8', mC: '2' },
    { numH: '7.5', baseH: 'G', mV: '8', mC: '4' },
    { numH: '7.5', baseH: 'G', mV: '8', mC: '6' },
    { numH: '7.5', baseH: 'G', mV: '8', mC: '8' },
    { numH: '7.5', baseH: 'G', mV: '8', mC: '10' },
    { numH: '7.5', baseH: 'G', mV: '8', mC: '12' },
    { numH: '7.5', baseH: 'G', mV: '8', mC: '14' },
    { numH: '7.5', baseH: 'G', mV: '8', mC: '16' },
    { numH: '7.5', baseH: 'G', mV: '8', mC: '18' },
    { numH: '7.5', baseH: 'G', mV: '8', mC: '20' },
    { numH: '10', baseH: 'G', mV: '8', mC: '2' },
    { numH: '10', baseH: 'G', mV: '8', mC: '4' },
    { numH: '10', baseH: 'G', mV: '8', mC: '6' },
    { numH: '10', baseH: 'G', mV: '8', mC: '8' },
    { numH: '10', baseH: 'G', mV: '8', mC: '10' },
    { numH: '10', baseH: 'G', mV: '8', mC: '12' },
    { numH: '10', baseH: 'G', mV: '8', mC: '14' },
    { numH: '10', baseH: 'G', mV: '8', mC: '16' },
    { numH: '10', baseH: 'G', mV: '8', mC: '18' },
    { numH: '10', baseH: 'G', mV: '8', mC: '20' },
    { numH: '2.5', baseH: 'BG', mV: '8', mC: '2' },
    { numH: '2.5', baseH: 'BG', mV: '8', mC: '4' },
    { numH: '2.5', baseH: 'BG', mV: '8', mC: '6' },
    { numH: '2.5', baseH: 'BG', mV: '8', mC: '8' },
    { numH: '2.5', baseH: 'BG', mV: '8', mC: '10' },
    { numH: '2.5', baseH: 'BG', mV: '8', mC: '12' },
    { numH: '2.5', baseH: 'BG', mV: '8', mC: '14' },
    { numH: '2.5', baseH: 'BG', mV: '8', mC: '16' },
    { numH: '2.5', baseH: 'BG', mV: '8', mC: '18' },
    { numH: '5', baseH: 'BG', mV: '8', mC: '2' },
    { numH: '5', baseH: 'BG', mV: '8', mC: '4' },
    { numH: '5', baseH: 'BG', mV: '8', mC: '6' },
    { numH: '5', baseH: 'BG', mV: '8', mC: '8' },
    { numH: '5', baseH: 'BG', mV: '8', mC: '10' },
    { numH: '5', baseH: 'BG', mV: '8', mC: '12' },
    { numH: '5', baseH: 'BG', mV: '8', mC: '14' },
    { numH: '5', baseH: 'BG', mV: '8', mC: '16' },
    { numH: '7.5', baseH: 'BG', mV: '8', mC: '2' },
    { numH: '7.5', baseH: 'BG', mV: '8', mC: '4' },
    { numH: '7.5', baseH: 'BG', mV: '8', mC: '6' },
    { numH: '7.5', baseH: 'BG', mV: '8', mC: '8' },
    { numH: '7.5', baseH: 'BG', mV: '8', mC: '10' },
    { numH: '7.5', baseH: 'BG', mV: '8', mC: '12' },
    { numH: '7.5', baseH: 'BG', mV: '8', mC: '14' },
    { numH: '7.5', baseH: 'BG', mV: '8', mC: '16' },
    { numH: '10', baseH: 'BG', mV: '8', mC: '2' },
    { numH: '10', baseH: 'BG', mV: '8', mC: '4' },
    { numH: '10', baseH: 'BG', mV: '8', mC: '6' },
    { numH: '10', baseH: 'BG', mV: '8', mC: '8' },
    { numH: '10', baseH: 'BG', mV: '8', mC: '10' },
    { numH: '10', baseH: 'BG', mV: '8', mC: '12' },
    { numH: '10', baseH: 'BG', mV: '8', mC: '14' },
    { numH: '2.5', baseH: 'B', mV: '8', mC: '2' },
    { numH: '2.5', baseH: 'B', mV: '8', mC: '4' },
    { numH: '2.5', baseH: 'B', mV: '8', mC: '6' },
    { numH: '2.5', baseH: 'B', mV: '8', mC: '8' },
    { numH: '2.5', baseH: 'B', mV: '8', mC: '10' },
    { numH: '2.5', baseH: 'B', mV: '8', mC: '12' },
    { numH: '5', baseH: 'B', mV: '8', mC: '2' },
    { numH: '5', baseH: 'B', mV: '8', mC: '4' },
    { numH: '5', baseH: 'B', mV: '8', mC: '6' },
    { numH: '5', baseH: 'B', mV: '8', mC: '8' },
    { numH: '7.5', baseH: 'B', mV: '8', mC: '2' },
    { numH: '7.5', baseH: 'B', mV: '8', mC: '4' },
    { numH: '7.5', baseH: 'B', mV: '8', mC: '6' },
    { numH: '7.5', baseH: 'B', mV: '8', mC: '8' },
    { numH: '10', baseH: 'B', mV: '8', mC: '2' },
    { numH: '10', baseH: 'B', mV: '8', mC: '4' },
    { numH: '10', baseH: 'B', mV: '8', mC: '6' },
    { numH: '10', baseH: 'B', mV: '8', mC: '8' },
    { numH: '2.5', baseH: 'PB', mV: '8', mC: '2' },
    { numH: '2.5', baseH: 'PB', mV: '8', mC: '4' },
    { numH: '2.5', baseH: 'PB', mV: '8', mC: '6' },
    { numH: '5', baseH: 'PB', mV: '8', mC: '2' },
    { numH: '5', baseH: 'PB', mV: '8', mC: '4' },
    { numH: '5', baseH: 'PB', mV: '8', mC: '6' },
    { numH: '7.5', baseH: 'PB', mV: '8', mC: '2' },
    { numH: '7.5', baseH: 'PB', mV: '8', mC: '4' },
    { numH: '7.5', baseH: 'PB', mV: '8', mC: '6' },
    { numH: '10', baseH: 'PB', mV: '8', mC: '2' },
    { numH: '10', baseH: 'PB', mV: '8', mC: '4' },
    { numH: '10', baseH: 'PB', mV: '8', mC: '6' },
    { numH: '10', baseH: 'PB', mV: '8', mC: '8' },
    { numH: '2.5', baseH: 'P', mV: '8', mC: '2' },
    { numH: '2.5', baseH: 'P', mV: '8', mC: '4' },
    { numH: '2.5', baseH: 'P', mV: '8', mC: '6' },
    { numH: '2.5', baseH: 'P', mV: '8', mC: '8' },
    { numH: '5', baseH: 'P', mV: '8', mC: '2' },
    { numH: '5', baseH: 'P', mV: '8', mC: '4' },
    { numH: '5', baseH: 'P', mV: '8', mC: '6' },
    { numH: '5', baseH: 'P', mV: '8', mC: '8' },
    { numH: '5', baseH: 'P', mV: '8', mC: '10' },
    { numH: '7.5', baseH: 'P', mV: '8', mC: '2' },
    { numH: '7.5', baseH: 'P', mV: '8', mC: '4' },
    { numH: '7.5', baseH: 'P', mV: '8', mC: '6' },
    { numH: '7.5', baseH: 'P', mV: '8', mC: '8' },
    { numH: '7.5', baseH: 'P', mV: '8', mC: '10' },
    { numH: '7.5', baseH: 'P', mV: '8', mC: '12' },
    { numH: '10', baseH: 'P', mV: '8', mC: '2' },
    { numH: '10', baseH: 'P', mV: '8', mC: '4' },
    { numH: '10', baseH: 'P', mV: '8', mC: '6' },
    { numH: '10', baseH: 'P', mV: '8', mC: '8' },
    { numH: '10', baseH: 'P', mV: '8', mC: '10' },
    { numH: '10', baseH: 'P', mV: '8', mC: '12' },
    { numH: '10', baseH: 'P', mV: '8', mC: '14' },
    { numH: '2.5', baseH: 'RP', mV: '8', mC: '2' },
    { numH: '2.5', baseH: 'RP', mV: '8', mC: '4' },
    { numH: '2.5', baseH: 'RP', mV: '8', mC: '6' },
    { numH: '2.5', baseH: 'RP', mV: '8', mC: '8' },
    { numH: '2.5', baseH: 'RP', mV: '8', mC: '10' },
    { numH: '2.5', baseH: 'RP', mV: '8', mC: '12' },
    { numH: '2.5', baseH: 'RP', mV: '8', mC: '14' },
    { numH: '5', baseH: 'RP', mV: '8', mC: '2' },
    { numH: '5', baseH: 'RP', mV: '8', mC: '4' },
    { numH: '5', baseH: 'RP', mV: '8', mC: '6' },
    { numH: '5', baseH: 'RP', mV: '8', mC: '8' },
    { numH: '5', baseH: 'RP', mV: '8', mC: '10' },
    { numH: '5', baseH: 'RP', mV: '8', mC: '12' },
    { numH: '7.5', baseH: 'RP', mV: '8', mC: '2' },
    { numH: '7.5', baseH: 'RP', mV: '8', mC: '4' },
    { numH: '7.5', baseH: 'RP', mV: '8', mC: '6' },
    { numH: '7.5', baseH: 'RP', mV: '8', mC: '8' },
    { numH: '7.5', baseH: 'RP', mV: '8', mC: '10' },
    { numH: '7.5', baseH: 'RP', mV: '8', mC: '12' },
    { numH: '10', baseH: 'RP', mV: '9', mC: '2' },
    { numH: '10', baseH: 'RP', mV: '9', mC: '4' },
    { numH: '10', baseH: 'RP', mV: '9', mC: '6' },
    { numH: '2.5', baseH: 'R', mV: '9', mC: '2' },
    { numH: '2.5', baseH: 'R', mV: '9', mC: '4' },
    { numH: '2.5', baseH: 'R', mV: '9', mC: '6' },
    { numH: '5', baseH: 'R', mV: '9', mC: '2' },
    { numH: '5', baseH: 'R', mV: '9', mC: '4' },
    { numH: '5', baseH: 'R', mV: '9', mC: '6' },
    { numH: '7.5', baseH: 'R', mV: '9', mC: '2' },
    { numH: '7.5', baseH: 'R', mV: '9', mC: '4' },
    { numH: '7.5', baseH: 'R', mV: '9', mC: '6' },
    { numH: '10', baseH: 'R', mV: '9', mC: '2' },
    { numH: '10', baseH: 'R', mV: '9', mC: '4' },
    { numH: '10', baseH: 'R', mV: '9', mC: '6' },
    { numH: '2.5', baseH: 'YR', mV: '9', mC: '2' },
    { numH: '2.5', baseH: 'YR', mV: '9', mC: '4' },
    { numH: '2.5', baseH: 'YR', mV: '9', mC: '6' },
    { numH: '5', baseH: 'YR', mV: '9', mC: '2' },
    { numH: '5', baseH: 'YR', mV: '9', mC: '4' },
    { numH: '5', baseH: 'YR', mV: '9', mC: '6' },
    { numH: '7.5', baseH: 'YR', mV: '9', mC: '2' },
    { numH: '7.5', baseH: 'YR', mV: '9', mC: '4' },
    { numH: '7.5', baseH: 'YR', mV: '9', mC: '6' },
    { numH: '7.5', baseH: 'YR', mV: '9', mC: '8' },
    { numH: '10', baseH: 'YR', mV: '9', mC: '2' },
    { numH: '10', baseH: 'YR', mV: '9', mC: '4' },
    { numH: '10', baseH: 'YR', mV: '9', mC: '6' },
    { numH: '10', baseH: 'YR', mV: '9', mC: '8' },
    { numH: '2.5', baseH: 'Y', mV: '9', mC: '2' },
    { numH: '2.5', baseH: 'Y', mV: '9', mC: '4' },
    { numH: '2.5', baseH: 'Y', mV: '9', mC: '6' },
    { numH: '2.5', baseH: 'Y', mV: '9', mC: '8' },
    { numH: '2.5', baseH: 'Y', mV: '9', mC: '10' },
    { numH: '2.5', baseH: 'Y', mV: '9', mC: '12' },
    { numH: '5', baseH: 'Y', mV: '9', mC: '2' },
    { numH: '5', baseH: 'Y', mV: '9', mC: '4' },
    { numH: '5', baseH: 'Y', mV: '9', mC: '6' },
    { numH: '5', baseH: 'Y', mV: '9', mC: '8' },
    { numH: '5', baseH: 'Y', mV: '9', mC: '10' },
    { numH: '5', baseH: 'Y', mV: '9', mC: '12' },
    { numH: '5', baseH: 'Y', mV: '9', mC: '14' },
    { numH: '5', baseH: 'Y', mV: '9', mC: '16' },
    { numH: '5', baseH: 'Y', mV: '9', mC: '18' },
    { numH: '5', baseH: 'Y', mV: '9', mC: '20' },
    { numH: '7.5', baseH: 'Y', mV: '9', mC: '2' },
    { numH: '7.5', baseH: 'Y', mV: '9', mC: '4' },
    { numH: '7.5', baseH: 'Y', mV: '9', mC: '6' },
    { numH: '7.5', baseH: 'Y', mV: '9', mC: '8' },
    { numH: '7.5', baseH: 'Y', mV: '9', mC: '10' },
    { numH: '7.5', baseH: 'Y', mV: '9', mC: '12' },
    { numH: '7.5', baseH: 'Y', mV: '9', mC: '14' },
    { numH: '7.5', baseH: 'Y', mV: '9', mC: '16' },
    { numH: '7.5', baseH: 'Y', mV: '9', mC: '18' },
    { numH: '10', baseH: 'Y', mV: '9', mC: '2' },
    { numH: '10', baseH: 'Y', mV: '9', mC: '4' },
    { numH: '10', baseH: 'Y', mV: '9', mC: '6' },
    { numH: '10', baseH: 'Y', mV: '9', mC: '8' },
    { numH: '10', baseH: 'Y', mV: '9', mC: '10' },
    { numH: '10', baseH: 'Y', mV: '9', mC: '12' },
    { numH: '10', baseH: 'Y', mV: '9', mC: '14' },
    { numH: '10', baseH: 'Y', mV: '9', mC: '16' },
    { numH: '10', baseH: 'Y', mV: '9', mC: '18' },
    { numH: '2.5', baseH: 'GY', mV: '9', mC: '2' },
    { numH: '2.5', baseH: 'GY', mV: '9', mC: '4' },
    { numH: '2.5', baseH: 'GY', mV: '9', mC: '6' },
    { numH: '2.5', baseH: 'GY', mV: '9', mC: '8' },
    { numH: '2.5', baseH: 'GY', mV: '9', mC: '10' },
    { numH: '2.5', baseH: 'GY', mV: '9', mC: '12' },
    { numH: '2.5', baseH: 'GY', mV: '9', mC: '14' },
    { numH: '2.5', baseH: 'GY', mV: '9', mC: '16' },
    { numH: '2.5', baseH: 'GY', mV: '9', mC: '18' },
    { numH: '5', baseH: 'GY', mV: '9', mC: '2' },
    { numH: '5', baseH: 'GY', mV: '9', mC: '4' },
    { numH: '5', baseH: 'GY', mV: '9', mC: '6' },
    { numH: '5', baseH: 'GY', mV: '9', mC: '8' },
    { numH: '5', baseH: 'GY', mV: '9', mC: '10' },
    { numH: '5', baseH: 'GY', mV: '9', mC: '12' },
    { numH: '5', baseH: 'GY', mV: '9', mC: '14' },
    { numH: '5', baseH: 'GY', mV: '9', mC: '16' },
    { numH: '5', baseH: 'GY', mV: '9', mC: '18' },
    { numH: '7.5', baseH: 'GY', mV: '9', mC: '2' },
    { numH: '7.5', baseH: 'GY', mV: '9', mC: '4' },
    { numH: '7.5', baseH: 'GY', mV: '9', mC: '6' },
    { numH: '7.5', baseH: 'GY', mV: '9', mC: '8' },
    { numH: '7.5', baseH: 'GY', mV: '9', mC: '10' },
    { numH: '7.5', baseH: 'GY', mV: '9', mC: '12' },
    { numH: '7.5', baseH: 'GY', mV: '9', mC: '14' },
    { numH: '7.5', baseH: 'GY', mV: '9', mC: '16' },
    { numH: '7.5', baseH: 'GY', mV: '9', mC: '18' },
    { numH: '10', baseH: 'GY', mV: '9', mC: '2' },
    { numH: '10', baseH: 'GY', mV: '9', mC: '4' },
    { numH: '10', baseH: 'GY', mV: '9', mC: '6' },
    { numH: '10', baseH: 'GY', mV: '9', mC: '8' },
    { numH: '10', baseH: 'GY', mV: '9', mC: '10' },
    { numH: '10', baseH: 'GY', mV: '9', mC: '12' },
    { numH: '10', baseH: 'GY', mV: '9', mC: '14' },
    { numH: '10', baseH: 'GY', mV: '9', mC: '16' },
    { numH: '10', baseH: 'GY', mV: '9', mC: '18' },
    { numH: '2.5', baseH: 'G', mV: '9', mC: '2' },
    { numH: '2.5', baseH: 'G', mV: '9', mC: '4' },
    { numH: '2.5', baseH: 'G', mV: '9', mC: '6' },
    { numH: '2.5', baseH: 'G', mV: '9', mC: '8' },
    { numH: '2.5', baseH: 'G', mV: '9', mC: '10' },
    { numH: '2.5', baseH: 'G', mV: '9', mC: '12' },
    { numH: '2.5', baseH: 'G', mV: '9', mC: '14' },
    { numH: '2.5', baseH: 'G', mV: '9', mC: '16' },
    { numH: '5', baseH: 'G', mV: '9', mC: '2' },
    { numH: '5', baseH: 'G', mV: '9', mC: '4' },
    { numH: '5', baseH: 'G', mV: '9', mC: '6' },
    { numH: '5', baseH: 'G', mV: '9', mC: '8' },
    { numH: '5', baseH: 'G', mV: '9', mC: '10' },
    { numH: '5', baseH: 'G', mV: '9', mC: '12' },
    { numH: '7.5', baseH: 'G', mV: '9', mC: '2' },
    { numH: '7.5', baseH: 'G', mV: '9', mC: '4' },
    { numH: '7.5', baseH: 'G', mV: '9', mC: '6' },
    { numH: '7.5', baseH: 'G', mV: '9', mC: '8' },
    { numH: '7.5', baseH: 'G', mV: '9', mC: '10' },
    { numH: '7.5', baseH: 'G', mV: '9', mC: '12' },
    { numH: '10', baseH: 'G', mV: '9', mC: '2' },
    { numH: '10', baseH: 'G', mV: '9', mC: '4' },
    { numH: '10', baseH: 'G', mV: '9', mC: '6' },
    { numH: '10', baseH: 'G', mV: '9', mC: '8' },
    { numH: '10', baseH: 'G', mV: '9', mC: '10' },
    { numH: '10', baseH: 'G', mV: '9', mC: '12' },
    { numH: '2.5', baseH: 'BG', mV: '9', mC: '2' },
    { numH: '2.5', baseH: 'BG', mV: '9', mC: '4' },
    { numH: '2.5', baseH: 'BG', mV: '9', mC: '6' },
    { numH: '2.5', baseH: 'BG', mV: '9', mC: '8' },
    { numH: '2.5', baseH: 'BG', mV: '9', mC: '10' },
    { numH: '5', baseH: 'BG', mV: '9', mC: '2' },
    { numH: '5', baseH: 'BG', mV: '9', mC: '4' },
    { numH: '5', baseH: 'BG', mV: '9', mC: '6' },
    { numH: '5', baseH: 'BG', mV: '9', mC: '8' },
    { numH: '5', baseH: 'BG', mV: '9', mC: '10' },
    { numH: '7.5', baseH: 'BG', mV: '9', mC: '2' },
    { numH: '7.5', baseH: 'BG', mV: '9', mC: '4' },
    { numH: '7.5', baseH: 'BG', mV: '9', mC: '6' },
    { numH: '7.5', baseH: 'BG', mV: '9', mC: '8' },
    { numH: '7.5', baseH: 'BG', mV: '9', mC: '10' },
    { numH: '10', baseH: 'BG', mV: '9', mC: '2' },
    { numH: '10', baseH: 'BG', mV: '9', mC: '4' },
    { numH: '10', baseH: 'BG', mV: '9', mC: '6' },
    { numH: '2.5', baseH: 'B', mV: '9', mC: '2' },
    { numH: '2.5', baseH: 'B', mV: '9', mC: '4' },
    { numH: '5', baseH: 'B', mV: '9', mC: '2' },
    { numH: '5', baseH: 'B', mV: '9', mC: '4' },
    { numH: '7.5', baseH: 'B', mV: '9', mC: '2' },
    { numH: '7.5', baseH: 'B', mV: '9', mC: '4' },
    { numH: '10', baseH: 'B', mV: '9', mC: '2' },
    { numH: '10', baseH: 'B', mV: '9', mC: '4' },
    { numH: '2.5', baseH: 'PB', mV: '9', mC: '2' },
    { numH: '5', baseH: 'PB', mV: '9', mC: '2' },
    { numH: '7.5', baseH: 'PB', mV: '9', mC: '2' },
    { numH: '10', baseH: 'PB', mV: '9', mC: '2' },
    { numH: '10', baseH: 'PB', mV: '9', mC: '4' },
    { numH: '2.5', baseH: 'P', mV: '9', mC: '2' },
    { numH: '2.5', baseH: 'P', mV: '9', mC: '4' },
    { numH: '5', baseH: 'P', mV: '9', mC: '2' },
    { numH: '5', baseH: 'P', mV: '9', mC: '4' },
    { numH: '7.5', baseH: 'P', mV: '9', mC: '2' },
    { numH: '7.5', baseH: 'P', mV: '9', mC: '4' },
    { numH: '7.5', baseH: 'P', mV: '9', mC: '6' },
    { numH: '10', baseH: 'P', mV: '9', mC: '2' },
    { numH: '10', baseH: 'P', mV: '9', mC: '4' },
    { numH: '10', baseH: 'P', mV: '9', mC: '6' },
    { numH: '2.5', baseH: 'RP', mV: '9', mC: '2' },
    { numH: '2.5', baseH: 'RP', mV: '9', mC: '4' },
    { numH: '2.5', baseH: 'RP', mV: '9', mC: '6' },
    { numH: '5', baseH: 'RP', mV: '9', mC: '2' },
    { numH: '5', baseH: 'RP', mV: '9', mC: '4' },
    { numH: '5', baseH: 'RP', mV: '9', mC: '6' },
    { numH: '7.5', baseH: 'RP', mV: '9', mC: '2' },
    { numH: '7.5', baseH: 'RP', mV: '9', mC: '4' },
    { numH: '7.5', baseH: 'RP', mV: '9', mC: '6' },
  ];
}
