import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { ApiService } from '../../api/api.service';
import { ProjectConditions } from '../../models/response/search-picklist-categories';
import { ProjectService } from '../../service/project.service';
import { UserService } from '../../service/user.service';
import { SearchService } from '../../service/search.service';
import { SpinnerComponent } from '../../ui/loader/spinner/spinner.component';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { ButtonComponent } from '../../ui/button/button/button.component';
import { FormsModule } from '@angular/forms';
import { MatLegacyRadioModule } from '@angular/material/legacy-radio';
import { NgIf, NgFor } from '@angular/common';

interface SimilarCategory {
  id: string;
  name: string;
}

@Component({
    selector: 'ls-search-similar-product-dialog',
    templateUrl: './search-similar-product-dialog.component.html',
    styleUrls: ['./search-similar-product-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    standalone: true,
    imports: [
        MatLegacyDialogModule,
        NgIf,
        MatLegacyRadioModule,
        FormsModule,
        NgFor,
        ButtonComponent,
        MatLegacyButtonModule,
        SpinnerComponent,
    ],
})
export class SearchSimilarProductDialogComponent implements OnInit {
  selectedCategories: string | undefined = undefined;

  get projectConditions(): ProjectConditions {
    return this.project.projectConditions;
  }

  get type() {
    return this.project.picklistType;
  }

  _categories: SimilarCategory[];
  get categories(): SimilarCategory[] {
    if (
      this.project.currentCategories &&
      this.project.currentCategories.length > 0 &&
      !this._categories
    ) {
      const currentCategories = this.project.currentCategories;
      const categoryIds = this.data.categoryIds;
      this._categories = categoryIds.reduce((acc: SimilarCategory[], val) => {
        const result = currentCategories.find((item) => item.id === val);
        if (result) {
          acc.push({ id: result.id, name: result.name });
        }
        return acc;
      }, []);
      this.selectedCategories = this._categories![0].id;
    }
    return this._categories;
  }

  get isLoading() {
    return (
      !this.categories ||
      this.categories.length == 0 ||
      (this.user.enableProject && !this.projectConditions)
    );
  }

  constructor(
    private dialogRef: MatDialogRef<
      SearchSimilarProductDialogComponent,
      string
    >,
    private changeDetector: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private api: ApiService,
    readonly user: UserService,
    readonly project: ProjectService,
    readonly search: SearchService,
    @Inject(MAT_DIALOG_DATA)
    readonly data: { categoryIds: string[] },
  ) {}

  ngOnInit() {
    [
      this.search.onLoadSeachCategories$,
      this.project.onLoadedProjectCondtion$,
    ].forEach((s) => {
      s.subscribe(() => {
        this.changeDetector.markForCheck();
      });
    });
  }

  insertBreak(name: string) {
    return name.replace('(', '<br/>(');
  }

  cancel(): void {
    this.dialogRef.close();
  }

  okClick() {
    this.dialogRef.close(this.selectedCategories);
  }
}
