import { NgIf, NgFor } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Inject,
  ViewChild,
} from '@angular/core';
import { FormControl, FormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import {
  MatLegacyAutocompleteTrigger as MatAutocompleteTrigger,
  MatLegacyAutocompleteModule,
} from '@angular/material/legacy-autocomplete';
import { MatLegacyOptionModule } from '@angular/material/legacy-core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
  MatLegacyDialog,
  MatLegacyDialogModule,
} from '@angular/material/legacy-dialog';
import { MatLegacyRadioModule } from '@angular/material/legacy-radio';
import {
  MatLegacySelectChange as MatSelectChange,
  MatLegacySelect as MatSelect,
  MatLegacySelectModule,
} from '@angular/material/legacy-select';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { DecidedProductInfo } from '@shared/models/response/decided-product-info';
import { ListProduct } from '@shared/models/response/list-product';
import { CertificationService } from '@shared/service/certification.service';
import { PicklistService } from '@shared/service/picklist.service';
import { Observable, Subject } from 'rxjs';

import { BasicButtonComponent } from '../../../components/presentation/basic-button/basic-button.component';
import { Picklist } from '../../models/response/picklist';
import { ProductCache } from '../../models/response/product-cache';
import { ManualMakersSuggention } from '../../models/response/sub/manual-makers-suggestion';
import { ThumbnailType } from '../../models/response/sub/thumbnail-type';
import { ButtonComponent } from '../../ui/button/button/button.component';
import { SrcDirective } from '../../ui/image/src/src.directive';
import { RequiredTagComponent } from '../../ui/tag/required-tag/required-tag.component';
import { AddColorImageDialogService } from '../add-color-image-dialog/add-color-image-dialog.service';
import { AlertInteriorRestrictionDialogComponent } from '../alert-interior-restriction-dialog/alert-interior-restriction-dialog.component';

@Component({
  selector: 'ls-add-manual-input-candidate-dialog',
  templateUrl: './add-manual-input-candidate-dialog.component.html',
  styleUrls: ['./add-manual-input-candidate-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false,
  standalone: true,
  imports: [
    NgIf,
    FormsModule,
    MatLegacyAutocompleteModule,
    NgFor,
    MatLegacyOptionModule,
    RequiredTagComponent,
    MatLegacySelectModule,
    MatLegacyTooltipModule,
    MatLegacyRadioModule,
    MatDividerModule,
    SrcDirective,
    BasicButtonComponent,
    MatLegacyDialogModule,
    ButtonComponent,
  ],
})
export class AddManualInputCandidateDialogComponent implements AfterViewInit {
  private readonly dialog = inject(MatLegacyDialog);

  newDecidedProductInfo: DecidedProductInfo;
  productCache: ProductCache;
  picklist: Picklist;
  listProduct: ListProduct;
  isTextureSelected = false;
  _thumbnailType = ThumbnailType;
  manualMakersSuggention = ManualMakersSuggention;
  createMode = false;
  texture_height: FormControl = new FormControl();
  texture_width: FormControl = new FormControl();

  get canSubmit() {
    return (
      (!!this.newDecidedProductInfo.name &&
        !this.newDecidedProductInfo.price) ||
      (!!this.newDecidedProductInfo.name &&
        !!this.newDecidedProductInfo.price &&
        !!this.newDecidedProductInfo.price_unit)
    );
  }

  get isSetTexture() {
    if (this.newDecidedProductInfo.texture_image || this.isTextureSelected) {
      return (
        this.newDecidedProductInfo.texture_width &&
        this.newDecidedProductInfo.texture_height
      );
    } else {
      return true;
    }
  }

  get restrictionSet() {
    if (this.productCache! && this.productCache!.restriction) {
      let filterSet = this.productCache.restriction!.set.filter(
        (s) => s.picklist_type == this.picklist.type,
      );
      filterSet = filterSet.filter((set) => {
        return (
          filterSet.filter((s) => s.no === set.no && s.memo === set.memo)[0] ===
          set
        );
      });

      if (filterSet.length > 1) {
        return filterSet;
      } else {
        return filterSet.length === 0
          ? []
          : filterSet[0].no === '' || !filterSet[0].no
            ? []
            : filterSet;
      }
    } else {
      return [];
    }
  }

  newThumbnailData?: string;
  newThumbnailReader: FileReader;

  newTextureData?: string;
  newTextureReader: FileReader;

  @ViewChild('select') select: MatSelect;

  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      newDecidedProductInfo: DecidedProductInfo;
      listProduct: ListProduct;
      picklist: Picklist;
      productCache: ProductCache;
      createMode: boolean;
    },
    private changeDetector: ChangeDetectorRef,
    private colorImageDialog: AddColorImageDialogService,
    private dialogRef: MatDialogRef<
      AddManualInputCandidateDialogComponent,
      DecidedProductInfo
    >,
    private certificationService: CertificationService,
    private pickListService: PicklistService,
  ) {
    this.newDecidedProductInfo = Object.assign(
      {},
      this.data.newDecidedProductInfo,
    );
    this.productCache = this.data.productCache;
    this.picklist = Object.assign({}, this.data.picklist);
    this.listProduct = this.data.listProduct;
    this.createMode = this.data.createMode;
  }

  ngAfterViewInit(): void {
    if (
      this.restrictionSet!.length > 1 &&
      !this.newDecidedProductInfo.certification
    ) {
      setTimeout(() => {
        this.select.open();
      }, 200);
    }
  }

  checkIllegalRoomAtSelect(event: MatSelectChange) {
    const preCertification = this.newDecidedProductInfo.certification;
    this.newDecidedProductInfo.certification = event.value;
    this.listProduct.certification = event.value;
    this.__checkIllegalRoomAtSelect(this.picklist, this.listProduct).subscribe(
      (result) => {
        if (!result) {
          this.newDecidedProductInfo.certification = preCertification;
          this.listProduct.certification = preCertification;
          event!.source.writeValue(preCertification);
          this.changeDetector.markForCheck();
        }
      },
    );
  }

  __checkIllegalRoomAtSelect(
    picklist: Picklist,
    listProduct: ListProduct,
  ): Observable<boolean | undefined> {
    const illegals =
      this.certificationService.checkInteriorRestrictionOfRoomByPicklist(
        picklist,
        listProduct,
      );

    const onEnd$ = new Subject<boolean | undefined>();

    if (illegals.length !== 0) {
      this.dialog
        .open(AlertInteriorRestrictionDialogComponent, {
          data: {
            illegalInfo: illegals,
            setPicklist: true,
            decided: true,
            singleSelect: false,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          onEnd$.next(result);
        });
    } else {
      setTimeout(() => {
        onEnd$.next(true);
      }, 10);
    }

    return onEnd$.asObservable();
  }

  checkIllegalRoomAtInput(event: EventTarget | null) {
    if (!this.createMode && event) {
      const preCertification = this.newDecidedProductInfo.certification;
      this.newDecidedProductInfo.certification = (
        event as HTMLInputElement
      ).value;
      this.listProduct.certification = (event as HTMLInputElement).value;

      this.__checkIllegalRoomAtSelect(
        this.picklist,
        this.listProduct,
      ).subscribe((result) => {
        if (!result) {
          this.newDecidedProductInfo.certification = preCertification;
          this.listProduct.certification = preCertification;
          this.changeDetector.markForCheck();
        }
      });
    }
  }

  selectThumbnail(file?: File) {
    if (!file) return;

    this.newDecidedProductInfo.uploadedThumbnail = file;
    this.newThumbnailReader = new FileReader();
    this.newThumbnailReader.onload = (e: any) => {
      this.newThumbnailData = e.target!.result;
      this.changeDetector.markForCheck();
    };

    this.newThumbnailReader.readAsDataURL(file);
  }

  selectTexture(file?: File) {
    if (!file) return;

    this.newDecidedProductInfo.uploadedTexture = file;
    this.newTextureReader = new FileReader();
    this.newTextureReader.onload = (e: any) => {
      this.newTextureData = e.target!.result;
      this.changeDetector.markForCheck();
    };
    this.newTextureReader.readAsDataURL(file);

    this.isTextureSelected = true;
    this.changeDetector.markForCheck();
  }

  deleteThumbnail() {
    this.newThumbnailData = undefined;
    this.newDecidedProductInfo.uploadedThumbnail = undefined;
    this.newDecidedProductInfo.thumbnail_image = null;
  }

  deleteTexture() {
    this.newTextureData = undefined;
    this.newDecidedProductInfo.uploadedTexture = undefined;
    this.newDecidedProductInfo.texture_image = null;
    this.newDecidedProductInfo.texture_width = null;
    this.newDecidedProductInfo.texture_height = null;
    this.isTextureSelected = false;
  }

  clearFilePath(uploadedFile: HTMLInputElement) {
    if (uploadedFile.value) {
      uploadedFile.value = '';
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  onSave() {
    const newListProduct = Object.assign(
      { ...this.listProduct },
      { ...this.newDecidedProductInfo },
    );
    this.dialogRef.close(newListProduct);
  }

  selectUseImage(useImageType: ThumbnailType) {
    this.listProduct.use_image = useImageType;
  }

  onClickCreateImage() {
    this.colorImageDialog
      .openDialog(this.listProduct!, this.picklist)
      .afterClosed()
      .subscribe((colorInfo) => {
        if (colorInfo) {
          this.newDecidedProductInfo.thumbnail_image = colorInfo.filePath;
          this.newDecidedProductInfo.color_image = colorInfo;
          this.newThumbnailData = undefined;
          this.changeDetector.markForCheck();
        }
      });
  }

  isColorImage(src: string): boolean {
    return this.pickListService.isColorImage(src, this.listProduct);
  }
}
