import { inject, Injectable } from '@angular/core';
import { GuardsCheckEnd, Router } from '@angular/router';
import {
  EMPTY,
  filter,
  map,
  Observable,
  of,
  switchMap,
  take,
  tap,
  throwError,
} from 'rxjs';

import { UserStore } from '../../core/store/user.store';
import { TenantApi } from '../_api/tenant.api';
import { TenantIdStorageLogic } from '../_logic/tenant-id-storage.logic';
import { TenantStore } from '../_store/tenant.store';
import { UserTenantStore } from '../_store/user-tenant.store';
import { Tenant, UserTenant } from '../_type/tenant';

@Injectable({
  providedIn: 'root',
})
export class TenantUsecase {
  private api = inject(TenantApi);
  private userStore = inject(UserStore);
  private tenantStore = inject(TenantStore);
  private userTenantStore = inject(UserTenantStore);
  private tenantIdStorageLogic = inject(TenantIdStorageLogic);
  private router = inject(Router);
  private tenantIdByQueryParam$: Observable<number | undefined> =
    this.getTenantIdByQueryParams();

  // idがなかったらlocalStorageから取得してくる
  fetchAndStoreTenant(id?: number): Observable<Tenant> {
    const userId$ = this.userStore.userId$;

    return userId$.pipe(
      filter((userId) => !!userId),
      map((userId: number) => {
        const tenantId = id ? id : this.tenantIdStorageLogic.getId(userId);

        return tenantId;
      }),
      switchMap((tenantId: number | undefined) => {
        if (tenantId) return of(tenantId);

        return this.tenantIdByQueryParam$;
      }),
      take(1),
      switchMap((tenantId) => {
        if (!tenantId || isNaN(tenantId) || tenantId < 1) {
          this.router.navigateByUrl('/tenant/login');

          return EMPTY;
        }

        return of(tenantId);
      }),
      tap(() => this.tenantStore.set(undefined)),
      switchMap((tenantId: number) => this.api.findUnique(tenantId)),
      tap((tenant) => {
        this.tenantStore.set(tenant);
        this.tenantIdStorageLogic.setId(tenant.id);
        this.api.setTenantId(tenant.id).subscribe();
      }),
    );
  }

  fetchAndStoreUserTenants(userId: number): Observable<UserTenant[]> {
    return this.api.findManyByUserId(userId).pipe(
      take(1),
      tap((userTenants) => this.userTenantStore.set(userTenants)),
    );
  }

  removeTenant(): void {
    this.userStore.userId$
      .pipe(
        take(1),
        map((userId: number) => {
          if (userId) return userId;
          else return throwError(() => new Error('User ID not found'));
        }),
      )
      .subscribe((userId: number) => {
        this.tenantIdStorageLogic.deleteId(userId);
        this.tenantStore.remove();
      });
  }

  private getTenantIdByQueryParams(): Observable<number> {
    return this.router.events.pipe(
      filter((event) => event instanceof GuardsCheckEnd),
      map((event: GuardsCheckEnd) => {
        const url = new URL(event.url, window.location.origin);

        return Number(url.searchParams.get('tenantId'));
      }),
    );
  }
}
