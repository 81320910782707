import { Injectable, inject } from '@angular/core';
import { RoomGroup } from 'app/v2/general/domain/types/states/room-group.state';
import {
  Observable,
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
} from 'rxjs';

import { RoomGroupStore } from '../../../store/room-group.store';
import { InteriorSetViewModel } from '../../../view-model/interior-set-view-model';
import { RoomBlockViewModel } from '../../../view-model/room-block-view-model';
import { RoomGroupViewModel } from '../../../view-model/room-group-view-model';
import { GetInteriorSetViewModelQuery } from '../interior-set/get-interior-set-view-model-query';
import { GetRoomBlockViewModelQuery } from '../room-block/get-room-block-view-model-query';

/**
 * RoomGroupViewModel生成クラス
 */
@Injectable({
  providedIn: 'root',
})
export class GetRoomGroupViewModelQuery {
  private roomGroupStore = inject(RoomGroupStore);
  private getInteriorSetViewModelQuery = inject(GetInteriorSetViewModelQuery);
  private getRoomBlockViewModelQuery = inject(GetRoomBlockViewModelQuery);

  private roomGroups$: Observable<RoomGroup[]> =
    this.roomGroupStore.roomGroups$;

  private roomBlockViewModels$: Observable<RoomBlockViewModel[]> =
    this.getRoomBlockViewModelQuery.getViewModelList();

  private interiorSetViewModel$ =
    this.getInteriorSetViewModelQuery.getViewModelList();

  /**
   * roomGroupStoreから取得した値でRoomGroupViewModelの配列のObservableを生成する
   */
  public getViewModelList(): Observable<RoomGroupViewModel[]> {
    return combineLatest([
      this.roomGroups$,
      this.roomBlockViewModels$,
      this.interiorSetViewModel$,
    ]).pipe(
      map(([roomGroups, roomBlockViewModels, interiorSets]) => {
        const roomGroupViewModelList: RoomGroupViewModel[] = roomGroups.map(
          (roomGroup) =>
            this.genRoomGroupViewModel(
              roomGroup,
              roomBlockViewModels,
              interiorSets,
            ),
        );

        return roomGroupViewModelList;
      }),
    );
  }

  public getViewModelById(
    roomGroupId: number,
  ): Observable<RoomGroupViewModel | undefined> {
    return combineLatest([
      this.roomGroups$,
      this.roomBlockViewModels$,
      this.interiorSetViewModel$,
    ]).pipe(
      filter(
        ([roomGroups, roomBlockViewModels, interiorSets]) =>
          !!roomGroups.length,
      ),
      map(([roomGroups, roomBlockViewModels, interiorSets]) => {
        const targetRoomGroup = roomGroups.find(
          (roomGroup) => roomGroup.id === roomGroupId,
        );

        if (!targetRoomGroup) return undefined;

        return this.genRoomGroupViewModel(
          targetRoomGroup,
          roomBlockViewModels,
          interiorSets,
        );
      }),
      distinctUntilChanged(
        (prev, curr) => JSON.stringify(prev) === JSON.stringify(curr),
      ),
    );
  }

  private genRoomGroupViewModel(
    roomGroup: RoomGroup,
    roomBlockViewModels: RoomBlockViewModel[],
    interiorSets: InteriorSetViewModel[],
  ): RoomGroupViewModel {
    const targetRoomBlockViewModels =
      roomBlockViewModels.filter(
        (roomBlock) => roomBlock.room_group_id == roomGroup.id,
      ) || [];

    const interiorSetViewModel = interiorSets.find((interiorSet) => {
      return interiorSet.id === roomGroup.interior_set_id;
    })!;

    const roomGroupViewModel: RoomGroupViewModel = {
      ...roomGroup,
      interiorSet: interiorSetViewModel!,
      room_blocks: targetRoomBlockViewModels,
    };

    return roomGroupViewModel;
  }
}
