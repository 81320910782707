import { Component, Injectable } from '@angular/core';

@Component({
    selector: 'ls-required-tag',
    templateUrl: './required-tag.component.html',
    styleUrls: ['./required-tag.component.scss'],
    standalone: true,
})
@Injectable({
  providedIn: 'root',
})
export class RequiredTagComponent {
  constructor() {}
}
