<button
  class="button button--{{ color }}"
  [class.button--active]="active"
  [class.button--disabled]="disable"
  mat-button
  (click)="onEmit($event)"
>
  <mat-icon class="prefix-icon" *ngIf="prefixIcon">{{ prefixIcon }}</mat-icon>
  <span class="label">{{ label }}</span>
  <mat-icon class="suffix-icon" *ngIf="suffixIcon">{{ suffixIcon }}</mat-icon>
</button>
