import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment as env } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SrcService {
  constructor(private http: HttpClient) {}

  checkIfExists(src: string): Observable<boolean> {
    return this.http
      .get(src, {
        responseType: 'blob',
      })
      .pipe(
        catchError((e) => of(false)),
        map<Blob | false, boolean>(
          (blob) => true,
          //(blob) => !!blob && !!blob.type.match(/^image\/.*/)
        ),
      );
  }

  storage(fileName: string, isNew?: boolean): string {
    const path = env.storagePath;
    if (isNew) {
      return `${fileName}`;
    } else {
      return `${path}${fileName}`;
    }
  }

  size(src: string, size: string) {
    // {prefix}/{id}-{size}.{extension}
    const match = src.match(/(.*)\/([0-9a-f]*)-([a-z]*)\.(png|jpg|jpeg|gif)$/);
    if (!match) {
      return src;
    }
    const [, prefix, id, , extension] = match;

    return `${prefix}/${id}-${size}.${extension}`;
  }
}
