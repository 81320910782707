import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { ListProduct } from '@shared/models/response/list-product';

import { ApiService } from '../../api/api.service';
import { List } from '../../models/list';
import { Picklist } from '../../models/response/picklist';
import { ColorImage } from '../../models/response/sub/color-image';

import { AddColorImageDialogService } from './add-color-image-dialog.service';
import { ButtonComponent } from '../../ui/button/button/button.component';
import { SrcDirective } from '../../ui/image/src/src.directive';
import { SpinnerComponent } from '../../ui/loader/spinner/spinner.component';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyOptionModule } from '@angular/material/legacy-core';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { NgIf, NgFor } from '@angular/common';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { FormsModule } from '@angular/forms';
import { MatLegacyRadioModule } from '@angular/material/legacy-radio';

@Component({
    selector: 'ls-add-color-image-dialog',
    templateUrl: './add-color-image-dialog.component.html',
    styleUrls: ['./add-color-image-dialog.component.scss'],
    standalone: true,
    imports: [
        MatLegacyDialogModule,
        MatLegacyRadioModule,
        FormsModule,
        MatLegacyTooltipModule,
        NgIf,
        MatLegacySelectModule,
        MatLegacyOptionModule,
        NgFor,
        MatLegacyButtonModule,
        MatDividerModule,
        MatIconModule,
        SpinnerComponent,
        SrcDirective,
        ButtonComponent,
    ],
})
export class AddColorImageDialogComponent implements OnInit {
  colorInfo: ColorImage;
  picklist: Picklist | undefined;
  listProduct: ListProduct;
  isLoading = false;

  numH: string | undefined;
  baseH: string | undefined;
  mV: string | undefined;
  mC: string | undefined;
  numHList = ['2.5', '5', '7.5', '10'];
  baseHList = ['N', 'B', 'BG', 'G', 'GY', 'P', 'PB', 'R', 'RP', 'Y', 'YR'];
  vList = ['1', '2', '3', '4', '5', '6', '7', '8', '9'];
  mCList: List<string>[];

  pH: string | undefined;
  pV: string | undefined;
  pC: string | undefined;
  pHList = [
    'N',
    '02',
    '05',
    '07',
    '08',
    '09',
    '12',
    '15',
    '17',
    '19',
    '22',
    '25',
    '27',
    '29',
    '32',
    '35',
    '37',
    '39',
    '42',
    '45',
    '47',
    '49',
    '52',
    '55',
    '57',
    '59',
    '62',
    '65',
    '67',
    '69',
    '72',
    '75',
    '76',
    '77',
    '79',
    '82',
    '85',
    '87',
    '89',
    '92',
    '95',
    '97',
    '99',
  ];
  pVList: string[] = [];
  pCList: string[] = [];

  constructor(
    private colorImageDialog: AddColorImageDialogService,
    private dialogRef: MatDialogRef<AddColorImageDialogComponent>,
    private changeDetector: ChangeDetectorRef,
    private api: ApiService,
    @Inject(MAT_DIALOG_DATA)
    private data: { listProduct: ListProduct; picklist?: Picklist },
  ) {}

  ngOnInit() {
    this.listProduct = this.data.listProduct;
    this.picklist = this.data.picklist;
    this.colorInfo = this.listProduct.color_image
      ? Object.assign({}, this.data.listProduct.color_image)
      : { color: '', type: 'paintNo' };
  }

  getMcList(
    nH: string | undefined,
    bH: string | undefined,
    v: string | undefined,
  ): void {
    this.mC = undefined;
    this.mCList = this.colorImageDialog.mCLists.filter(
      (munsell) =>
        munsell.numH === nH && munsell.baseH === bH && munsell.mV === v,
    );
  }

  selectN(): void {
    if (this.baseH === 'N') {
      this.numH = undefined;
      this.mC = undefined;
    }
  }

  getPvList(h: string | undefined): void {
    this.pV = undefined;
    this.pC = undefined;
    const vArr: string[] = [];
    this.colorImageDialog.paintNoLists.forEach((paintNo) => {
      if (paintNo['pH'] === h) {
        vArr.push(paintNo['pV']);
      }
    });
    this.pVList = Array.from(new Set(vArr)).sort();
  }

  getPcList(h: string | undefined, v: string | undefined): void {
    this.pC = undefined;
    const cArr: string[] = [];
    this.colorImageDialog.paintNoLists.forEach((paintNo) => {
      if (paintNo['pH'] === h && paintNo['pV'] === v) {
        cArr.push(paintNo['pC']);
      }
    });
    this.pCList = Array.from(new Set(cArr)).sort();
    if (this.pCList.length === 1) {
      this.pC = this.pCList[0];
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }

  onGenerate(): void {
    if (
      (this.numH && this.baseH && this.mV && this.mC) ||
      (this.pH && this.pV && this.pC) ||
      (this.baseH === 'N' && this.mV) ||
      (this.pH === 'N' && this.pV)
    ) {
      if (this.colorInfo.type === 'munsell') {
        this.colorInfo.color =
          this.baseH === 'N'
            ? this.baseH + this.mV
            : (this.colorInfo.color =
                this.numH! + this.baseH! + ' ' + this.mV + '/' + this.mC);
        this.generate();
      } else if (this.colorInfo.type === 'paintNo') {
        this.colorInfo.color =
          this.pH === 'N'
            ? this.pH + '-' + this.pV
            : this.pH + '-' + this.pV + this.pC;
        this.generate();
      }
    } else {
      alert('値を選択してください');
      return;
    }
  }

  onSave() {
    this.dialogRef.close(this.colorInfo);
  }

  generate() {
    this.isLoading = true;
    const projectId = this.picklist
      ? this.picklist.project_id
      : this.listProduct.project_id;
    this.api.project
      .generateColorImage(projectId, this.listProduct.id, this.colorInfo)
      .subscribe((colorInfo) => {
        this.isLoading = false;
        this.colorInfo = colorInfo;
        this.changeDetector.markForCheck();
      });
  }

  resetColorInfo() {
    this.colorInfo = { color: '', type: 'paintNo' };
  }
}
