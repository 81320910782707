import { Injectable } from '@angular/core';
import { InteriorCategory } from '@shared/models/response/interior-category';
import { ListProduct } from '@shared/models/response/list-product';
import { Picklist } from '@shared/models/response/picklist';
import { RoomGroup } from '@shared/models/response/room-group';
import { IllegalInteriorList } from '@shared/models/response/sub/illegal-interior-list';

import { PicklistService } from './picklist.service';
import { ProjectService } from './project.service';

@Injectable({
  providedIn: 'root',
})
export class CertificationService {
  get interiorCategories() {
    return this.project.interiorCategories;
  }

  get floorGroups() {
    return this.project.floorGroups;
  }

  get interiorRestrictionCategories(): {
    design: InteriorCategory[];
    withBase: InteriorCategory[];
  } {
    const targetCategories = this.interiorCategories.filter((category) => {
      return ['天井', '壁'].includes(category.name);
    });

    return {
      design: targetCategories.filter((category) => category.type == '仕上'),
      withBase: targetCategories,
    };
  }

  constructor(
    private picklistService: PicklistService,
    private project: ProjectService,
  ) {}

  public checkInteriorRestrictionOfRoom(roomGroup: RoomGroup) {
    return this.interiorCategories.reduce((collection, category) => {
      const categoryId = category.id;
      const parts = roomGroup.interiorSet.parts[categoryId];

      if (!!parts && !!parts.picklists) {
        parts.picklists.forEach((picklist) => {
          const decidedListProduct =
            this.picklistService.getDecidedListProductByPicklist(picklist);

          if (decidedListProduct) {
            collection = [
              ...collection,
              ...this.checkInteriorRestriction(
                roomGroup,
                categoryId,
                decidedListProduct,
                picklist,
              ),
            ];
          }
        });
      }

      return collection;
    }, [] as IllegalInteriorList[]);
  }

  public checkInteriorRestrictionOfRoomByPicklist(
    picklist: Picklist,
    listProduct?: ListProduct,
  ): IllegalInteriorList[] {
    let tmpIllegalLists: IllegalInteriorList[] = [];
    const decidedListProduct = !listProduct
      ? this.picklistService.getDecidedListProductByPicklist(picklist)
      : listProduct;

    if (decidedListProduct) {
      tmpIllegalLists = this.picklistService
        .getInteriorUsedIn(picklist, this.floorGroups, this.interiorCategories)
        .reduce((collection, tmp) => {
          return [
            ...collection,
            ...this.checkInteriorRestriction(
              tmp.roomGroup,
              tmp.categoryId,
              decidedListProduct,
              picklist,
            ),
          ];
        }, [] as IllegalInteriorList[]);
    }
    return tmpIllegalLists;
  }

  private covertToLowerCase(val?: string): string {
    if (val) {
      return val
        .replace(/[０-９]/g, (s) => {
          return String.fromCharCode(s.charCodeAt(0) - 65248);
        })
        ?.trim();
    }
    return '';
  }

  private getInteriorRestrictionCheckPatterns(restriction: string): {
    categories: InteriorCategory[];
    patterns: RegExp[];
  } {
    let checker: {
      categories: InteriorCategory[];
      patterns: RegExp[];
    } = {
      categories: [],
      patterns: [],
    };

    const NM = /^.*NM-[0-9]{4,}.*$/;
    const QM = /^.*QM-[0-9]{4,}.*$/;
    const RM = /^.*RM-[0-9]{4,}.*$/;
    const info1 = /^.*告.*1400.*$/;
    const info2 = /^.*告.*1401.*$/;
    const info3 = /^.*告.*1402.*$/;

    checker.categories = (() => {
      if (restriction.match(/^《[不準]》$/)) {
        return this.interiorRestrictionCategories.withBase;
      } else if (restriction.match(/^〈[不準難]〉$/)) {
        return this.interiorRestrictionCategories.design;
      }
      return [];
    })();

    checker.patterns = (() => {
      if (restriction.match(/^[《〈]不[〉》]$/)) {
        return [NM, info1];
      } else if (restriction.match(/^[《〈]準[〉》]$/)) {
        return [NM, QM, info1, info2];
      } else if (restriction.match(/^〈難〉$/)) {
        return [NM, QM, RM, info1, info2, info3];
      }
      return [];
    })();

    return checker;
  }

  private checkInteriorRestriction(
    roomGroup: RoomGroup,
    categoryId: number,
    decidedListProduct: ListProduct,
    picklist: Picklist,
  ): IllegalInteriorList[] {
    const tmpIllegalLists: IllegalInteriorList[] = [];
    const restriction = roomGroup.interior_restriction;

    if (restriction && restriction !== '-') {
      const checkPatterns =
        this.getInteriorRestrictionCheckPatterns(restriction);
      const interiorCategory = checkPatterns.categories.find(
        (_category) => _category.id == categoryId,
      );

      if (!!interiorCategory) {
        const UnMatched =
          checkPatterns.patterns.filter((pattern) =>
            this.covertToLowerCase(decidedListProduct.certification!).match(
              pattern,
            ),
          ).length == 0;

        if (UnMatched) {
          tmpIllegalLists.push({
            roomGroup,
            picklist,
            interiorCategory,
            decidedListProduct,
          });
        }
      }
    }
    return tmpIllegalLists;
  }

  public checkIllegalPicklist(
    picklist: Picklist,
    listProduct?: ListProduct,
  ): string | null {
    let illegalLists: IllegalInteriorList[] = [];
    illegalLists = this.checkInteriorRestrictionOfRoomByPicklist(
      picklist,
      listProduct,
    );
    if (illegalLists.length !== 0) {
      const lists = illegalLists.map((list) => {
        return [list.roomGroup.name, list.roomGroup.interior_restriction].join(
          ',',
        );
      });
      const newLists = Array.from(new Set(lists));
      newLists.unshift(
        '使用されている以下の部屋の内装制限を満たしていない可能性があります。',
      );
      return newLists.join('\n');
    }

    return null;
  }

  public checkIllegalRoom(roomGroup: RoomGroup): string | null {
    let illegalLists: IllegalInteriorList[] | null = [];
    illegalLists = this.checkInteriorRestrictionOfRoom(roomGroup);
    if (illegalLists.length > 0) {
      return this.createIllegalRoomText(illegalLists);
    }

    return null;
  }

  public createIllegalRoomText(illegalLists: IllegalInteriorList[]) {
    const lists = illegalLists.map((list) => {
      return [
        list.picklist.name,
        list.decidedListProduct.name,
        list.decidedListProduct.certification,
      ].join(',');
    });
    const newLists = Array.from(new Set(lists));
    newLists.unshift(
      'ステータスが【採用】の以下の材料が内装制限を満たしていない可能性があります。',
    );
    return newLists.join('\n');
  }
}
