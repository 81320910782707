<ng-container *ngIf="data.setPicklist; else updateRoom">
  <mat-dialog-content>
    <div>
      <p *ngIf="data.decided">
        使用されている以下の部屋の内装制限を満たしていない可能性があります。
      </p>
      <p *ngIf="data.decided && !data.singleSelect">
        本当にこの認定番号で保存しますか？
      </p>
      <p *ngIf="!data.decided">
        貼り付けようとしている以下の部屋の内装制限を満たしていない可能性があります。
      </p>
      <p *ngIf="!data.decided">本当に貼り付けますか？</p>
    </div>
    <div class="wrapper">
      <div class="section">
        <table>
          <thead>
            <tr>
              <th colspan="1">部屋名</th>
              <th colspan="1">内装制限</th>
              <th colspan="1">材料名</th>
              <th colspan="1">製品名</th>
              <th colspan="1">認定番号</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let illigal of data.illegal">
              <td>{{ illigal.roomGroup.name }}</td>
              <td>{{ illigal.roomGroup.interior_restriction }}</td>
              <td>{{ illigal.picklist.name }}</td>
              <td>{{ illigal.decidedListProduct.name }}</td>
              <td>
                {{
                  illigal.decidedListProduct.certification
                    ? illigal.decidedListProduct.certification
                    : '指定なし'
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <a
      *ngIf="data.singleSelect"
      ls-button
      (click)="clickStore()"
      [border]="true"
      >閉じる</a
    >
    <a *ngIf="!data.singleSelect" ls-button (click)="cancel()" [border]="true"
      >キャンセル</a
    >
    <a
      *ngIf="!data.singleSelect"
      ls-button
      (click)="clickStore()"
      theme="warn"
      [border]="true"
      >{{ data.decided ? '保存' : '貼付' }}</a
    >
  </mat-dialog-actions>
</ng-container>

<ng-template #updateRoom>
  <mat-dialog-content>
    <div>
      <p>
        使用されている以下の材料が内装制限を満たしていない可能性があります。
      </p>
      <p>本当にこの内装制限を保存しますか？</p>
    </div>
    <div class="wrapper">
      <div class="header">
        <h3 class="header-title">選択した内装制限</h3>
        <p>
          {{ data.illegal[0].roomGroup.interior_restriction }}　{{
            lowLang[data.illegal[0].roomGroup.interior_restriction ?? 0]
          }}
        </p>
      </div>
      <div class="section">
        <h3 class="header-title">{{ data.illegal[0].roomGroup.name }}</h3>
        <table>
          <thead>
            <tr>
              <th colspan="2">部位</th>
              <th colspan="1">材料名</th>
              <th colspan="1">製品名</th>
              <th colspan="1">認定番号</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let illigal of data.illegal">
              <td>{{ illigal.interiorCategory.name }}</td>
              <td>{{ illigal.interiorCategory.type }}</td>
              <td>{{ illigal.picklist.name }}</td>
              <td>{{ illigal.decidedListProduct.name }}</td>
              <td>
                {{
                  illigal.decidedListProduct.certification
                    ? illigal.decidedListProduct.certification
                    : '指定なし'
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <a ls-button (click)="cancel()" [border]="true">キャンセル</a>
    <a ls-button (click)="clickStore()" theme="warn" [border]="true">保存</a>
  </mat-dialog-actions>
</ng-template>
