import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { SubscriptionLike } from 'rxjs';

import {
  DummyImageParams,
  DummyImageService,
} from '../dummy-image/dummy-image.service';

import { PicklistSrcService } from './picklist-src.service';
import { SrcService } from './src.service';

@Directive({
    selector: '[lsSrc]',
    exportAs: 'lsSrc',
    standalone: true,
})
export class SrcDirective implements OnChanges, OnDestroy {
  @Input('lsSrc')
  fileName?: string | null;

  @Input()
  size?: string;

  @Input()
  dummy: DummyImageParams = { width: 400 };

  @Input()
  hideIfNotExists = false;

  @Input()
  type: 'picklist' | 'product' | 'new' = 'product';

  get isChecking() {
    return !!this.checkingSubscription;
  }

  get exists() {
    return this._exists;
  }

  private src: SrcService | PicklistSrcService;
  private srcSet: string;

  private _exists = false;

  private checkingSubscription?: SubscriptionLike;

  constructor(
    private productSrc: SrcService,
    private picklistSrc: PicklistSrcService,
    private element: ElementRef,
    private changeDetector: ChangeDetectorRef,
    private dummyImage: DummyImageService,
  ) {
    if (this.element.nativeElement.tagName.toLowerCase() !== 'img') {
      throw new Error('The element must be img.');
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.type === 'picklist') {
      this.src = this.picklistSrc;
    } else {
      this.src = this.productSrc;
    }

    if (changes.fileName || changes.size) {
      this.updateAndCheckSrc();
    } else if (changes.dummy) {
      if (!this.isChecking && !this.exists) {
        this.setDummySrc();
      }
    }
  }

  ngOnDestroy() {
    this.cancelCheckingIfCan();
  }

  private updateAndCheckSrc() {
    this._exists = false;
    // 先にsrc更新、チェックして存在しなければダミーに変更
    this.cancelCheckingIfCan();
    if (!this.fileName) {
      this.setDummySrc();
      return;
    }
    if (this.type === 'new') {
      this.srcSet = this.src.storage(
        this.src.size(this.fileName, this.size || 'original'),
        true,
      );
      this.element.nativeElement.src = this.srcSet;
    } else {
      this.srcSet = this.src.storage(
        this.src.size(this.fileName, this.size || 'original'),
      );
      this.element.nativeElement.src = this.srcSet;
    }

    this.setHiddenIfNeeded(false);
    this.checkingSubscription = this.src
      .checkIfExists(this.srcSet)
      .subscribe((exists) => {
        this._exists = exists;
        if (!exists) {
          this.setDummySrc();
        }
        this.cancelCheckingIfCan();
      });
  }

  private cancelCheckingIfCan() {
    if (this.checkingSubscription) {
      this.checkingSubscription.unsubscribe();
      this.checkingSubscription = undefined;
      this.changeDetector.markForCheck();
    }
  }

  private setDummySrc() {
    this.setHiddenIfNeeded(true);
    this.element.nativeElement.src = this.dummyImage.srcUrl(this.dummy);
    this.changeDetector.markForCheck();
  }

  private setHiddenIfNeeded(hidden: boolean) {
    if (!this.hideIfNotExists) {
      this.element.nativeElement.style.display = 'block';
      return;
    }

    this.element.nativeElement.style.display = hidden ? 'none' : 'block';
  }
}
